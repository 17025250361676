import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Textarea } from "../components/ui/textarea";
import { useToast } from "../components/ui/use-toast";
import {
  Mail,
  MessageCircle,
  Copy,
  CheckCircle,
  Bug,
  HelpCircle,
  MessageSquare,
  Star,
  Plus
} from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";

const SupportPage = () => {
  const user = useSelector((state) => state?.auth?.user || null);
  const { toast } = useToast();
  const [formData, setFormData] = useState({
    name: user?.firstName ? `${user.firstName} ${user.lastName || ''}` : '',
    email: user?.email || '',
    // Initialize empty if no user is logged in
    subject: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubjectChange = (value) => {
    setFormData({
      ...formData,
      subject: value,
    });
  };

  const subjectOptions = [
    { value: 'bug', label: 'Report a Bug/Issue', icon: Bug },
    { value: 'account', label: 'Help With My Account', icon: HelpCircle },
    { value: 'feedback', label: 'General Feedback', icon: Star },
    { value: 'question', label: 'I Have a Question', icon: MessageSquare },
    { value: 'other', label: 'Other', icon: Plus },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api'}/support/contact`, formData);
      
      toast({
        title: "Message Sent",
        description: "We'll get back to you as soon as possible.",
        duration: 5000,
      });

      // Clear form
      setFormData({
        ...formData,
        subject: '',
        message: '',
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to send message. Please try again.",
        variant: "destructive",
        duration: 5000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const copyEmail = () => {
    navigator.clipboard.writeText('support@floworchestra.com');
    setCopied(true);
    toast({
      title: "Email Copied",
      description: "Support email has been copied to clipboard",
      duration: 3000,
    });
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <div className="container mx-auto p-4 max-w-4xl">
      <h1 className="text-3xl font-bold mb-8">Support</h1>
      
      <div className="grid gap-6 md:grid-cols-2">
        {/* Contact Form Card */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <MessageCircle className="h-5 w-5" />
              Contact Us
            </CardTitle>
            <CardDescription>
              Send us a message and we'll get back to you as soon as possible.
            </CardDescription>
          </CardHeader>
          <form onSubmit={handleSubmit}>
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <label className="text-sm font-medium">Name</label>
                <Input
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              
              <div className="space-y-2">
                <label className="text-sm font-medium">Email</label>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              
              <div className="space-y-2">
                <label className="text-sm font-medium">Subject</label>
                <Select
                  value={formData.subject}
                  onValueChange={handleSubjectChange}
                  required
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select a subject" />
                  </SelectTrigger>
                  <SelectContent>
                    {subjectOptions.map(({ value, label, icon: Icon }) => (
                      <SelectItem key={value} value={value}>
                        <div className="flex items-center gap-2">
                          <Icon className="h-4 w-4" />
                          <span>{label}</span>
                        </div>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              
              <div className="space-y-2">
                <label className="text-sm font-medium">Message</label>
                <Textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={5}
                  required
                />
              </div>
            </CardContent>
            
            <CardFooter>
              <Button 
                type="submit" 
                className="w-full"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending...' : 'Send Message'}
              </Button>
            </CardFooter>
          </form>
        </Card>

        {/* Email Card */}
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Mail className="h-5 w-5" />
              Email Us
            </CardTitle>
            <CardDescription>
              You can also reach us directly via email.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-between p-4 bg-secondary rounded-lg">
              <span className="font-mono">support@floworchestra.com</span>
              <Button
                variant="outline"
                size="sm"
                onClick={copyEmail}
                className="flex items-center gap-2"
              >
                {copied ? (
                  <CheckCircle className="h-4 w-4" />
                ) : (
                  <Copy className="h-4 w-4" />
                )}
                {copied ? 'Copied' : 'Copy'}
              </Button>
            </div>
            <p className="mt-4 text-sm text-muted-foreground">
              Our support team typically responds within 24 hours during business days.
            </p>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default SupportPage;