import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, CardHeader, CardContent, CardFooter } from '../../components/ui/card';
import { Badge } from '../../components/ui/badge';
import { Button } from '../../components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../components/ui/tooltip';
import { Bot, Lock, CreditCard, Star, ChevronRight, Users } from 'lucide-react';

const ChatbotCard = ({ 
  chatbot, 
  isSelected, 
  onClick, 
  showPurchaseOptions = true,
  onPurchase,
  className = '' 
}) => {
  const navigate = useNavigate();
  const userSubscription = useSelector(state => state.auth.user?.subscription);

  const handleChatNow = (e) => {
    e.stopPropagation();
    navigate(`/chat?assistantId=${chatbot._id}`);
  };

  const getAccessBadge = () => {
    switch (chatbot.accessType) {
      case 'free':
        return (
          <Badge variant="secondary" className="flex items-center gap-1 border border-green-600">
            <Star className="w-3 h-3 text-green-600" />
            Free
          </Badge>
        );
      case 'paid':
        return (<>
            <div className='text-sm'>Pay Once - Use Forever</div>
          <Badge variant="default" className="flex items-center gap-1 bg-green-500 -mt-2">
            <CreditCard className="w-3 h-3" />
            ${chatbot.price}
          </Badge>
        </>);
      case 'subscription':
        return (
          <Badge 
            variant="outline" 
            className={`flex items-center gap-1 ${
              userSubscription?.tier >= chatbot.requiredSubscriptionTier 
                ? 'bg-green-100' 
                : 'bg-yellow-100'
            }`}
          >
            <Lock className="w-3 h-3" />
            {chatbot.requiredSubscriptionTier} Plan
          </Badge>
        );
      default:
        return null;
    }
  };

  const getBgColor = (group) => {
    switch (group) {
        case 'Expert':
            return 'bg-yellow-500';
      case 'Standard':
        return 'bg-gray-100';
      case 'Teams':
        return 'bg-green-100';
      case 'Power Tool':
        return 'bg-red-300';
      default:
        return 'bg-blue-100';
    }
  };

  const canAccess = () => {
    switch (chatbot.accessType) {
      case 'free':
        return true;
      case 'paid':
        return false; // Always show purchase option
      case 'subscription':
        return userSubscription?.tier >= chatbot.requiredSubscriptionTier;
      default:
        return false;
    }
  };

  return (
    <Card 
      className={`
        relative overflow-hidden transition-all duration-200 cursor-pointer
        ${isSelected ? 'ring-2 ring-primary' : 'hover:shadow-lg'}
        ${className}
      `}
      onClick={() => onClick(chatbot)}
    >
      <CardHeader className="pb-2">
        <div className='absolute top-2 left-2'>
            <Badge variant="outline" className={`${getBgColor(chatbot.salesGroup)} text-sm`}>
                {chatbot.salesGroup || 'Standard'}
            </Badge>
        </div>
        
        <div className="flex items-center text-muted-foreground absolute top-1 right-2">
                  <Users className="w-4 h-4 mr-1" />
                  <span className="text-sm">{chatbot.activeUsers}</span>
                </div>
        <div className="flex flex-col justify-center items-center pt-4 gap-2 lg:gap-3">
            <div className="relative w-20 h-20 overflow-hidden rounded-full shadow-md shadow-gray-900 border border-gray-900">
                <img src={chatbot.avatarUrl} alt={chatbot.name} className="object-cover w-full h-full" />
            </div>
          <div className="flex items-center gap-2 text-center">
            <h3 className="font-semibold text-lg leading-tight">{chatbot.name}</h3>
          </div>

          {getAccessBadge()}
        </div>
      </CardHeader>

      <CardContent>
        <p className="text-sm text-muted-foreground line-clamp-4 leading-tight text-center">
          {chatbot.description}
        </p>
      </CardContent>

      <CardFooter className="flex flex-col gap-2 lg:gap-4 justify-between items-center">
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="flex items-center gap-2">
            <Badge variant="outline" className="bg-primary/5">
              {chatbot.category || 'General'}
            </Badge>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>Category</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>

    {showPurchaseOptions ? (
      !canAccess() && (
        <Button
          variant="default"
          size="sm"
          className="flex items-center gap-1"
          onClick={(e) => {
            e.stopPropagation();
            onClick(chatbot);
          }}
        >
          More Details
          <ChevronRight className="w-4 h-4" />
        </Button>
      )
    ) : (
      <Button
        variant="default"
        size="sm"
        className="flex items-center gap-1"
        onClick={handleChatNow}
      >
        Chat Now
        <ChevronRight className="w-4 h-4" />
      </Button>
    )}
  </CardFooter>
    </Card>
  );
};

export default ChatbotCard;