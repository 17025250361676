// src/components/TodoList.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "./ui/card";
import { Button } from "./ui/button";
import { Checkbox } from "./ui/checkbox";
import { Badge } from "./ui/badge";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import {
  Clock,
  ChevronRight,
  AlertCircle,
  CheckCircle2,
  Circle,
  Clock4,
  Tag,
  User2
} from 'lucide-react';
import SelectionBar from './TodoComponents/SelectionBar';
import {
  fetchTodos,
  updateTodoStatus,
  bulkArchiveTodos,
  selectTodos,
  selectTodoLoading,
  selectTodoError,
  selectTodoPagination,
  deleteTodo
} from '../slices/todoSlice';
import { useToast } from "./ui/use-toast";

const TodoStatusBadge = ({ status }) => {
  const statusConfig = {
    pending: { color: 'bg-yellow-100 text-yellow-800', icon: Clock },
    in_progress: { color: 'bg-blue-100 text-blue-800', icon: Clock4 },
    completed: { color: 'bg-green-100 text-green-800', icon: CheckCircle2 }
  };

  const config = statusConfig[status] || statusConfig.pending;
  const Icon = config.icon;

  return (
    <Badge variant="outline" className={`${config.color} flex items-center gap-1`}>
      <Icon className="h-3 w-3" />
      {status.replace('_', ' ')}
    </Badge>
  );
};

const TodoPriorityBadge = ({ priority }) => {
  const priorityConfig = {
    low: 'bg-gray-100 text-gray-800',
    medium: 'bg-yellow-100 text-yellow-800',
    high: 'bg-red-100 text-red-800'
  };

  return (
    <Badge variant="outline" className={priorityConfig[priority]}>
      {priority}
    </Badge>
  );
};

const TodoList = ({ 
  isWidget = false,
  teamId = null,
  maxItems = 5,
  showHeader = true,
  onItemClick,
  currentUserRole = null
}) => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const todos = useSelector(selectTodos);
  const loading = useSelector(selectTodoLoading);
  const error = useSelector(selectTodoError);
  const pagination = useSelector(selectTodoPagination);
  
  const [statusFilter, setStatusFilter] = useState('all');
  const [selectedItems, setSelectedItems] = useState([]);

  // Permission checks
  const isTeamContext = !!teamId;
  const canDelete = !isTeamContext || currentUserRole === 'admin';
  const canModify = !isTeamContext || ['admin', 'member'].includes(currentUserRole);

  useEffect(() => {
    dispatch(fetchTodos({
      team: teamId,
      status: statusFilter !== 'all' ? statusFilter : undefined,
      limit: maxItems
    }));
  }, [dispatch, teamId, statusFilter, maxItems]);

  // Selection handlers
  const handleSelectItem = (todoId) => {
    setSelectedItems(prev => {
      if (prev.includes(todoId)) {
        return prev.filter(id => id !== todoId);
      }
      return [...prev, todoId];
    });
  };

  const handleSelectAll = () => {
    if (selectedItems.length === todos.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(todos.map(todo => todo._id));
    }
  };

  const handleClearSelection = () => {
    setSelectedItems([]);
  };

  // Bulk action handlers
  const handleBulkArchive = async () => {
    try {
      await dispatch(bulkArchiveTodos(selectedItems)).unwrap();
      toast({
        title: "Success",
        description: `${selectedItems.length} items archived successfully`
      });
      setSelectedItems([]);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to archive selected items",
        variant: "destructive"
      });
    }
  };

  const handleBulkDelete = async () => {
    if (!canDelete) {
      toast({
        title: "Permission Denied",
        description: "You don't have permission to delete these todos",
        variant: "destructive"
      });
      return;
    }

    if (!window.confirm('Are you sure you want to delete the selected items?')) {
      return;
    }

    try {
      // Delete items one by one since we don't have a bulk delete endpoint
      await Promise.all(selectedItems.map(id => dispatch(deleteTodo(id)).unwrap()));
      toast({
        title: "Success",
        description: `${selectedItems.length} items deleted successfully`
      });
      setSelectedItems([]);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete selected items",
        variant: "destructive"
      });
    }
  };

  const handleTodoClick = (e, todo) => {
    // If we're selecting, don't trigger the click handler
    if (selectedItems.length > 0) {
      e.preventDefault();
      e.stopPropagation();
      handleSelectItem(todo._id);
      return;
    }

    if (onItemClick) {
      onItemClick(todo);
    }
  };

  if (error) {
    return (
      <Card className="w-full">
        <CardContent className="pt-6">
          <div className="flex items-center gap-2 text-red-600">
            <AlertCircle className="h-4 w-4" />
            <span>Failed to load to-dos</span>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Card className={`w-full ${isWidget ? 'col-span-2 border-none shadow-lg' : ''}`}>
        {showHeader && (
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-lg font-medium">To-dos</CardTitle>
            <Select
              value={statusFilter}
              onValueChange={setStatusFilter}
            >
              <SelectTrigger className="w-[180px] bg-black text-white">
                <SelectValue placeholder="Filter by status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Status</SelectItem>
                <SelectItem value="pending">Pending</SelectItem>
                <SelectItem value="in_progress">In Progress</SelectItem>
                <SelectItem value="completed">Completed</SelectItem>
              </SelectContent>
            </Select>
          </CardHeader>
        )}
        <CardContent className={`${isWidget ? 'h-fit pr-4 lg:max-h-[300px] overflow-y-auto' : ''}`}>
          {loading ? (
            <div className="flex items-center justify-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
            </div>
          ) : todos.length === 0 ? (
            <div className="text-center py-8 text-gray-500">
              No to-dos found
            </div>
          ) : (
            <div className="space-y-4 pt-2">
              {todos.map(todo => (
                <div
                  key={todo._id}
                  className={`flex items-center p-4 rounded-lg border cursor-pointer hover:bg-gray-100 ${
                    selectedItems.includes(todo._id) ? 'bg-primary/5 border-primary' : 'bg-gray-50'
                  }`}
                  onClick={(e) => handleTodoClick(e, todo)}
                >
                  <div className="flex items-center gap-4 w-full">
                    <Checkbox
                      checked={selectedItems.includes(todo._id)}
                      onCheckedChange={() => handleSelectItem(todo._id)}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <div className="flex flex-col gap-2 flex-grow">
                      <div className="flex items-center justify-between">
                        <h3 className="font-medium">{todo.title}</h3>
                        <ChevronRight className="h-4 w-4 text-gray-400" />
                      </div>
                      <div className="flex flex-col md:flex-row items-start lg:items-center md:gap-2 text-sm text-gray-500">
                        {todo.dueDate && (
                          <div className="flex items-center gap-1">
                            <Clock className="h-3 w-3" />
                            <span>{format(new Date(todo.dueDate), 'MMM d, yyyy')}</span>
                          </div>
                        )}
                        {todo.assignedTo?.length > 0 && (
                          <div className="flex items-center gap-1">
                            <User2 className="h-3 w-3" />
                            <span>{todo.assignedTo.length} assigned</span>
                          </div>
                        )}
                        {todo.labels?.length > 0 && (
                          <div className="flex items-center gap-1">
                            <Tag className="h-3 w-3" />
                            <span>{todo.labels.length} labels</span>
                          </div>
                        )}
                      </div>
                      <div className="flex items-center gap-2">
                        <TodoStatusBadge status={todo.status} />
                        <TodoPriorityBadge priority={todo.priority} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </CardContent>
      </Card>

      <SelectionBar
        selectedCount={selectedItems.length}
        onClearSelection={handleClearSelection}
        onArchiveSelected={handleBulkArchive}
        onDeleteSelected={handleBulkDelete}
        onSelectAll={handleSelectAll}
        isAllSelected={selectedItems.length === todos.length}
        totalItems={todos.length}
      />
    </>
  );
};

export default TodoList;