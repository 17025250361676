import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllChatbots, getUserAddedChatbots, createChatbotCheckoutSession, postAddFreeChatbot } from '../services/api';

// Async thunks
export const fetchMarketplaceChatbots = createAsyncThunk(
  'chatbots/fetchMarketplace',
  async ({ search, filters } = {}, { rejectWithValue }) => {
    try {
      const response = await getAllChatbots({ search, ...filters });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch chatbots');
    }
  }
);

export const fetchUserAddedChatbots = createAsyncThunk(
  'chatbots/fetchUserAddedChatbots',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUserAddedChatbots();
      //console.log('fetchUserAddedChatbots response:', response.data.data);
      return response.data.data.sort((a, b) => a.name.localeCompare(b.name));
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch user chatbots');
    }
  }
);

export const addFreeChatbot = createAsyncThunk(
  'chatbots/addFreeChatbot',
  async (chatbotId, { rejectWithValue, getState }) => {
    try {
      const response = await postAddFreeChatbot(chatbotId);
      // Get the chatbot details from the marketplace state
      const marketplaceChatbot = getState().chatbots.marketplace.chatbots
        .find(bot => bot._id === chatbotId);
      return { 
        chatbot: response.data.chatbot || marketplaceChatbot, // fallback to marketplace data if needed
        originalId: chatbotId 
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to add free chatbot');
    }
  }
);

export const createCheckoutSession = createAsyncThunk(
  'chatbots/createCheckoutSession',
  async (chatbotId, { rejectWithValue }) => {
    try {
      const response = await createChatbotCheckoutSession(chatbotId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to create checkout session');
    }
  }
);

const initialState = {
  marketplace: {
    chatbots: [],
    loading: false,
    error: null,
    filters: {
      search: '',
      accessType: 'all', // 'all', 'free', 'paid', 'subscription'
      priceRange: { min: 0, max: null },
      salesGroup: 'all',
      subscriptionTier: 'all', // 'all', 'basic', 'pro', 'enterprise'
      sortBy: 'name' // 'name', 'price', 'popularity'
    }
  },
  userChatbots: {
    chatbots: [],
    loading: false,
    error: null
  },
  selectedChatbot: null,
  checkoutStatus: {
    loading: false,
    error: null,
    sessionId: null
  }
};

const chatbotsSlice = createSlice({
  name: 'chatbots',
  initialState,
  reducers: {
    setSelectedChatbot: (state, action) => {
      state.selectedChatbot = action.payload;
    },
    updateMarketplaceFilters: (state, action) => {
      state.marketplace.filters = {
        ...state.marketplace.filters,
        ...action.payload
      };
    },
    clearCheckoutStatus: (state) => {
      state.checkoutStatus = {
        loading: false,
        error: null,
        sessionId: null
      };
    },
    resetMarketplaceFilters: (state) => {
      state.marketplace.filters = initialState.marketplace.filters;
    }
  },
  extraReducers: (builder) => {
    // Marketplace chatbots
    builder
      .addCase(fetchMarketplaceChatbots.pending, (state) => {
        state.marketplace.loading = true;
        state.marketplace.error = null;
      })
      .addCase(fetchMarketplaceChatbots.fulfilled, (state, action) => {
        state.marketplace.loading = false;
        state.marketplace.chatbots = action.payload;
      })
      .addCase(fetchMarketplaceChatbots.rejected, (state, action) => {
        state.marketplace.loading = false;
        state.marketplace.error = action.payload;
      })
    
    // User chatbots
      .addCase(fetchUserAddedChatbots.pending, (state) => {
        state.userChatbots.loading = true;
        state.userChatbots.error = null;
      })
      .addCase(fetchUserAddedChatbots.fulfilled, (state, action) => {
        state.userChatbots.loading = false;
        state.userChatbots.chatbots = action.payload;
      })
      .addCase(fetchUserAddedChatbots.rejected, (state, action) => {
        state.userChatbots.loading = false;
        state.userChatbots.error = action.payload;
      })
    
    // Checkout session
      .addCase(createCheckoutSession.pending, (state) => {
        state.checkoutStatus.loading = true;
        state.checkoutStatus.error = null;
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        state.checkoutStatus.loading = false;
        state.checkoutStatus.sessionId = action.payload.sessionId;
      })
      .addCase(createCheckoutSession.rejected, (state, action) => {
        state.checkoutStatus.loading = false;
        state.checkoutStatus.error = action.payload;
      })
      .addCase(addFreeChatbot.pending, (state) => {
        state.userChatbots.loading = true;
        state.userChatbots.error = null;
      })
      .addCase(addFreeChatbot.fulfilled, (state, action) => {
        state.userChatbots.loading = false;
        // Add to user chatbots if not already present
        if (!state.userChatbots.chatbots.find(bot => bot._id === action.payload.chatbot._id)) {
          state.userChatbots.chatbots.push(action.payload.chatbot);
        }
        // Keep the chatbot in marketplace but mark it as owned
        const marketplaceChatbot = state.marketplace.chatbots
          .find(bot => bot._id === action.payload.originalId);
        if (marketplaceChatbot) {
          marketplaceChatbot.isOwned = true;
        }
      })
      .addCase(addFreeChatbot.rejected, (state, action) => {
        state.userChatbots.loading = false;
        state.userChatbots.error = action.payload;
      });
  }
});

export const { 
  setSelectedChatbot, 
  updateMarketplaceFilters, 
  clearCheckoutStatus,
  resetMarketplaceFilters
} = chatbotsSlice.actions;

// Selectors
export const selectMarketplaceChatbots = state => state.chatbots.marketplace.chatbots;
export const selectMarketplaceLoading = state => state.chatbots.marketplace.loading;
export const selectMarketplaceError = state => state.chatbots.marketplace.error;
export const selectMarketplaceFilters = state => state.chatbots.marketplace.filters;

export const selectUserChatbots = state => state.chatbots.userChatbots.chatbots;
export const selectUserChatbotsLoading = state => state.chatbots.userChatbots.loading;
export const selectUserChatbotsError = state => state.chatbots.userChatbots.error;

export const selectSelectedChatbot = state => state.chatbots.selectedChatbot;
export const selectCheckoutStatus = state => state.chatbots.checkoutStatus;

export default chatbotsSlice.reducer;