// src/pages/ImageGenerationComponents/ImageHistorySidebar.js

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserImages, setCurrentImage, deleteImage } from '../../slices/imageGenerationSlice';
import { Button } from "../../components/ui/button";
import { Pagination } from "../../components/ui/pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faChevronLeft, faChevronRight, faHistory, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../../components/ui/dialog";
import { formatDistanceToNow } from 'date-fns';

const ImageHistorySidebar = ({ isOpen, toggleSidebar }) => {
  const dispatch = useDispatch();
  const { images, currentPage, totalPages } = useSelector((state) => state.imageGeneration);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);

  const handlePageChange = (page) => {
    dispatch(fetchUserImages(page));
  };

  const handleImageClick = (image, event) => {
    if (!dialogOpen && !event.target.closest('.delete-button')) {
      dispatch(setCurrentImage(image));
    }
  };

  const handleDeleteClick = (event, image) => {
    event.stopPropagation();
    setImageToDelete(image);
    setDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (imageToDelete) {
      dispatch(deleteImage(imageToDelete._id));
    }
    setDialogOpen(false);
    setImageToDelete(null);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setImageToDelete(null);
  };

  return (
    <div className={`bg-white shadow-lg transition-all duration-300 ${isOpen ? 'w-full lg:w-96' : 'lg:w-12'} flex flex-col mr-0 lg:mr-6 h-full`}>
      <div className="flex justify-between items-center">
        <Button
          onClick={toggleSidebar}
          className="hidden lg:flex lg:flex-grow lg:justify-center lg:items-center"
        >
          <FontAwesomeIcon icon={isOpen ? faChevronLeft : faChevronRight} />
        </Button>
        {isOpen && (
          <Button
            variant="ghost"
            size="sm"
            onClick={toggleSidebar}
            className="lg:hidden m-2"
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        )}
      </div>
      
      {isOpen ? (
        <div className="flex-grow flex flex-col overflow-hidden">
          <h2 className="text-inter font-bold text-xl p-4">Image History</h2>
          <div className="flex-grow overflow-y-auto px-4 space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-4">
              {images.map((image) => (
                <div 
                  key={image._id} 
                  className="relative group cursor-pointer"
                  onClick={(event) => {
                    handleImageClick(image, event);
                    if (window.innerWidth < 1024) {
                      toggleSidebar();
                    }
                  }}
                >
                  <img
                    src={image.imageUrl}
                    alt={image.prompt}
                    className="w-full h-40 object-cover rounded-lg"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-30 opacity-0 group-hover:opacity-100 transition-opacity duration-200 flex items-start justify-end p-2">
                    <Button 
                      variant="destructive" 
                      size="icon" 
                      className="delete-button"
                      onClick={(event) => handleDeleteClick(event, image)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </div>
                  <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 text-sm">
                    {formatDistanceToNow(new Date(image.createdAt), { addSuffix: true })}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="p-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      ) : (
        <div className="flex-grow flex items-center justify-center cursor-pointer" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faHistory} className="text-xl" />
        </div>
      )}
      
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="z-[80]">
          <DialogHeader>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this image? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={handleDialogClose}>Cancel</Button>
            <Button variant="destructive" onClick={handleDeleteConfirm}>Delete</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ImageHistorySidebar;