// src/slices/promptStackSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
  createPromptStack, 
  getPromptStacks, 
  updatePromptStack, 
  deletePromptStack,
  executePromptStack,
  fetchAllGlobalPromptStacks
} from '../services/api';

const initialState = {
  promptStacks: [],
  loading: false,
  error: null,
  currentStack: null,
  executionStatus: {
    isExecuting: false,
    currentStep: null,
    error: null
  },
  globalPromptStacks: [],
  globalPromptStacksStatus: 'idle',
  globalPromptStacksError: null,
};

export const fetchPromptStacks = createAsyncThunk(
  'promptStack/fetchAll',
  async (teamId, { rejectWithValue }) => {
    try {
      const response = await getPromptStacks(teamId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch prompt stacks');
    }
  }
);

export const savePromptStack = createAsyncThunk(
  'promptStack/save',
  async (stackData, { rejectWithValue }) => {
    try {
      const response = await createPromptStack(stackData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to save prompt stack');
    }
  }
);

export const editPromptStack = createAsyncThunk(
  'promptStack/edit',
  async ({ id, stackData }, { rejectWithValue }) => {
    try {
      const response = await updatePromptStack(id, stackData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to update prompt stack');
    }
  }
);

export const removePromptStack = createAsyncThunk(
  'promptStack/delete',
  async (id, { rejectWithValue }) => {
    try {
      await deletePromptStack(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to delete prompt stack');
    }
  }
);

export const fetchGlobalPromptStacks = createAsyncThunk(
  'flows/fetchGlobalPromptStacks',
  async () => {
    const response = await fetchAllGlobalPromptStacks();
    console.log('global prompt stacks', response.data);
    return response.data;
  }
);

/*export const executeStack = createAsyncThunk(
  'promptStack/execute',
  async ({ chatId, stackId, tempStack = null }, { rejectWithValue }) => {
    try {
      let response;
      if (tempStack) {
        // Execute temporary stack
        response = await executePromptStack(chatId, null, tempStack);
      } else {
        // Execute saved stack
        response = await executePromptStack(chatId, stackId);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to execute prompt stack');
    }
  }
);*/

export const executeStack = createAsyncThunk(
  'promptStack/execute',
  async ({ chatId, stackId, tempStack = null, promptCount, teamId = null }, { rejectWithValue }) => {
    try {
      const response = await executePromptStack(chatId, stackId, tempStack, promptCount, teamId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.response?.data || 'Failed to execute prompt stack');
    }
  }
);

const promptStackSlice = createSlice({
  name: 'promptStack',
  initialState,
  reducers: {
    setCurrentStack: (state, action) => {
      state.currentStack = action.payload;
    },
    clearCurrentStack: (state) => {
      state.currentStack = null;
    },
    clearExecutionStatus: (state) => {
      state.executionStatus = {
        isExecuting: false,
        currentStep: null,
        error: null
      };
    },
    setExecutionStep: (state, action) => {
      state.executionStatus.currentStep = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch prompt stacks
      .addCase(fetchPromptStacks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPromptStacks.fulfilled, (state, action) => {
        state.loading = false;
        state.promptStacks = action.payload;
      })
      .addCase(fetchPromptStacks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      // Save prompt stack
      .addCase(savePromptStack.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(savePromptStack.fulfilled, (state, action) => {
        state.loading = false;
        state.promptStacks.push(action.payload);
      })
      .addCase(savePromptStack.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      // Edit prompt stack
      .addCase(editPromptStack.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editPromptStack.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.promptStacks.findIndex(stack => stack._id === action.payload._id);
        if (index !== -1) {
          state.promptStacks[index] = action.payload;
        }
      })
      .addCase(editPromptStack.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      // Delete prompt stack
      .addCase(removePromptStack.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removePromptStack.fulfilled, (state, action) => {
        state.loading = false;
        state.promptStacks = state.promptStacks.filter(stack => stack._id !== action.payload);
      })
      .addCase(removePromptStack.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      // Execute stack
      .addCase(executeStack.pending, (state) => {
        state.executionStatus.isExecuting = true;
        state.executionStatus.error = null;
        state.executionStatus.currentStep = null;
      })
      .addCase(executeStack.fulfilled, (state) => {
        state.executionStatus.isExecuting = false;
        state.executionStatus.currentStep = null;
      })
      .addCase(executeStack.rejected, (state, action) => {
        state.executionStatus.isExecuting = false;
        state.executionStatus.currentStep = null;
        state.executionStatus.error = action.payload;
      })
            .addCase(fetchGlobalPromptStacks.pending, (state) => {
              state.globalPromptStacksStatus = 'loading';
            })
            .addCase(fetchGlobalPromptStacks.fulfilled, (state, action) => {
              state.globalPromptStacksStatus = 'succeeded';
              state.globalPromptStacks = action.payload;
            })
            .addCase(fetchGlobalPromptStacks.rejected, (state, action) => {
              state.globalPromptStacksStatus = 'failed';
              state.globalPromptStacksError = action.error.message;
            });
  }
});

export const { 
  setCurrentStack, 
  clearCurrentStack, 
  clearExecutionStatus,
  setExecutionStep 
} = promptStackSlice.actions;

export default promptStackSlice.reducer;