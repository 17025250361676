import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  updateMarketplaceFilters, 
  resetMarketplaceFilters,
  selectMarketplaceFilters 
} from '../../slices/chatbotsSlice';
import { Input } from '../../components/ui/input';
import { Button } from '../../components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../components/ui/select';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../components/ui/popover';
import { Slider } from '../../components/ui/slider';
import { Label } from '../../components/ui/label';
import { Search, SlidersHorizontal, X } from 'lucide-react';
import { debounce } from 'lodash';

const MarketplaceFilters = ({ className = '', chatbots }) => {
  const dispatch = useDispatch();
  const filters = useSelector(selectMarketplaceFilters);
  const [showMobileFilters, setShowMobileFilters] = React.useState(false);
  const [localPriceRange, setLocalPriceRange] = React.useState(filters.priceRange);

  const uniqueSalesGroups = React.useMemo(() => {
    const groups = new Set(chatbots
      .map(bot => bot.salesGroup)
      .filter(Boolean) // Remove null/undefined values
    );
    return Array.from(groups).sort();
  }, [chatbots]);

  const debouncedSearch = React.useMemo(
    () => debounce((value) => {
      dispatch(updateMarketplaceFilters({ search: value }));
    }, 300),
    [dispatch]
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  const handlePriceRangeChange = (value) => {
    setLocalPriceRange({
      min: value[0],
      max: value[1]
    });
  };

  const handlePriceRangeComplete = () => {
    dispatch(updateMarketplaceFilters({ priceRange: localPriceRange }));
  };

  const handleReset = () => {
    dispatch(resetMarketplaceFilters());
    setLocalPriceRange({ min: 0, max: null });
  };

  return (
    <div className={`space-y-4 ${className}`}>
      {/* Desktop View */}
      <div className="hidden lg:block">
        <div className="flex gap-4 items-start">
          <div className="flex-1">
            <div className="relative">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search chatbots..."
                className="pl-8"
                defaultValue={filters.search}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          
          <Select
            value={filters.accessType}
            onValueChange={(value) => 
              dispatch(updateMarketplaceFilters({ accessType: value }))
            }
          >
            <SelectTrigger className="w-[160px]">
              <SelectValue placeholder="Access Type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All Types</SelectItem>
              <SelectItem value="free">Free</SelectItem>
              <SelectItem value="paid">Paid</SelectItem>
              <SelectItem value="subscription">Subscription</SelectItem>
            </SelectContent>
          </Select>

          <Select
    value={filters.salesGroup}
    onValueChange={(value) =>
      dispatch(updateMarketplaceFilters({ salesGroup: value }))
    }
  >
    <SelectTrigger className="w-[160px]">
      <SelectValue placeholder="Sales Group" />
    </SelectTrigger>
    <SelectContent>
      <SelectItem value="all">All Groups</SelectItem>
      {uniqueSalesGroups.map(group => (
        <SelectItem key={group} value={group}>
          {group}
        </SelectItem>
      ))}
    </SelectContent>
  </Select>

          <Popover>
            <PopoverTrigger asChild>
              <Button variant="outline">
                <SlidersHorizontal className="h-4 w-4 mr-2" />
                Price Range
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-80">
              <div className="space-y-4">
                <h4 className="font-medium">Price Range</h4>
                <Slider
                  min={0}
                  max={100}
                  step={1}
                  value={[localPriceRange.min, localPriceRange.max || 100]}
                  onValueChange={handlePriceRangeChange}
                  onValueCommit={handlePriceRangeComplete}
                  className="w-full"
                />
                <div className="flex justify-between">
                  <span>${localPriceRange.min}</span>
                  <span>${localPriceRange.max || '100+'}</span>
                </div>
              </div>
            </PopoverContent>
          </Popover>

          <Button
            variant="ghost"
            onClick={handleReset}
            className="flex items-center"
          >
            <X className="h-4 w-4 mr-2" />
            Reset
          </Button>
        </div>
      </div>

      {/* Mobile View */}
      <div className="lg:hidden">
        <div className="flex gap-2">
          <div className="relative flex-1">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search chatbots..."
              className="pl-8"
              defaultValue={filters.search}
              onChange={handleSearchChange}
            />
          </div>
          <Button
            variant="outline"
            onClick={() => setShowMobileFilters(!showMobileFilters)}
          >
            <SlidersHorizontal className="h-4 w-4" />
          </Button>
        </div>

        {showMobileFilters && (
          <div className="mt-4 space-y-4">
            <div className="space-y-2">
              <Label>Access Type</Label>
              <Select
                value={filters.accessType}
                onValueChange={(value) =>
                  dispatch(updateMarketplaceFilters({ accessType: value }))
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Access Type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All Types</SelectItem>
                  <SelectItem value="free">Free</SelectItem>
                  <SelectItem value="paid">Paid</SelectItem>
                  <SelectItem value="subscription">Subscription</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label>Subscription Tier</Label>
              <Select
    value={filters.salesGroup}
    onValueChange={(value) =>
      dispatch(updateMarketplaceFilters({ salesGroup: value }))
    }
  >
    <SelectTrigger>
      <SelectValue placeholder="Sales Group" />
    </SelectTrigger>
    <SelectContent>
      <SelectItem value="all">All Groups</SelectItem>
      {uniqueSalesGroups.map(group => (
        <SelectItem key={group} value={group}>
          {group}
        </SelectItem>
      ))}
    </SelectContent>
  </Select>
            </div>

            <div className="space-y-2">
              <Label>Price Range</Label>
              <Slider
                min={0}
                max={100}
                step={1}
                value={[localPriceRange.min, localPriceRange.max || 100]}
                onValueChange={handlePriceRangeChange}
                onValueCommit={handlePriceRangeComplete}
                className="w-full"
              />
              <div className="flex justify-between text-sm text-muted-foreground">
                <span>${localPriceRange.min}</span>
                <span>${localPriceRange.max || '100+'}</span>
              </div>
            </div>

            <Button
              variant="ghost"
              onClick={handleReset}
              className="w-full flex items-center justify-center"
            >
              <X className="h-4 w-4 mr-2" />
              Reset Filters
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MarketplaceFilters;