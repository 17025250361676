import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchUserAddedChatbots } from '../../slices/chatbotsSlice';
import { Card, CardHeader, CardContent, CardFooter } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { CheckCircle2, XCircle, ArrowLeft } from 'lucide-react';

export const PurchaseSuccessPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    if (sessionId) {
      // Refresh user's chatbots to include the newly purchased one
      dispatch(fetchUserAddedChatbots());
    }
  }, [sessionId, dispatch]);

  return (
    <div className="container max-w-md mx-auto py-16 px-4">
      <Card>
        <CardHeader>
          <div className="flex flex-col items-center space-y-2">
            <CheckCircle2 className="w-12 h-12 text-green-500" />
            <h2 className="text-2xl font-bold text-center">Purchase Successful!</h2>
          </div>
        </CardHeader>
        <CardContent className="text-center">
          <p className="text-muted-foreground">
            Your chatbot has been successfully added to your account. You can start using it right away!
          </p>
        </CardContent>
        <CardFooter className="flex flex-col space-y-2">
        <Button 
            className="w-full"
            onClick={() => navigate('/marketplace', { state: { activeTab: 'my-chatbots' }})}
          >
            Go to My Chatbots
          </Button>
          <Button 
            variant="outline" 
            className="w-full"
            onClick={() => navigate('/marketplace')}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Marketplace
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export const PurchaseCancelPage = () => {
  const navigate = useNavigate();

  return (
    <div className="container max-w-md mx-auto py-16 px-4">
      <Card>
        <CardHeader>
          <div className="flex flex-col items-center space-y-2">
            <XCircle className="w-12 h-12 text-red-500" />
            <h2 className="text-2xl font-bold text-center">Purchase Cancelled</h2>
          </div>
        </CardHeader>
        <CardContent className="text-center">
          <p className="text-muted-foreground">
            Your purchase was cancelled. If you encountered any issues, please try again or contact support.
          </p>
        </CardContent>
        <CardFooter>
          <Button 
            className="w-full"
            onClick={() => navigate('/marketplace')}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Marketplace
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};