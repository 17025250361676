import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../components/ui/alert-dialog";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "../components/ui/tabs";
import { useToast } from "../components/ui/use-toast";
import { Label } from "../components/ui/label";
import { User, Mail, Key, Trash2, Shield, Skull } from "lucide-react";
import SubscriptionManagement from "./SubscriptionComponents/SubscriptionManagement";
import * as api from "../services/api";
import { setUser } from "../slices/authSlice";

const Profile = () => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const { user } = useSelector((state) => state.auth);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showFinalConfirmation, setShowFinalConfirmation] = useState(false);
  const [formData, setFormData] = useState({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
  });

  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.updateCurrentUser(formData);
      dispatch(setUser(response.data));
      toast({
        title: "Profile Updated",
        description: "Your profile has been successfully updated.",
      });
      setEditing(false);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to update profile",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      toast({
        title: "Error",
        description: "New passwords do not match",
        variant: "destructive",
      });
      return;
    }

    setLoading(true);
    try {
      await api.changePassword({
        currentPassword: passwordData.currentPassword,
        newPassword: passwordData.newPassword,
      });
      toast({
        title: "Password Updated",
        description: "Your password has been successfully changed.",
      });
      setPasswordData({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to change password",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    setIsDeleting(true);
    try {
      await api.deleteUserAccount();
      // Handle successful deletion - typically redirect to logout or home
      window.location.href = "/logout";
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to delete account",
        variant: "destructive",
      });
      setIsDeleting(false);
      setShowFinalConfirmation(false);
    }
  };

  return (
    <div className="container mx-auto p-2 lg:py-8">
      <h1 className="text-3xl font-bold mb-8">Profile Settings</h1>

      <div className="space-y-6">
        <Tabs defaultValue="profile" className="w-full">
          <TabsList>
            <TabsTrigger value="profile">Profile</TabsTrigger>
            <TabsTrigger value="security">Security</TabsTrigger>
            <TabsTrigger value="subscription">Subscription</TabsTrigger>
          </TabsList>

          <TabsContent value="profile" className="space-y-4">
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <User className="h-5 w-5" />
                  Personal Information
                </CardTitle>
              </CardHeader>
              <CardContent>
                <form onSubmit={handleUpdateProfile} className="space-y-4">
                  <div className="grid lg:grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <Label htmlFor="firstName">First Name</Label>
                      <Input
                        id="firstName"
                        disabled={!editing}
                        value={formData.firstName}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            firstName: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="lastName">Last Name</Label>
                      <Input
                        id="lastName"
                        disabled={!editing}
                        value={formData.lastName}
                        onChange={(e) =>
                          setFormData({ ...formData, lastName: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="email">Email</Label>
                    {/*<Input
                      id="email"
                      type="email"
                      disabled={!editing}
                      value={formData.email}
                      onChange={(e) => setFormData({...formData, email: e.target.value})}
                    />*/}
                    <div>{formData.email}</div>
                  </div>
                  <div className="flex justify-end gap-2">
                    {editing ? (
                      <>
                        <Button
                          type="button"
                          variant="outline"
                          onClick={() => setEditing(false)}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" disabled={loading}>
                          {loading ? "Saving..." : "Save Changes"}
                        </Button>
                      </>
                    ) : (
                      <Button type="button" onClick={() => setEditing(true)}>
                        Edit Profile
                      </Button>
                    )}
                  </div>
                </form>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="security" className="space-y-4">
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Shield className="h-5 w-5" />
                  Change Password
                </CardTitle>
              </CardHeader>
              <CardContent>
              <form onSubmit={handlePasswordChange} className="space-y-4">
  <div className="space-y-2">
    <Label htmlFor="currentPassword">Current Password</Label>
    <Input
      id="currentPassword"
      type="password"
      value={passwordData.currentPassword}
      onChange={(e) =>
        setPasswordData({
          ...passwordData,
          currentPassword: e.target.value,
        })
      }
    />
  </div>

  <div className="space-y-2">
    <Label htmlFor="newPassword">New Password</Label>
    <Input
      id="newPassword"
      type="password"
      value={passwordData.newPassword}
      onChange={(e) =>
        setPasswordData({
          ...passwordData,
          newPassword: e.target.value,
        })
      }
      className={passwordData.newPassword && passwordData.newPassword.length < 8 ? "border-red-500 focus:border-red-500" : ""}
    />
    {passwordData.newPassword && passwordData.newPassword.length < 8 && (
      <div className="text-sm text-red-500">
        Password must be at least 8 characters long
      </div>
    )}
  </div>

  <div className="space-y-2">
    <Label htmlFor="confirmPassword">Confirm New Password</Label>
    <Input
      id="confirmPassword"
      type="password"
      value={passwordData.confirmPassword}
      onChange={(e) =>
        setPasswordData({
          ...passwordData,
          confirmPassword: e.target.value,
        })
      }
    />
    {passwordData.newPassword && passwordData.confirmPassword && 
      passwordData.newPassword !== passwordData.confirmPassword && (
      <div className="text-sm text-red-500">
        Passwords do not match
      </div>
    )}
  </div>

  <div className="flex justify-end">
    <Button 
      type="submit" 
      disabled={loading || 
        !passwordData.currentPassword || 
        !passwordData.newPassword || 
        passwordData.newPassword.length < 8 ||
        !passwordData.confirmPassword || 
        passwordData.newPassword !== passwordData.confirmPassword}
    >
      {loading ? "Updating..." : "Update Password"}
    </Button>
  </div>
</form>
              </CardContent>
            </Card>
            <Card className="border-red-200">
              <CardHeader>
                <CardTitle className="flex items-center gap-2 text-red-600">
                  <Trash2 className="h-5 w-5" />
                  Danger Zone
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <p className="text-sm text-muted-foreground">
                    Permanently delete your account and all associated data.
                    You will lose all of your flows, chat history, generated images, and more.
                    <br></br><div className="flex gap-2 font-bold text-lg"><Skull />This action cannot be undone.</div>
                  </p>
                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <Button
                        variant="destructive"
                        className="w-full sm:w-auto"
                      >
                        Delete Account
                      </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>
                          Are you sure you want to delete your account?
                        </AlertDialogTitle>
                        <AlertDialogDescription className="space-y-3">
                          <p>
                            This action is permanent and cannot be undone. All
                            your data will be permanently deleted, including:
                          </p>
                          <ul className="list-disc pl-6 space-y-1">
                            <li>All your flows and executions</li>
                            <li>Your custom assistants and chat history</li>
                            <li>
                              Your generated images and social media posts
                            </li>
                            <li>Your team contributions and shared assets</li>
                            <li>Your subscription and billing information</li>
                          </ul>
                          <p className="font-semibold text-red-600">
                            Note: If you have shared any assets with team
                            members, they will lose access to these assets.
                          </p>
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialog>
                          <AlertDialogTrigger asChild>
                            <AlertDialogAction variant="destructive">
                              Continue with Deletion
                            </AlertDialogAction>
                          </AlertDialogTrigger>
                          <AlertDialogContent>
                            <AlertDialogHeader>
                              <AlertDialogTitle>
                                Final Confirmation Required
                              </AlertDialogTitle>
                              <AlertDialogDescription>
                                Please type "DELETE" to confirm you want to
                                permanently delete your account and all
                                associated data.
                              </AlertDialogDescription>
                            </AlertDialogHeader>
                            <div className="flex items-center gap-2 py-3">
                              <Input
                                placeholder="Type DELETE"
                                onChange={(e) =>
                                  setShowFinalConfirmation(
                                    e.target.value === "DELETE"
                                  )
                                }
                                className="font-mono"
                              />
                            </div>
                            <AlertDialogFooter>
                              <AlertDialogCancel
                                onClick={() => setShowFinalConfirmation(false)}
                              >
                                Cancel
                              </AlertDialogCancel>
                              <AlertDialogAction
                                onClick={handleDeleteAccount}
                                disabled={!showFinalConfirmation || isDeleting}
                                variant="destructive"
                              >
                                {isDeleting
                                  ? "Deleting..."
                                  : "Permanently Delete Account"}
                              </AlertDialogAction>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialog>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </div>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="subscription">
            <SubscriptionManagement />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default Profile;
