import React from 'react';
import { Card, CardHeader, CardContent, CardFooter } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { Check } from 'lucide-react';

const PricingSection = () => {
  const plans = [
    {
      name: "Intro (Free)",
      price: "Free",
      description: "30-day trial",
      features: [
        "2 Active Flows",
        "3 Steps Per Flow",
        "50 Chat Messages",
        "15 Image Generations",
        "15 Flow Executions",
        "1 Custom Assistant",
        "1 Seat",
        "Basic Templates"
      ]
    },
    {
      name: "Individual",
      price: "$19",
      description: "per month",
      features: [
        "5 Active Flows",
        "5 Steps Per Flow",
        "100 Flow Executions",
        "500 Chat Messages",
        "5 Custom Assistants",
        "15 Image Generations",
        "1 Seat",
        "Advanced Templates"
      ]
    },
    {
      name: "Growth",
      price: "$49",
      description: "per month",
      features: [
        "10 Active Flows",
        "10 Steps Per Flow",
        "400 Flow Executions",
        "2000 Chat Messages",
        "10 Custom Assistants",
        "50 Image Generations",
        "Up to 3 Seats",
        "Advanced Templates"
      ],
      popular: true
    },
    {
      name: "Creator",
      price: "$99",
      description: "per month",
      features: [
        "Unlimited Active Flows",
        "50 Steps Per Flow",
        "1000 Flow Executions",
        "5000 Chat Messages",
        "Unlimited Custom Assistants",
        "150 Image Generations",
        "Up to 5 Seats",
        "Advanced Templates"
      ]
    }
  ];

  return (
    <div className="min-h-screen flex flex-col justify-start items-center p-6 lg:p-12 bg-gray-50">
      <div className="pt-10 lg:pt-12 w-full max-w-7xl">
        <h2 className="text-3xl font-bold text-center mb-4">Simple, Transparent Pricing</h2>
        <p className="text-gray-600 text-center mb-12">Choose the plan that's right for you</p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 pb-40">
          {plans.map((plan, index) => (
            <Card 
              key={index}
              className={`relative transform transition-all duration-300 hover:scale-105 ${
                plan.popular ? 'border-2 border-black' : ''
              }`}
            >
              {plan.popular && (
                <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                  <span className="bg-black text-white px-4 py-1 rounded-full text-sm">
                    Most Popular
                  </span>
                </div>
              )}
              
              <CardHeader className="p-4 lg:p-6">
                <h3 className="text-lg lg:text-xl font-bold">{plan.name}</h3>
                <div className="mt-2 lg:mt-4">
                  <span className="text-3xl lg:text-4xl font-bold">{plan.price}</span>
                  <span className="text-gray-500 ml-2">{plan.description}</span>
                </div>
              </CardHeader>
              
              <CardContent className="p-4 lg:p-6">
                <ul className="space-y-2 lg:space-y-3 text-sm lg:text-base">
                  {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-center">
                      <Check className="h-4 w-4 lg:h-5 lg:w-5 text-green-500 mr-2 flex-shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </CardContent>
              
              <CardFooter className="p-4 lg:p-6">
                <Button 
                  className={`w-full text-sm lg:text-base ${
                    plan.popular 
                      ? 'bg-black text-white hover:bg-gray-800' 
                      : 'bg-white text-black border-2 border-black hover:bg-gray-100'
                  }`}
                >
                  Coming Soon
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingSection;