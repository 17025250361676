// src/components/TodoComponents/TodoDetails.js
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import {
  Calendar,
  Clock,
  Tag,
  User2,
  CheckSquare,
  Square,
  AlertCircle,
  Edit,
  Trash2,
} from 'lucide-react';
import {
  updateTodoStatus,
  updateChecklistItem,
  deleteTodo
} from '../../slices/todoSlice';
import { useToast } from "../ui/use-toast";
import TodoForm from "./TodoForm";

const TodoDetails = ({ todo, isOpen, onClose, setSelectedTodo, onSuccess  }) => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const [showEditForm, setShowEditForm] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(todo?.status);

useEffect(() => {
  if (todo) {
    setCurrentStatus(todo.status);
  }
}, [todo]);

  if (!todo) return null;

  const handleStatusChange = async (newStatus) => {
    try {
      await dispatch(updateTodoStatus({ id: todo._id, status: newStatus })).unwrap();
      setCurrentStatus(newStatus);
      setSelectedTodo({...todo, status: newStatus});
      toast({
        title: "Status updated",
        description: `To-do status changed to ${newStatus}`,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update to-do status",
        variant: "destructive",
      });
    }
  };

  const handleChecklistItemToggle = async (index, completed) => {
    // Create a new todo object with updated checklist
    const updatedTodo = {
      ...todo,
      checklist: todo.checklist.map((item, i) => 
        i === index ? { ...item, completed: !completed } : item
      )
    };
  
    // Update local state immediately
    setSelectedTodo(updatedTodo);
  
    try {
      await dispatch(updateChecklistItem({
        todoId: todo._id,
        itemIndex: index,
        completed: !completed
      })).unwrap();
    } catch (error) {
      // Revert the change if API call fails
      setSelectedTodo(todo);
      toast({
        title: "Error",
        description: "Failed to update checklist item",
        variant: "destructive",
      });
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this to-do?')) {
      try {
        await dispatch(deleteTodo(todo._id)).unwrap();
        toast({
          title: "Success",
          description: "To-do deleted successfully",
        });
        onClose();
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to delete to-do",
          variant: "destructive",
        });
      }
    }
  };

  const getStatusColor = (status) => {
    const colors = {
      pending: "bg-yellow-100 text-yellow-800",
      in_progress: "bg-blue-100 text-blue-800",
      completed: "bg-green-100 text-green-800"
    };
    return colors[status] || colors.pending;
  };

  const getPriorityColor = (priority) => {
    const colors = {
      low: "bg-gray-100 text-gray-800",
      medium: "bg-yellow-100 text-yellow-800",
      high: "bg-red-100 text-red-800"
    };
    return colors[priority] || colors.medium;
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl max-h-[80vh] overflow-y-auto z-[60]">
        <DialogHeader>
          <div className="flex items-center justify-between">
            <DialogTitle className="text-xl font-semibold">{todo.title}</DialogTitle>
            <div className="flex items-center gap-2">
              <Button variant="ghost" size="icon" onClick={handleDelete}>
                <Trash2 className="h-4 w-4 text-red-500" />
              </Button>
              <Button variant="ghost" size="icon" onClick={() => setShowEditForm(true)}>
                <Edit className="h-4 w-4" />
              </Button>
            </div>
          </div>
          <div className="flex items-center gap-2 mt-2">
            <Badge className={getStatusColor(todo.status)}>
              {todo.status.replace('_', ' ')}
            </Badge>
            <Badge className={getPriorityColor(todo.priority)}>
              {todo.priority}
            </Badge>
          </div>
        </DialogHeader>

        <div className="space-y-6">
          {/* Description */}
          {todo.description && (
            <div>
              <h3 className="text-sm font-medium mb-2">Description</h3>
              <p className="text-sm text-gray-600">{todo.description}</p>
            </div>
          )}

          {/* Meta Information */}
          <div className="flex flex-wrap gap-4">
            {todo.dueDate && (
              <div className="flex items-center gap-2">
                <Calendar className="h-4 w-4 text-gray-500" />
                <span className="text-sm text-gray-600">
                  Due {format(new Date(todo.dueDate), 'MMM d, yyyy')}
                </span>
              </div>
            )}
            {todo.assignedTo?.length > 0 && (
              <div className="flex items-center gap-2">
                <User2 className="h-4 w-4 text-gray-500" />
                <span className="text-sm text-gray-600">
                  {todo.assignedTo.length} assigned
                </span>
              </div>
            )}
            <div className="flex items-center gap-2">
              <Clock className="h-4 w-4 text-gray-500" />
              <span className="text-sm text-gray-600">
                Created {format(new Date(todo.createdAt), 'MMM d, yyyy')}
              </span>
            </div>
          </div>

          {/* Labels */}
          {todo.labels?.length > 0 && (
            <div>
              <h3 className="text-sm font-medium mb-2">Labels</h3>
              <div className="flex flex-wrap gap-2">
                {todo.labels.map((label, index) => (
                  <Badge key={index} variant="secondary">
                    <Tag className="h-3 w-3 mr-1" />
                    {label}
                  </Badge>
                ))}
              </div>
            </div>
          )}

          {/* Checklist */}
          {todo.checklist?.length > 0 && (
            <div>
              <h3 className="text-sm font-medium mb-2">Checklist</h3>
              <div className="space-y-2">
                {todo.checklist.map((item, index) => (
                  <div 
                    key={index}
                    className="flex items-start gap-2 p-2 hover:bg-gray-50 rounded-md"
                  >
                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-5 w-5"
                      onClick={() => handleChecklistItemToggle(index, item.completed)}
                    >
                      {item.completed ? (
                        <CheckSquare className="h-4 w-4" />
                      ) : (
                        <Square className="h-4 w-4" />
                      )}
                    </Button>
                    <span className={`text-sm ${item.completed ? 'line-through text-gray-400' : 'text-gray-600'}`}>
                      {item.item}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Status Controls */}
          <div>
            <h3 className="text-sm font-medium mb-2">Update Status</h3>
            <div className="flex gap-2">
              <Button
                size="sm"
                variant={currentStatus === 'pending' ? 'default' : 'outline'}
                onClick={() => handleStatusChange('pending')}
              >
                Pending
              </Button>
              <Button
                size="sm"
                variant={currentStatus === 'in_progress' ? 'default' : 'outline'}
                onClick={() => handleStatusChange('in_progress')}
              >
                In Progress
              </Button>
              <Button
                size="sm"
                variant={currentStatus === 'completed' ? 'default' : 'outline'}
                onClick={() => handleStatusChange('completed')}
              >
                Completed
              </Button>
            </div>
          </div>
          <TodoForm 
  todo={todo}
  isOpen={showEditForm}
  onClose={() => setShowEditForm(false)}
  onSuccess={() => {
    setShowEditForm(false);
    onSuccess?.();
  }}
/>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TodoDetails;