import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Button } from "../../components/ui/button";
import { Textarea } from "../../components/ui/textarea";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FileUpload } from '../../components/FileUpload';
import { FilePreview } from '../../components/FilePreview';
import { Lightbulb } from 'lucide-react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import { ScrollArea } from "../../components/ui/scroll-area";

const ChatInput = forwardRef(({
  message,
  setMessage,
  handleSendMessage,
  currentChat,
  selectedAssistant,
  chatTitle,
  files,
  setFiles,
  sentFiles,
  credits,
  limits,
  selectedAssistantFull
}, ref) => {
  const [showFiles, setShowFiles] = useState(false);
  const [suggestionOpen, setSuggestionOpen] = useState(false);
  const [localMessage, setLocalMessage] = useState(message);

  useImperativeHandle(ref, () => ({
    resetLocalMessage: () => setLocalMessage("")
  }));

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  const handleFileUpload = (uploadedFiles) => {
    if (credits.chat <= 0) return;
    if (uploadedFiles.length === 0) return;
    setFiles(prevFiles => [...prevFiles, ...uploadedFiles].slice(0, 5));
  };

  const removeFile = (fileToRemove) => {
    setFiles(files.filter(file => file !== fileToRemove));
  };

  const handleSuggestionClick = (suggestion) => {
    setLocalMessage(suggestion);
    setMessage(suggestion);
    setSuggestionOpen(false);
  };

  const handleLocalChange = (e) => {
    const newValue = e.target.value;
    setLocalMessage(newValue);
    setMessage(newValue);
  };

  return (
    <form onSubmit={handleSendMessage} className="p-2 lg:px-6 border border-gray-400 absolute lg:relative bottom-0 w-full left-0 lg:w-2/3 lg:mx-auto bg-white/80 lg:rounded lg:shadow-xl">
      <div className="flex flex-col md:flex-row md:items-center gap-2 lg:ml-0 px-2 lg:px-0">
        {/* Input and Send Button */}
        <div className="flex items-center gap-2 w-full">
          <div className='flex flex-col w-full'>
            <div className={`text-sm ${credits.chat <= 25 ? 'text-red-500 font-bold' : 'text-gray-600'} mb-1`}>
              {credits.chat > 0 ? `Chat Credits: ${credits.chat}` : 'Out of Chat Credits'}
            </div>
            <div className="relative flex items-center w-full">
              <Textarea
                value={localMessage}
                onChange={handleLocalChange}
                onKeyDown={handleKeyDown}
                placeholder="Type your message..."
                className="flex-grow pr-10"
                rows={1}
              />
              {selectedAssistantFull?.interactions?.suggestedPrompts?.length > 0 && (
                <Popover open={suggestionOpen} onOpenChange={setSuggestionOpen}>
                  <PopoverTrigger asChild>
                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      className="absolute right-2 top-1/2 -translate-y-1/2 bg-yellow-50 border border-yellow-300 hover:bg-yellow-100"
                    >
                      <Lightbulb className="h-4 w-4" />
                      <span className="sr-only">Suggested Prompts</span>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-80 p-0" align="end">
                    <ScrollArea className="h-[300px]">
                      <div className="p-4">
                        <h3 className="font-medium mb-2 flex items-center"><Lightbulb className="h-4 w-4 mr-1 text-yellow-500" />Suggested Prompts</h3>
                        <div className="space-y-2">
                          {selectedAssistantFull.interactions.suggestedPrompts.map((prompt, index) => (
                            <Button
                              key={index}
                              variant="ghost"
                              className="w-full justify-start text-left font-normal h-auto whitespace-normal border"
                              onClick={() => handleSuggestionClick(prompt.text)}
                            >
                              {prompt.text}
                            </Button>
                          ))}
                        </div>
                      </div>
                    </ScrollArea>
                  </PopoverContent>
                </Popover>
              )}
            </div>
          </div>
          
          <Button
            type="submit"
            disabled={(!currentChat && (!selectedAssistant || !chatTitle)) || (!message.trim() && files.length === 0) || credits.chat <= 0}
            className="rounded-full w-10 h-10 flex items-center justify-center p-0"
          >
            <FontAwesomeIcon icon={faPaperPlane} className="h-4 w-4" />
            <span className="sr-only">Send</span>
          </Button>
        </div>

        {/* File Upload and Toggle */}
        <div className="hidden lg:flex items-center justify-end lg:justify-between w-full md:w-auto">
          <FileUpload onFileUpload={handleFileUpload} />
          {files.length > 0 && (
            <button
              type="button"
              onClick={() => setShowFiles(!showFiles)}
              className="text-sm text-gray-600 underline ml-2"
            >
              {showFiles ? 'Hide Files' : `Show Files (${files.length})`}
            </button>
          )}
        </div>
      </div>

      {/* File Previews */}
      {showFiles && files.length > 0 && (
        <div className="mt-2">
          {files.map((file, index) => (
            <FilePreview key={index} file={file} onRemove={removeFile} />
          ))}
        </div>
      )}

      {/* Sent Files Section */}
      {sentFiles && sentFiles.length > 0 && (
        <div className="mt-2">
          <h4 className="text-sm font-medium mb-1">Previously Attached Files:</h4>
          <div className="flex flex-wrap gap-2">
            {sentFiles.map((file, index) => (
              <FilePreview key={index} file={file} />
            ))}
          </div>
        </div>
      )}
    </form>
  );
});

export default ChatInput;