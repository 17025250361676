// ChatComponent.js
import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChatById,
  sendMessage,
  createChat,
  switchAssistant,
  uploadFile,
} from "../services/api";
import {
  setCurrentChat,
  addMessage,
  setLoading,
  setError,
} from "../slices/chatSlice";
import { fetchUserChatbots } from "../slices/userChatbotSlice";
import { fetchUserAddedChatbots } from "../slices/chatbotsSlice";
import { fetchAssistants } from "../slices/flowsSlice";
import { updateCredits } from "../slices/authSlice";
import { SocketContext } from "../App";
import ChatHeader from "./ChatComponents/ChatHeader";
import MessageList from "./ChatComponents/MessageList";
import ChatInput from "./ChatComponents/ChatInput";
import NewChatInstructions from "./ChatComponents/NewChatInstructions";
import ChatHistorySidebar from "./ChatComponents/ChatHistorySidebar";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../components/ui/alert-dialog";
import { Button } from "../components/ui/button";
import { Menu, History } from "lucide-react";
import useSubscription from "../hooks/useSubscription";
import { useToast } from "../components/ui/use-toast";
import { fetchGlobalPromptStacks } from "../slices/promptStackSlice";

const ChatComponent = () => {
  const { chatId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const assistantIdFromQuery = queryParams.get('assistantId');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chatInputRef = useRef();
  const { currentTier, status, credits, limits } = useSubscription();
  const socket = useContext(SocketContext);
  const { currentChat, isLoading, error } = useSelector((state) => state.chat);
    const { selectedTeam } = useSelector((state) => state.teams);
  const { assistants } = useSelector((state) => state.flows);
  const { globalPromptStacks } = useSelector((state) => state.promptStack);
  const [message, setMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [streamedResponse, setStreamedResponse] = useState("");
  const [selectedAssistant, setSelectedAssistant] = useState("");
  const [chatTitle, setChatTitle] = useState(() => {
    const date = new Date();
    return `Chat - ${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  });
  const [isWaiting, setIsWaiting] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const userChatbots = useSelector((state) => state.userChatbots.chatbots);
  const userAddedChatbots = useSelector(state => state.chatbots.userChatbots.chatbots);
  const [assistantType, setAssistantType] = useState("");
  const [chattableUserAddedChatbots, setChattableUserAddedChatbots] = useState([]);
  const [combinedAssistants, setCombinedAssistants] = useState([]);
  const [selectedAssistantFull, setSelectedAssistantFull] = useState(null);
  const [files, setFiles] = useState([]);
  const [sentFiles, setSentFiles] = useState([]);
    const { toast } = useToast();

    useEffect(() => {
      if (!chatId && assistantIdFromQuery && !selectedAssistant) {
        setSelectedAssistant(assistantIdFromQuery);
        const assistant = combinedAssistants.find(a => a._id === assistantIdFromQuery) || 
                         chattableUserAddedChatbots.find(a => a._id === assistantIdFromQuery);
        if (assistant) {
          setAssistantType(assistant.type);
          setSelectedAssistantFull(assistant);
        }
      }
    }, [assistantIdFromQuery, combinedAssistants, chattableUserAddedChatbots, chatId]);

  useEffect(() => {
    const fetchAllAssistants = async () => {
      await dispatch(fetchAssistants());
      await dispatch(fetchUserChatbots());
      await dispatch(fetchUserAddedChatbots());
    };
    fetchAllAssistants();
  }, [dispatch]);

  useEffect(() => {
    const chattableUserChatbots = userChatbots
      //.filter((chatbot) => chatbot.isChattable)
      .map((chatbot) => ({
        ...chatbot,
        type: "UserChatbot",
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    const fetchUserAddedChatbots = userAddedChatbots
      .map((chatbot) => ({
        ...chatbot,
        type: "MarketplaceChatbot",
      }));
      setChattableUserAddedChatbots(fetchUserAddedChatbots);

      console.log("fetchUserAddedChatbots:", fetchUserAddedChatbots);

    const groupedOpenAIAssistants = assistants
      .filter((assistant) => assistant.isChattable)
      .map((assistant) => ({
        ...assistant,
        type: "OpenAIAssistant",
      }))
      .reduce((acc, assistant) => {
        const group = assistant.assistantType || "other";
        if (!acc[group]) acc[group] = [];
        acc[group].push(assistant);
        return acc;
      }, {});

    const sortedGroups = ["personal", "general", "specialist", "other"];

    const sortedOpenAIAssistants = sortedGroups.flatMap((group) =>
      (groupedOpenAIAssistants[group] || []).sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    );

    setCombinedAssistants([
      ...chattableUserChatbots,
      ...sortedOpenAIAssistants,
    ]);
  }, [assistants, userChatbots]);

  useEffect(() => {
    if (currentChat && currentChat.assistant?._id) {
      setSelectedAssistant(currentChat.assistant._id);
      setSelectedAssistantFull(currentChat.assistant);
    } else if (currentChat && currentChat.assistant) {
      setSelectedAssistant(currentChat.assistant);
      setSelectedAssistantFull(currentChat.assistant);
    }

    if (currentChat && currentChat.title) {
      setChatTitle(currentChat.title);
    }
  }, [currentChat]);

  useEffect(() => {
    if (currentChat && currentChat._id && !chatId) {
      navigate(`/chat/${currentChat._id}`);
    }
  }, [currentChat, chatId, navigate]);

  const handleChatStream = useCallback(
    ({ chunkContent, userId, chatId: streamChatId }) => {
      if (streamChatId === chatId) {
        if (chunkContent === "--complete--") {
          setIsTyping(false);
          if (streamedResponse) {
            dispatch(
              addMessage({ role: "assistant", content: streamedResponse })
            );
            setStreamedResponse("");
          }
        } else {
          setIsTyping(true);
          setIsWaiting(false);
          setStreamedResponse((prev) => prev + chunkContent);
        }
      }
    },
    [chatId, dispatch, streamedResponse]
  );

  useEffect(() => {
    if (socket) {
      socket.on("new message", (message) => {
        dispatch(addMessage(message));
      });

      socket.on("chatStream", handleChatStream);

      return () => {
        socket.off("new message");
        socket.off("chatStream");
      };
    }
  }, [socket, dispatch, handleChatStream]);

  useEffect(() => {
    if (chatId && socket && socket.connected) {
      socket.emit("join chat", chatId);
      return () => {
        socket.emit("leave chat", chatId);
      };
    }
  }, [chatId, socket]);

  useEffect(() => {
    if (!chatId) return;

    dispatch(setLoading(true));

    fetchChatById(chatId)
      .then((data) => {
        //console.log("Chat data:", data?.messages?.length);
        // Only set currentChat if messages exist
        if (data?.messages?.length > 0) {
          dispatch(setCurrentChat(data));
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setError(err.message));
        dispatch(setLoading(false));
      });
  }, [chatId, dispatch]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (credits.chat <= 0) return;
    if (
      !selectedAssistant ||
      (!chatTitle && !currentChat?.title) ||
      (!message.trim() && files.length === 0)
    ) {
      let missingItems = [];
      if (!selectedAssistant) missingItems.push("an assistant");
      if (!chatTitle && !currentChat?.title) missingItems.push("a chat title");
      if (!message.trim() && files.length === 0)
        missingItems.push("a message or file");

      const missingItemsText = missingItems.join(" and ");
      setAlertMessage(
        `Please provide ${missingItemsText} before sending a message.`
      );
      setShowAlert(true);
      return;
    }

    let chatToUse = currentChat;

    if (!currentChat) {
      try {
        const newChat = await createChat(selectedAssistant, chatTitle, assistantType, selectedTeam || null);
        chatToUse = newChat.data;
        dispatch(setCurrentChat(chatToUse));
        navigate(`/chat/${chatToUse._id}`);
      } catch (err) {
        dispatch(setError("Failed to create chat"));
        return;
      }
    }

    setIsTyping(true);
    setIsWaiting(true);

    try {
      let fileIds = [];
      if (files.length > 0) {
        const uploadPromises = files.map((file) =>
          uploadFile(chatToUse._id, file)
        );
        const uploadResults = await Promise.all(uploadPromises);
        //console.log("Upload results:", uploadResults);
        fileIds = uploadResults.map((result) => result.data.fileId);
      }

      const newMessage = {
        role: "user",
        content: message,
        files: files.map((f) => f.name),
      };
      dispatch(addMessage(newMessage));
      setMessage("");
      if (chatInputRef.current) {
        chatInputRef.current.resetLocalMessage();
      }
      dispatch(updateCredits({ type: 'chat', amount: 1, subtract: true }));
      const response = await sendMessage(chatToUse._id, message, fileIds, assistantType, selectedTeam || null);
      dispatch(setCurrentChat(response.data));
      setSentFiles((prev) => [...prev, ...files]);
      setFiles([]); // Clear files after sending
    } catch (err) {
      dispatch(setError("Failed to send message"));
      dispatch(updateCredits({ type: 'chat', amount: 1, subtract: false }));
      toast({
        title: "Oops! Insufficient Credits",
        description: err.response?.data?.message || "Failed to send message",
        variant: "destructive",
        duration: 3000,
      });
      setIsTyping(false);
      setIsWaiting(false);
    }
  };

  const handleSwitchAssistant = async (assistantId) => {
    setSelectedAssistant(assistantId);
    const selectedAssistant =
        combinedAssistants.find((a) => a._id === assistantId) ||
        chattableUserAddedChatbots.find((a) => a._id === assistantId);
    if (selectedAssistant) {
      setAssistantType(selectedAssistant.type);
      setSelectedAssistantFull(selectedAssistant);
    }
    if (currentChat && assistantId !== currentChat.assistant) {
      try {
        const updatedChat = await switchAssistant(
          currentChat._id,
          assistantId,
          selectedAssistant.type
        );
        dispatch(setCurrentChat(updatedChat.data.chat));
      } catch (err) {
        dispatch(setError("Failed to switch assistant"));
        setSelectedAssistant(currentChat.assistant);
        setSelectedAssistantFull(currentChat.assistant);
      }
    }
  };

  const handleNewChat = () => {
    dispatch(setCurrentChat(null));
    //setSelectedAssistant("");
    setChatTitle(`Chat - ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`);
    navigate("/chat");
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(!currentChat);
  const toggleHeader = () => setIsHeaderCollapsed(!isHeaderCollapsed);

  //if (error) return <div>Error: {error}</div>;
  /*useEffect(() => {
    if (error) {
      setIsTyping(false);
      setIsWaiting(false);
    }
  }, [error]);*/

  //if (error) return null;

  if (!credits || !limits) {
    return <div className="text-center">Loading subscription details...</div>;
  }

  return (
    <div className="flex flex-col lg:flex-row h-[calc(100vh-18rem)] lg:h-[calc(100vh-0rem)] lg:-ml-6">
      {/* Mobile Header - New Addition */}
      <div className="lg:hidden flex items-center justify-between px-0 border-b bg-background z-[60] -mx-6 -mt-4">
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="mr-2"
        >
          <History className="h-5 w-5" />
        </Button>
        <h2 className="text-sm font-medium truncate">
          {!isSidebarOpen && (currentChat ? currentChat.title : "New Chat")}
        </h2>
        <button
          onClick={toggleHeader}
          className={`m-2 p-2 rounded-full bg-black shadow-lg lg:hidden`}
        >
          {!isHeaderCollapsed ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 text-gray-100"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 15l6-6 6 6"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 text-gray-100"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M18 9l-6 6-6-6"
              />
            </svg>
          )}
        </button>
      </div>

      {/* Chat History Sidebar */}
      <div
        className={`
    border-r bg-background
    fixed lg:relative inset-y-0 left-0 z-[70]
    transform transition-transform duration-200 ease-in-out
    h-[calc(100vh-4rem)] lg:h-[calc(100vh-0rem)]
    ${isSidebarOpen ? "translate-x-0" : "-translate-x-full lg:translate-x-0"}
  `}
      >
        <ChatHistorySidebar
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
        />
      </div>

      {/* Main Chat Area - Updated for mobile */}
      <div className="flex-grow flex flex-col h-[calc(100vh-6rem)] lg:h-auto justify-between">
      <div
  className={`
    transition-all duration-300 ease-in-out mx-auto px-4 py-1 shadow-lg lg:shadow-none lg:px-0 lg:py-0
    
    /* MOBILE COLLAPSE BEHAVIOR */
    ${
      isHeaderCollapsed
        ? "max-h-0 pointer-events-none overflow-hidden"
        : "max-h-[500px] pointer-events-auto overflow-hidden"
    }
    
    /* DESKTOP OVERRIDES - ALWAYS SHOW */
    lg:max-h-none lg:overflow-visible lg:pointer-events-auto
  `}
  style={{
    zIndex: isHeaderCollapsed ? "auto" : 50, // Ensure header appears on top when expanded
    position: isHeaderCollapsed
      ? "static lg:relative"
      : "absolute lg:relative", // Preserve original positioning logic
    top: isHeaderCollapsed ? "auto" : 10,    // Place header at the top when expanded
    width: isHeaderCollapsed ? "100%" : "95%",
    background: isHeaderCollapsed ? "none" : "background", // Give a background when expanded
  }}
>
  <ChatHeader
    currentChat={currentChat}
    handleNewChat={handleNewChat}
    selectedAssistant={selectedAssistant}
    handleSwitchAssistant={handleSwitchAssistant}
    chatTitle={chatTitle}
    setChatTitle={setChatTitle}
    assistants={combinedAssistants}
    toggleSidebar={toggleSidebar}
    credits={credits}
    limits={limits}
  />
</div>

        <div
          className={`flex-grow overflow-y-auto p-1 lg:p-4 lg:py-1 -mx-6 lg:mx-0 transition-all duration-300 ${
            isHeaderCollapsed ? "mt-0 lg:mt-0" : "lg:mt-0"
          }`}
        >
          {currentChat && currentChat.messages ? (
            <MessageList
              messages={currentChat.messages}
              isTyping={isTyping}
              isWaiting={isWaiting}
              streamedResponse={streamedResponse}
            />
          ) : (
            <NewChatInstructions />
          )}
        </div>
        <ChatInput
          ref={chatInputRef}
          message={message}
          setMessage={setMessage}
          handleSendMessage={handleSendMessage}
          currentChat={currentChat}
          selectedAssistant={selectedAssistant}
          chatTitle={chatTitle}
          files={files}
          setFiles={setFiles}
          sentFiles={sentFiles}
          credits={credits}
          limits={limits}
          selectedAssistantFull={selectedAssistantFull}
        />
      </div>

      {/* Mobile Menu Overlay - New Addition
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black/50 z-[65] lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )} */}

      <AlertDialog open={showAlert} onOpenChange={setShowAlert}>
        <AlertDialogContent className="z-[80]">
          <AlertDialogHeader>
            <AlertDialogTitle>Missing Information</AlertDialogTitle>
            <AlertDialogDescription>{alertMessage}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={() => setShowAlert(false)}>
              OK
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default ChatComponent;
