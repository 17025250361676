import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { Check, X } from 'lucide-react';
import useSubscription from '../../hooks/useSubscription';

const PlanComparison = ({ plans }) => {
  const { currentTier } = useSubscription();
  
  const formatValue = (value) => {
    if (value === 999) return 'Unlimited';
    if (typeof value === 'boolean') return value ? <Check className="h-4 w-4 text-primary mx-auto" /> : <X className="h-4 w-4 text-muted-foreground mx-auto" />;
    return value;
  };

  const features = [
    { key: 'price_monthly', label: 'Monthly Price', format: (val) => `$${val}` },
    { key: 'activeFlows', label: 'Active Flows' },
    { key: 'flowSteps', label: 'Steps per Flow' },
    { key: 'chatCredits', label: 'Monthly Chat Credits' },
    { key: 'executionCredits', label: 'Monthly Execution Credits' },
    { key: 'imageCredits', label: 'Monthly Image Credits' },
    { key: 'customAssistants', label: 'Custom Assistants' },
    { key: 'seats', label: 'Available Team Seats' },
    { key: 'isAdvancedTemplates', label: 'Advanced Templates' }
  ];

  return (
    <div className="rounded-lg border bg-card">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[200px]">Feature</TableHead>
            {plans.map((plan) => (
              <TableHead 
                key={plan._id} 
                className="text-center"
              >
                {plan.name}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {features.map((feature) => (
            <TableRow key={feature.key}>
              <TableCell className="font-medium">{feature.label}</TableCell>
              {plans.map((plan) => (
                <TableCell key={plan._id} className="text-center">
                  {feature.format 
                    ? feature.format(plan[feature.key])
                    : formatValue(plan[feature.key])}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default PlanComparison;