import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAudioTracks, setCurrentTrack, setIsPlaying } from '../../slices/audioSlice';
import { Card, CardContent } from "../ui/card";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { ScrollArea } from "../ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { Badge } from "../ui/badge";
import { 
  Search, 
  Music2, 
  Play, 
  Pause,
  Clock,
  Tag,
  BarChart,
  Calendar 
} from 'lucide-react';
import AudioTrackList from './AudioTrackList';

const AudioBrowser = () => {
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [activeTab, setActiveTab] = useState('all');
    const { tracks = [], loading, error } = useSelector(state => state.audio);
    const safeTracks = Array.isArray(tracks) ? tracks : [];
    const currentTrack = useSelector(state => state.audio.currentTrack);
    const isPlaying = useSelector(state => state.audio.isPlaying);
  
    useEffect(() => {
      dispatch(fetchAudioTracks({ search: searchTerm }));
    }, [dispatch, searchTerm]);
  
    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
      console.log('searchTerm:', searchTerm);
    };
  
    const handlePlayTrack = (track) => {
      if (currentTrack?._id === track._id) {
        dispatch(setIsPlaying(!isPlaying));
      } else {
        dispatch(setCurrentTrack(track));
        dispatch(setIsPlaying(true));
      }
    };
  
    return (
      <div className="container mx-auto p-4 space-y-4">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">Audio Library</h1>
          {/*<div className="relative w-72">
            <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search tracks..."
              value={searchTerm}
              onChange={handleSearch}
              className="pl-8"
            />
          </div>*/}
        </div>
  
        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList>
            <TabsTrigger value="all" className="flex items-center gap-2">
              <Music2 className="h-4 w-4" />
              All Tracks
            </TabsTrigger>
            <TabsTrigger value="recent" className="flex items-center gap-2">
              <Clock className="h-4 w-4" />
              Recent
            </TabsTrigger>
            <TabsTrigger value="popular" className="flex items-center gap-2">
              <BarChart className="h-4 w-4" />
              Popular
            </TabsTrigger>
          </TabsList>
  
          <TabsContent value="all" className="space-y-4">
            <AudioTrackList
              tracks={safeTracks}
              currentTrack={currentTrack}
              isPlaying={isPlaying}
              onPlayTrack={handlePlayTrack}
              loading={loading}
            />
          </TabsContent>
  
                    <TabsContent value="recent" className="space-y-4">
            <AudioTrackList
              tracks={[...safeTracks].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))}
              currentTrack={currentTrack}
              isPlaying={isPlaying}
              onPlayTrack={handlePlayTrack}
              loading={loading}
            />
          </TabsContent>
  
          <TabsContent value="popular" className="space-y-4">
            <AudioTrackList
              tracks={[...safeTracks].sort((a, b) => b.playCount - a.playCount)}
              currentTrack={currentTrack}
              isPlaying={isPlaying}
              onPlayTrack={handlePlayTrack}
              loading={loading}
            />
          </TabsContent>
        </Tabs>
  
        {error && (
          <div className="text-red-500 text-center py-4">
            Error loading tracks: {error}
          </div>
        )}
      </div>
    );
  };
  
  export default AudioBrowser;