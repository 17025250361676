import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../slices/authSlice';
const AuthWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);
  const { loading, isAuthenticated, user } = useSelector((state) => state.auth);
  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        //console.log('Stored access token:', accessToken);
        if (accessToken) {
          await dispatch(getCurrentUser()).unwrap();
        }
      } catch (error) {
        console.error('Failed to initialize auth:', error);
      } finally {
        setIsInitialized(true);
      }
    };
    initializeAuth();
  }, [dispatch]);
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    /*console.log('Auth state:', {
      isInitialized,
      loading,
      isAuthenticated,
      user: user ? { ...user, id: user.id } : null,
      accessToken: accessToken ? 'Access token exists' : 'No access token',
      refreshToken: refreshToken ? 'Refresh token exists' : 'No refresh token'
    });*/
  }, [isInitialized, loading, isAuthenticated, user]);
  if (!isInitialized) {
    return <div>Loading...</div>;
  }
  return children;
};
export default AuthWrapper;