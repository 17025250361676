import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  setCurrentTrack, 
  setIsPlaying,
  removeFromPlaylist,
  reorderPlaylist,
  clearPlaylist,
  setPlaylistIndex
} from '../../slices/audioSlice';
import { Button } from '../ui/button';
import { ScrollArea } from '../ui/scroll-area';
import { Card } from '../ui/card';
import { 
  Music2, 
  Play,
  Pause,
  GripVertical,
  X,
  ListMusic,
  Trash2
} from 'lucide-react';

const PlaylistManager = () => {
  const dispatch = useDispatch();
  const playlist = useSelector(state => state.audio.currentPlaylist);
  const currentTrack = useSelector(state => state.audio.currentTrack);
  const isPlaying = useSelector(state => state.audio.isPlaying);
  const currentPlaylistIndex = useSelector(state => state.audio.currentPlaylistIndex);

  const handlePlay = (track, index) => {
    dispatch(setCurrentTrack(track));
    dispatch(setPlaylistIndex(index));
    dispatch(setIsPlaying(true));
  };

  const handleRemove = (trackId) => {
    dispatch(removeFromPlaylist(trackId));
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, newIndex) => {
    const oldIndex = parseInt(e.dataTransfer.getData('text/plain'));
    dispatch(reorderPlaylist({ oldIndex, newIndex }));
  };

  if (!playlist.length) {
    return (
      <Card className="p-4 text-center text-muted-foreground">
        <ListMusic className="w-8 h-8 mx-auto mb-2" />
        <p>Playlist is empty</p>
      </Card>
    );
  }

  return (
    <ScrollArea className="h-[300px]">
      <div className="space-y-2 p-2">
        {playlist.map((track, index) => (
          <Card
            key={track._id}
            className={`flex items-center p-2 gap-2 ${
              currentTrack?._id === track._id ? 'bg-accent' : ''
            }`}
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
          >
            <div 
              className="cursor-move p-1 hover:bg-accent rounded"
              onMouseDown={(e) => e.preventDefault()}
            >
              <GripVertical className="w-4 h-4 text-muted-foreground" />
            </div>

            <div className="w-8 h-8 bg-primary/10 rounded-lg flex items-center justify-center flex-shrink-0">
              {track.coverUrl ? (
                <img 
                  src={track.coverUrl} 
                  alt={track.title} 
                  className="w-full h-full object-cover rounded-lg"
                />
              ) : (
                <Music2 className="w-4 h-4 text-primary" />
              )}
            </div>

            <div className="flex-1 min-w-0">
              <div className="font-medium truncate">
                {currentTrack?._id === track._id && (
                  <span className="text-primary mr-2">►</span>
                )}
                {track.title}
              </div>
              <div className="text-sm text-muted-foreground truncate">
                {track.artist}
              </div>
            </div>

            <div className="flex items-center gap-1">
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8"
                onClick={() => handlePlay(track, index)}
              >
                {currentTrack?._id === track._id && isPlaying ? (
                  <Pause className="h-4 w-4" />
                ) : (
                  <Play className="h-4 w-4" />
                )}
              </Button>
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8 text-destructive"
                onClick={() => handleRemove(track._id)}
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          </Card>
        ))}
      </div>

      {playlist.length > 0 && (
        <div className="p-2">
          <Button 
            variant="outline" 
            className="w-full"
            onClick={() => dispatch(clearPlaylist())}
          >
            <Trash2 className="w-4 h-4 mr-2" />
            Clear Playlist
          </Button>
        </div>
      )}
    </ScrollArea>
  );
};

export default PlaylistManager;