// src/pages/TodoPage.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTodos,
  fetchTodoStats,
  updateFilters,
  selectTodos,
  selectTodoLoading,
  selectTodoError,
  selectTodoStats,
  selectTodoFilters,
  bulkArchiveTodos
} from '../slices/todoSlice';
import TodoList from '../components/TodoList';
import TodoDetails from '../components/TodoComponents/TodoDetails';
import TodoForm from '../components/TodoComponents/TodoForm';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { Card, CardContent } from "../components/ui/card";
import { Badge } from "../components/ui/badge";
import { ScrollArea } from "../components/ui/scroll-area";
import { Separator } from "../components/ui/separator";
import { useToast } from "../components/ui/use-toast";
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from "../components/ui/collapsible";
import {
    Check,
    Plus,
    Search,
    Filter,
    Calendar,
    ListFilter,
    Clock,
    Archive,
    Loader2,
    AlertCircle,
    Users,
    Flame,
    ListChecks,
    ChevronDown,
    ArrowDownNarrowWide,
  } from 'lucide-react';
import { format } from 'date-fns';

const StatCard = ({ title, value, icon: Icon, className = "" }) => (
    <Card className={`min-w-[200px] ${className}`}>
      <CardContent className="p-6">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-muted-foreground">{title}</p>
            <h3 className="text-2xl font-bold mt-2">{value}</h3>
          </div>
          <div className="h-12 w-12 rounded-full bg-black flex items-center justify-center">
            <Icon className="h-6 w-6 text-white" />
          </div>
        </div>
      </CardContent>
    </Card>
  );
  
  const TodoPage = () => {
    const dispatch = useDispatch();
    const { toast } = useToast();
    const [selectedTodo, setSelectedTodo] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [showArchived, setShowArchived] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [statsOpen, setStatsOpen] = useState(false);
const [filtersOpen, setFiltersOpen] = useState(false);
  
    const todos = useSelector(selectTodos);
    const loading = useSelector(selectTodoLoading);
    const error = useSelector(selectTodoError);
    const stats = useSelector(selectTodoStats);
    const filters = useSelector(selectTodoFilters);
    const { teams } = useSelector((state) => state.teams);
  
    // Fetch todos and stats on mount and when filters change
    useEffect(() => {
        const fetchData = async () => {
          await Promise.all([
            dispatch(fetchTodos({
              ...filters,
              search: searchTerm,
              isArchived: showArchived,
              team: filters.team === 'all' ? undefined : filters.team
            })),
            dispatch(fetchTodoStats())
          ]);
        };
        fetchData();
      }, [dispatch, filters, searchTerm, showArchived]);
  
    // Handle filter changes
    const handleFilterChange = (key, value) => {
      // If value is "all", we want to remove the filter
      const filterValue = value === "all" ? undefined : value;
      dispatch(updateFilters({ [key]: filterValue }));
      dispatch(fetchTodoStats(value));
    };
  
    // Handle bulk actions
    const handleBulkArchive = async () => {
      if (selectedItems.length === 0) return;
  
      try {
        await dispatch(bulkArchiveTodos(selectedItems)).unwrap();
        setSelectedItems([]);
        toast({
          title: "Success",
          description: "Selected to-dos archived successfully"
        });
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to archive selected to-dos",
          variant: "destructive"
        });
      }
    };
  
    // Handle search with debounce
    useEffect(() => {
      const timer = setTimeout(() => {
        dispatch(updateFilters({ search: searchTerm }));
      }, 500);
  
      return () => clearTimeout(timer);
    }, [searchTerm, dispatch]);
  
    // Handle form success
    const handleFormSuccess = () => {
      // Refresh todos
      dispatch(fetchTodos({
        ...filters,
        search: searchTerm,
        isArchived: showArchived
      }));
      dispatch(fetchTodoStats());
    };
  
    if (error) {
      return (
        <div className="flex items-center justify-center h-full">
          <Card className="w-full max-w-md">
            <CardContent className="p-6">
              <div className="flex items-center gap-2 text-red-600">
                <AlertCircle className="h-5 w-5" />
                <p>Failed to load to-dos: {error}</p>
              </div>
            </CardContent>
          </Card>
        </div>
      );
    }
  
    return (
        <div className="container mx-auto py-2 lg:py-6 px-2 lg:px-4 space-y-2 lg:space-y-6">
          {/* Header */}
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
            <h1 className="text-3xl font-bold">To-dos</h1>
            <Button 
              className="gap-2"
              onClick={() => setShowCreateForm(true)}
            >
              <Plus className="h-4 w-4" />
              Create To-do
            </Button>
          </div>
      
          {/* Stats Cards */}
<Collapsible
  open={statsOpen || window.innerWidth >= 1024}
  onOpenChange={setStatsOpen}
  className="lg:block"
>
  <CollapsibleTrigger className="flex items-center gap-2 lg:hidden w-full">
    <h2 className="text-lg font-semibold">Statistics</h2>
    <ChevronDown className={`h-4 w-4 transition-transform ${statsOpen ? 'transform rotate-180' : ''}`} />
  </CollapsibleTrigger>
  <CollapsibleContent>
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6">
            <StatCard
              title="Total Active"
              value={stats.data.totalActive}
              icon={Check}
            />
            <StatCard
              title="Due This Week"
              value={stats.data.dueThisWeek}
              icon={Calendar}
            />
            <StatCard
              title="In Progress"
              value={stats.data.statusDistribution.in_progress || 0}
              icon={Clock}
            />
            <StatCard
              title="Archived"
              value={stats.data.totalArchived}
              icon={Archive}
            />
    </div>
  </CollapsibleContent>
</Collapsible>
      
          {/* Main Content */}
          <div className="flex flex-col lg:flex-row gap-6">
            {/* Filters Sidebar */}
<Collapsible
  open={filtersOpen || window.innerWidth >= 1024}
  onOpenChange={setFiltersOpen}
  className="lg:block"
>
  <CollapsibleTrigger className="flex items-center gap-2 lg:hidden w-full">
    <h2 className="text-lg font-semibold">Filters</h2>
    <ChevronDown className={`h-4 w-4 transition-transform ${filtersOpen ? 'transform rotate-180' : ''}`} />
  </CollapsibleTrigger>
  <CollapsibleContent>
    <Card className="w-full lg:w-64 shrink-0 mt-4">
    <Card className="w-full lg:w-64 shrink-0">
              <CardContent className="p-4">
                <div className="space-y-4">
                  <h3 className="font-medium flex items-center gap-2">
                    <Filter className="h-4 w-4" />
                    Filters
                  </h3>
                  
                  <div className="space-y-2">
                    <p className="text-sm text-muted-foreground flex items-center"><Users className='mr-1 h-4 w-4' />Team</p>
                    <Select
                      value={filters.team || 'all'}
                      onValueChange={(value) => handleFilterChange('team', value)}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="All Teams" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">All Teams</SelectItem>
                        <SelectItem value="personal">Personal</SelectItem>
                        {teams?.map(team => (
                          <SelectItem key={team._id} value={team._id}>
                            {team.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
      
                  <div className="space-y-2">
                    <p className="text-sm text-muted-foreground flex items-center"><ListChecks className='mr-1 h-4 w-4' />Status</p>
                    <Select
                      value={filters.status || 'all'}
                      onValueChange={(value) => handleFilterChange('status', value)}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="All Status" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">All Status</SelectItem>
                        <SelectItem value="pending">Pending</SelectItem>
                        <SelectItem value="in_progress">In Progress</SelectItem>
                        <SelectItem value="completed">Completed</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
      
                  <div className="space-y-2">
                    <p className="text-sm text-muted-foreground flex items-center"><Flame className='mr-1 h-4 w-4' />Priority</p>
                    <Select
                      value={filters.priority || 'all'}
                      onValueChange={(value) => handleFilterChange('priority', value)}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="All Priority" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">All Priority</SelectItem>
                        <SelectItem value="low">Low</SelectItem>
                        <SelectItem value="medium">Medium</SelectItem>
                        <SelectItem value="high">High</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
      
                  <Separator />
      
                  <div className="space-y-2">
                    <p className="text-sm text-muted-foreground flex items-center"><ArrowDownNarrowWide className='mr-1 h-4 w-4' />Sort By</p>
                    <Select
                      value={filters.sort}
                      onValueChange={(value) => handleFilterChange('sort', value)}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Sort by" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="-createdAt">Newest First</SelectItem>
                        <SelectItem value="createdAt">Oldest First</SelectItem>
                        <SelectItem value="dueDate">Due Date</SelectItem>
                        <SelectItem value="-priority">Priority</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
      
                  <Button
                    variant="outline"
                    className="w-full"
                    onClick={() => setShowArchived(!showArchived)}
                  >
                    {showArchived ? 'Hide Archived' : 'Show Archived'}
                  </Button>
                </div>
              </CardContent>
            </Card>
    </Card>
  </CollapsibleContent>
</Collapsible>
            
      
            {/* Todo List */}
            <div className="flex-1 space-y-4">
              {/* Search and Bulk Actions */}
              <div className="flex flex-col sm:flex-row gap-4">
                <div className="relative flex-1">
                  <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
                  <Input
                    placeholder="Search todos..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="pl-10"
                  />
                </div>
                
                {selectedItems.length > 0 && (
                  <Button
                    variant="outline"
                    className="gap-2 whitespace-nowrap"
                    onClick={handleBulkArchive}
                  >
                    <Archive className="h-4 w-4" />
                    Archive Selected ({selectedItems.length})
                  </Button>
                )}
              </div>
      
              {/* Todo List Component */}
              <TodoList
                showHeader={false}
                onItemClick={setSelectedTodo}
                selectedItems={selectedItems}
                onItemSelect={setSelectedItems}
                teamId={filters.team === 'all' ? null : filters.team}
              />
            </div>
          </div>
      
          {/* Todo Form Modal */}
          <TodoForm
            isOpen={showCreateForm}
            onClose={() => setShowCreateForm(false)}
            onSuccess={handleFormSuccess}
          />
      
          {/* Todo Details Modal */}
          {selectedTodo && (
            <TodoDetails
              todo={selectedTodo}
              isOpen={!!selectedTodo}
              onClose={() => setSelectedTodo(null)}
              setSelectedTodo={setSelectedTodo}
              onSuccess={handleFormSuccess}
            />
          )}
        </div>
      );
    };
  
  export default TodoPage;