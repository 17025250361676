// src/pages/SchedulingComponents/ScheduleConfigForm.js
import React, { useState, useEffect } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { Input } from '../../components/ui/input';
import { Label } from '../../components/ui/label';

const ScheduleConfigForm = ({ type, config, onChange }) => {
  const [selectedMinute, setSelectedMinute] = useState(config.minutes?.[0] ?? 0);
  const [selectedHour, setSelectedHour] = useState(config.hours?.[0] ?? 0);
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(config.daysOfWeek?.[0] ?? 0);
  const [selectedDayOfMonth, setSelectedDayOfMonth] = useState(config.daysOfMonth?.[0] ?? 1);

  useEffect(() => {
    setSelectedMinute(config.minutes?.[0] ?? 0);
    setSelectedHour(config.hours?.[0] ?? 0);
    setSelectedDayOfWeek(config.daysOfWeek?.[0] ?? 0);
    setSelectedDayOfMonth(config.daysOfMonth?.[0] ?? 1);
  }, [config.minutes, config.hours, config.daysOfWeek, config.daysOfMonth]);

  const handleConfigChange = (key, value) => {
    onChange({ ...config, [key]: [value] });
  };

  const renderHourlyConfig = () => (
    <div className="space-y-4">
      <Label>Minute of the hour</Label>
      <Select
        value={selectedMinute.toString()}
        onValueChange={(value) => {
          const minute = parseInt(value);
          setSelectedMinute(minute);
          handleConfigChange('minutes', minute);
        }}
      >
        <SelectTrigger>
          <SelectValue>{selectedMinute}</SelectValue>
        </SelectTrigger>
        <SelectContent>
          {[0, 15, 30, 45].map((minute) => (
            <SelectItem key={minute} value={minute.toString()}>
              {minute}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );

  const renderDailyConfig = () => (
    <div className="space-y-4">
      <Label>Hour of the day (24-hour format)</Label>
      <Select
        value={selectedHour.toString()}
        onValueChange={(value) => {
          const hour = parseInt(value);
          setSelectedHour(hour);
          handleConfigChange('hours', hour);
        }}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select hour" />
        </SelectTrigger>
        <SelectContent>
          {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
            <SelectItem key={hour} value={hour.toString()}>
              {hour.toString().padStart(2, '0')}:00
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );

  const renderTwiceDailyConfig = () => (
    <div className="space-y-4">
      <Label>First hour of the day (24-hour format)</Label>
      <Select
        value={config.hours?.[0]?.toString() || '0'}
        onValueChange={(value) => handleConfigChange('hours', [parseInt(value), config.hours?.[1] ?? 12])}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select first hour" />
        </SelectTrigger>
        <SelectContent>
          {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
            <SelectItem key={hour} value={hour.toString()}>
              {hour.toString().padStart(2, '0')}:00
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Label>Second hour of the day (24-hour format)</Label>
      <Select
        value={config.hours?.[1]?.toString() || '12'}
        onValueChange={(value) => handleConfigChange('hours', [config.hours?.[0] ?? 0, parseInt(value)])}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select second hour" />
        </SelectTrigger>
        <SelectContent>
          {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
            <SelectItem key={hour} value={hour.toString()}>
              {hour.toString().padStart(2, '0')}:00
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );

  const renderWeeklyConfig = () => (
    <div className="space-y-4">
      <Label>Day of the week</Label>
      <Select
        value={selectedDayOfWeek.toString()}
        onValueChange={(value) => {
          const day = parseInt(value);
          setSelectedDayOfWeek(day);
          handleConfigChange('daysOfWeek', day);
        }}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select day of week" />
        </SelectTrigger>
        <SelectContent>
          {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
            <SelectItem key={index} value={index.toString()}>
              {day}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Label>Hour of the day (24-hour format)</Label>
      <Select
        value={selectedHour.toString()}
        onValueChange={(value) => {
          const hour = parseInt(value);
          setSelectedHour(hour);
          handleConfigChange('hours', hour);
        }}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select hour" />
        </SelectTrigger>
        <SelectContent>
          {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
            <SelectItem key={hour} value={hour.toString()}>
              {hour.toString().padStart(2, '0')}:00
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );

  const renderMonthlyConfig = () => (
    <div className="space-y-4">
      <Label>Day of the month</Label>
      <Select
        value={selectedDayOfMonth.toString()}
        onValueChange={(value) => {
          const day = parseInt(value);
          setSelectedDayOfMonth(day);
          handleConfigChange('daysOfMonth', day);
        }}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select day of month" />
        </SelectTrigger>
        <SelectContent>
          {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
            <SelectItem key={day} value={day.toString()}>
              {day}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Label>Hour of the day (24-hour format)</Label>
      <Select
        value={selectedHour.toString()}
        onValueChange={(value) => {
          const hour = parseInt(value);
          setSelectedHour(hour);
          handleConfigChange('hours', hour);
        }}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select hour" />
        </SelectTrigger>
        <SelectContent>
          {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
            <SelectItem key={hour} value={hour.toString()}>
              {hour.toString().padStart(2, '0')}:00
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );

  const renderCustomConfig = () => (
    <div className="space-y-4">
      <Label>Custom Cron Expression</Label>
      <Input
        type="text"
        value={config.customCron || ''}
        onChange={(e) => handleConfigChange('customCron', e.target.value)}
        placeholder="Enter custom cron expression"
      />
    </div>
  );

  switch (type) {
    case 'hourly':
      return renderHourlyConfig();
    case 'daily':
      return renderDailyConfig();
    case 'twice_daily':
      return renderTwiceDailyConfig();
    case 'weekly':
      return renderWeeklyConfig();
    case 'monthly':
      return renderMonthlyConfig();
    case 'custom':
      return renderCustomConfig();
    default:
      return null;
  }
};

export default ScheduleConfigForm;