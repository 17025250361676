// src/components/TodoComponents/SelectionBar.js
import React from 'react';
import { Button } from "../ui/button";
import { Separator } from "../ui/separator";
import {
  Archive,
  Trash2,
  X,
  CheckSquare,
  Square
} from 'lucide-react';

const SelectionBar = ({
  selectedCount,
  onClearSelection,
  onArchiveSelected,
  onDeleteSelected,
  onSelectAll,
  isAllSelected,
  totalItems
}) => {
  if (selectedCount === 0) return null;

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50">
      <div className="bg-gray-200 border border-gray-700 rounded-lg shadow-lg px-4 py-1 lg:py-2 flex flex-col lg:flex-row items-center gap-0 lg:gap-4">
        <Button
          variant="ghost"
          size="sm"
          onClick={onSelectAll}
          className="gap-2"
        >
          {isAllSelected ? (
            <CheckSquare className="h-4 w-4" />
          ) : (
            <Square className="h-4 w-4" />
          )}
          {isAllSelected ? 'Deselect All' : 'Select All'}
        </Button>

        <Separator orientation="vertical" className="hidden lg:block h-6" />
        
        <span className="text-sm font-medium">
          {selectedCount} item{selectedCount !== 1 ? 's' : ''} selected
        </span>

        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={onArchiveSelected}
            className="gap-2"
          >
            <Archive className="h-4 w-4" />
            Archive Selected
          </Button>

          <Button
            variant="outline"
            size="sm"
            onClick={onDeleteSelected}
            className="gap-2 text-red-600 hover:text-red-600"
          >
            <Trash2 className="h-4 w-4" />
            Delete Selected
          </Button>
        </div>

        <Button
          variant="ghost"
          size="icon"
          onClick={onClearSelection}
          className="ml-2"
        >
          <X className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

export default SelectionBar;