export const timezones = [
  { name: 'Pacific/Kiritimati (UTC+14:00)', value: 'Pacific/Kiritimati', aliases: ['Pacific/Kiritimati'] },
  { name: 'Pacific/Apia (UTC+13:00)', value: 'Pacific/Apia', aliases: ['Pacific/Apia'] },
  { name: 'Pacific/Auckland (UTC+13:00)', value: 'Pacific/Auckland', aliases: ['Pacific/Auckland'] },
  { name: 'Pacific/Fiji (UTC+12:00)', value: 'Pacific/Fiji', aliases: ['Pacific/Fiji'] },
  { name: 'Pacific/Noumea (UTC+11:00)', value: 'Pacific/Noumea', aliases: ['Pacific/Noumea'] },
  { name: 'Australia/Sydney (UTC+10:00)', value: 'Australia/Sydney', aliases: ['Australia/Sydney'] },
  { name: 'Asia/Tokyo (UTC+09:00)', value: 'Asia/Tokyo', aliases: ['Asia/Tokyo'] },
  { name: 'Asia/Seoul (UTC+09:00)', value: 'Asia/Seoul', aliases: ['Asia/Seoul'] },
  { name: 'Asia/Shanghai (UTC+08:00)', value: 'Asia/Shanghai', aliases: ['Asia/Shanghai'] },
  { name: 'Asia/Singapore (UTC+08:00)', value: 'Asia/Singapore', aliases: ['Asia/Singapore'] },
  { name: 'Asia/Hong_Kong (UTC+08:00)', value: 'Asia/Hong_Kong', aliases: ['Asia/Hong_Kong'] },
  { name: 'Asia/Bangkok (UTC+07:00)', value: 'Asia/Bangkok', aliases: ['Asia/Bangkok'] },
  { name: 'Asia/Jakarta (UTC+07:00)', value: 'Asia/Jakarta', aliases: ['Asia/Jakarta'] },
  { name: 'Asia/Kolkata (UTC+05:30)', value: 'Asia/Kolkata', aliases: ['Asia/Kolkata'] },
  { name: 'Asia/Dubai (UTC+04:00)', value: 'Asia/Dubai', aliases: ['Asia/Dubai'] },
  { name: 'Europe/Moscow (UTC+03:00)', value: 'Europe/Moscow', aliases: ['Europe/Moscow'] },
  { name: 'Africa/Nairobi (UTC+03:00)', value: 'Africa/Nairobi', aliases: ['Africa/Nairobi'] },
  { name: 'Europe/Istanbul (UTC+03:00)', value: 'Europe/Istanbul', aliases: ['Europe/Istanbul'] },
  { name: 'Europe/Athens (UTC+03:00)', value: 'Europe/Athens', aliases: ['Europe/Athens'] },
  { name: 'Europe/Berlin (UTC+02:00)', value: 'Europe/Berlin', aliases: ['Europe/Berlin'] },
  { name: 'Europe/Paris (UTC+02:00)', value: 'Europe/Paris', aliases: ['Europe/Paris'] },
  { name: 'Europe/Rome (UTC+02:00)', value: 'Europe/Rome', aliases: ['Europe/Rome'] },
  { name: 'Europe/London (UTC+01:00)', value: 'Europe/London', aliases: ['Europe/London'] },
  { name: 'UTC (UTC+00:00)', value: 'UTC', aliases: ['UTC'] },
  { name: 'Atlantic/Reykjavik (UTC+00:00)', value: 'Atlantic/Reykjavik', aliases: ['Atlantic/Reykjavik'] },
  { name: 'America/Sao_Paulo (UTC-03:00)', value: 'America/Sao_Paulo', aliases: ['America/Sao_Paulo'] },
  { name: 'America/New_York (UTC-04:00)', value: 'America/New_York', aliases: ['America/New_York', 'America/Indiana/Indianapolis', 'America/Indiana/Marengo', 'America/Indiana/Petersburg', 'America/Indiana/Vevay', 'America/Indiana/Vincennes', 'America/Indiana/Winamac', 'America/Kentucky/Louisville', 'America/Kentucky/Monticello'] },
  { name: 'America/Toronto (UTC-04:00)', value: 'America/Toronto', aliases: ['America/Toronto'] },
  { name: 'America/Chicago (UTC-05:00)', value: 'America/Chicago', aliases: ['America/Chicago', 'America/Indiana/Knox', 'America/Indiana/Tell_City'] },
  { name: 'America/Mexico_City (UTC-05:00)', value: 'America/Mexico_City', aliases: ['America/Mexico_City'] },
  { name: 'America/Denver (UTC-06:00)', value: 'America/Denver', aliases: ['America/Denver'] },
  { name: 'America/Phoenix (UTC-07:00)', value: 'America/Phoenix', aliases: ['America/Phoenix'] },
  { name: 'America/Los_Angeles (UTC-07:00)', value: 'America/Los_Angeles', aliases: ['America/Los_Angeles'] },
  { name: 'America/Vancouver (UTC-07:00)', value: 'America/Vancouver', aliases: ['America/Vancouver'] },
  { name: 'America/Anchorage (UTC-08:00)', value: 'America/Anchorage', aliases: ['America/Anchorage'] },
  { name: 'Pacific/Honolulu (UTC-10:00)', value: 'Pacific/Honolulu', aliases: ['Pacific/Honolulu'] }
];
  