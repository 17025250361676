import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '../../components/ui/dialog';
import { Button } from '../../components/ui/button';
import { RadioGroup, RadioGroupItem } from '../../components/ui/radio-group';
import { Label } from '../../components/ui/label';
import { Users, User } from 'lucide-react';

const SubscriptionTypeModal = ({ 
  isOpen, 
  onClose, 
  onSelect,
  planDetails 
}) => {
  const [selectedType, setSelectedType] = React.useState('individual');

  const handleSubmit = () => {
    onSelect(selectedType);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Choose Your Subscription Type</DialogTitle>
          <DialogDescription>
            Select how you want to use your {planDetails.name} subscription
          </DialogDescription>
        </DialogHeader>

        <RadioGroup
          value={selectedType}
          onValueChange={setSelectedType}
          className="grid gap-6 pt-4"
        >
          <div className={`flex items-center space-x-4 p-4 rounded-lg border-2 ${
            selectedType === 'individual' ? 'border-green-500 bg-green-100' : 'border-border'
          }`}>
            <RadioGroupItem value="individual" id="individual" className="sr-only" />
            <Label 
              htmlFor="individual" 
              className="flex-1 cursor-pointer space-y-3"
            >
              <div className="flex items-center gap-2">
                <User className="h-5 w-5" />
                <span className="font-semibold">Individual Account</span>
              </div>
              <p className="text-sm text-muted-foreground">
                Apply all subscription benefits directly to your personal account. 
                Get {planDetails.chatCredits} chat credits, {planDetails.executionCredits} execution credits, 
                and {planDetails.imageCredits} image credits monthly.
              </p>
            </Label>
          </div>

          <div className={`flex items-center space-x-4 p-4 rounded-lg border-2 ${
            selectedType === 'team' ? 'border-green-500 bg-green-100' : 'border-border'
          }`}>
            <RadioGroupItem value="team" id="team" className="sr-only" />
            <Label 
              htmlFor="team" 
              className="flex-1 cursor-pointer space-y-3"
            >
              <div className="flex items-center gap-2">
                <Users className="h-5 w-5" />
                <span className="font-semibold">Shared Team Account</span>
              </div>
              <p className="text-sm text-muted-foreground">
                Create a team with up to {planDetails.seats} members to share subscription 
                benefits. Pool {planDetails.chatCredits} chat credits, {planDetails.executionCredits} execution credits, 
                and {planDetails.imageCredits} image credits monthly.
              </p>
            </Label>
          </div>
        </RadioGroup>

        <div className="flex justify-end space-x-2 mt-4">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button className='hover:bg-green-500' onClick={handleSubmit}>
            Continue
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionTypeModal;