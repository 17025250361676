// src/slices/videoModalSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  videos: [], // Array of video objects with url and title
};

const videoModalSlice = createSlice({
  name: 'videoModal',
  initialState,
  reducers: {
    openVideoModal: (state, action) => {
      state.isOpen = true;
      state.videos = Array.isArray(action.payload) ? action.payload : [action.payload];
    },
    closeVideoModal: (state) => {
      state.isOpen = false;
      state.videos = [];
    },
  },
});

export const { openVideoModal, closeVideoModal } = videoModalSlice.actions;
export default videoModalSlice.reducer;