// src/slices/genericItemsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchItems as fetchItemsApi } from '../services/api';

export const fetchItems = createAsyncThunk(
    'genericItems/fetchItems',
    async (modelName, { rejectWithValue }) => {
      try {
        const response = await fetchItemsApi(modelName);
        
        // Sort the items alphabetically by name
        const sortedItems = response.sort((a, b) => 
          a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
        );
  //console.log('Sorted items:', sortedItems);
        return { modelName, items: sortedItems };
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

const genericItemsSlice = createSlice({
  name: 'genericItems',
  initialState: {
    items: {},
    isLoading: {}, // Changed from a boolean to an object
    error: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(fetchItems.pending, (state, action) => {
      const modelName = action.meta.arg;
      state.isLoading[modelName] = true;
      state.error[modelName] = null;
    })
    .addCase(fetchItems.fulfilled, (state, action) => {
      const { modelName, items } = action.payload;
      state.isLoading[modelName] = false;
      state.items[modelName] = items;
    })
    .addCase(fetchItems.rejected, (state, action) => {
      const modelName = action.meta.arg;
      state.isLoading[modelName] = false;
      state.error[modelName] = action.payload;
    });
  },
});

export default genericItemsSlice.reducer;