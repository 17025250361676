import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Play, Edit, Trash2, MoreVertical } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../components/ui/alert-dialog";
import { removePromptStack } from '../../slices/promptStackSlice';
import { Card, CardContent } from "../../components/ui/card";
import { ScrollArea } from "../../components/ui/scroll-area";
import TeamShareButton from "../../components/TeamShare/TeamShareButton";

const SavedPromptStacks = ({ 
  stacks, 
  onExecute, 
  onEdit,
  className = "",
  credits,
  limits
}) => {
  const [stackToDelete, setStackToDelete] = React.useState(null);
  const dispatch = useDispatch();

  const handleDelete = async () => {
    if (stackToDelete) {
      await dispatch(removePromptStack(stackToDelete._id));
      setStackToDelete(null);
    }
  };

  return (
    <>
      <ScrollArea className={className}>
        <div className="flex flex-col h-full ">
          <div className="px-4 py-2 lg:pt-0 border-b sticky top-0 bg-black">
            <span className={`text-sm ${credits.chat <= 25 ? 'text-red-500 font-bold' : 'text-gray-300'}`}>
              {credits.chat} chat credits remaining
            </span>
          </div>
  
          <div className="space-y-3 p-4">
            {stacks.map((stack) => (
              <Card 
                key={stack._id} 
                className={`relative border ${
                  credits.chat < stack.prompts.length ? 'border-red-500 bg-red-50' : 'bg-gray-100'
                }`}
              >
                <CardContent className="p-4">
                  <div className="flex items-center justify-between gap-2">
                    <div className="flex-1 min-w-0">
                      {credits.chat < stack.prompts.length && (
                        <div className="absolute top-0 right-0 bg-red-500 text-white text-xs font-semibold px-2 py-0.5 rounded-bl-md">
                          {stack.prompts.length - credits.chat} credit(s) needed
                        </div>
                      )}
                      <h3 className="font-medium truncate pr-8">
                        {stack.title}
                      </h3>
                      <p className="text-sm text-muted-foreground">
                        {stack.prompts.length} step{stack.prompts.length !== 1 ? 's' : ''}
                      </p>
                    </div>
  
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                          <MoreVertical className="h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end" className="w-48 z-[60]">
                        <DropdownMenuLabel>Actions</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        {credits.chat >= stack.prompts.length && (
                          <DropdownMenuItem onClick={() => onExecute(stack)}>
                            <Play className="h-4 w-4 mr-2" />
                            Execute
                          </DropdownMenuItem>
                        )}
                        <DropdownMenuItem onClick={() => onEdit(stack)}>
                          <Edit className="h-4 w-4 mr-2" />
                          Edit
                        </DropdownMenuItem>
                        <DropdownMenuItem 
                          className="text-destructive"
                          onClick={() => setStackToDelete(stack)}
                        >
                          <Trash2 className="h-4 w-4 mr-2" />
                          Delete
                        </DropdownMenuItem>
                        <DropdownMenuItem>
                        <TeamShareButton 
  assetId={stack._id}
  modelName="PromptStack" // or "Flow", "PromptStack", etc.
  currentTeams={stack.team} // Array of team IDs currently having access
  onShareSuccess={() => {
    // Optional callback for when sharing is successful
    // e.g., refetch the asset data
  }}
/>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </ScrollArea>
  
      <AlertDialog 
        open={!!stackToDelete} 
        onOpenChange={() => setStackToDelete(null)}
      >
        <AlertDialogContent className="sm:max-w-[425px]">
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Prompt Stack</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this prompt stack? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="gap-2 sm:gap-0">
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction 
              onClick={handleDelete}
              className="bg-destructive text-destructive-foreground"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default SavedPromptStacks;