// src/pages/SubscriptionPlans.js
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Card, CardContent } from '../components/ui/card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../components/ui/tabs';
import { Alert, AlertTitle, AlertDescription } from '../components/ui/alert';
import { useToast } from '../components/ui/use-toast';
import { Button } from '../components/ui/button';
import PlanCard from './SubscriptionComponents/PlanCard';
import PlanComparison from './SubscriptionComponents/PlanComparison';
import useSubscription from '../hooks/useSubscription';
import * as api from '../services/api';
import { Loader2, Mail } from 'lucide-react';
import FAQSection from '../components/FAQSection';

const stripePromise = loadStripe('pk_live_51QgtRaAc8dniylqxX9SHcE95h6VHPiByeG12czEsFhGzNlSYMtGZ51nP5eY3J6GfLtviyojG6bP9ugzoFltetLIj007FP6XnBY');

const SubscriptionPlans = () => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [plans, setPlans] = useState([]);
  const { currentTier, isActive } = useSubscription();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await api.getSubscriptionPlans();
        // Filter out enterprise plan and format unlimited values
        const formattedPlans = response.data.data
          .filter(plan => plan.tier !== 'enterprise')
          .map(plan => ({
            ...plan,
            activeFlows: plan.activeFlows === 999 ? 'Unlimited' : plan.activeFlows,
            flowSteps: plan.flowSteps === 999 ? 'Unlimited' : plan.flowSteps,
            chatCredits: plan.chatCredits === 999 ? 'Unlimited' : plan.chatCredits,
            executionCredits: plan.executionCredits === 999 ? 'Unlimited' : plan.executionCredits,
            imageCredits: plan.imageCredits === 999 ? 'Unlimited' : plan.imageCredits,
            customAssistants: plan.customAssistants === 999 ? 'Unlimited' : plan.customAssistants,
            seats: plan.seats === 999 ? 'Unlimited' : plan.seats,
          }));
        setPlans(formattedPlans);
      } catch (error) {
        setError(error.message || 'Failed to fetch subscription plans');
        toast({
          title: "Error",
          description: "Failed to load subscription plans",
          variant: "destructive"
        });
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, [toast]);

  const handleSelectPlan = async (tier, subscriptionType = 'individual') => {
    try {
      setLoading(true);
      
      if (tier === 'free') {
        toast({
          title: "Contact Support",
          description: "Please contact support to downgrade to the free plan.",
        });
        return;
      }
  
      const response = await api.createSubscriptionSession({
        tier,
        subscriptionType
      });
  
      if (!response.data?.sessionId) {
        throw new Error('No session ID returned from server');
      }
  
      const stripe = await stripePromise;
      
      const { error } = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId
      });
  
      if (error) {
        console.error('Stripe error:', error);
        throw new Error(error.message);
      }
      
    } catch (error) {
      console.error('Subscription error:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to process subscription",
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  };

  const getFeaturesList = (plan) => [
    `${plan.activeFlows} Active Flows`,
    `${plan.flowSteps} Steps per Flow`,
    `${plan.chatCredits} ${plan.tier !== 'free' ? 'Monthly' : ''} Chat Credits`,
    `${plan.executionCredits} ${plan.tier !== 'free' ? 'Monthly' : ''} Execution Credits`,
    `${plan.imageCredits} ${plan.tier !== 'free' ? 'Monthly' : ''} Image Credits`,
    `${plan.customAssistants} Custom Assistants`,
    `${plan.seats === 1 || plan.seats === 'Unlimited' ? '' : 'Up to '}${plan.seats} ${plan.seats === 1 || plan.seats === 'Unlimited' ? 'Seat' : 'Team Seats'}`,
    plan.isAdvancedTemplates ? 'Advanced Templates' : 'Basic Templates',
    `Unlimited Prompt Stacks`
];

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="container mx-auto py-2 space-y-2 px-0 lg:px-8 lg:py-8 lg:space-y-8">
      <div className="text-center space-y-4">
        <h1 className="text-4xl font-bold">Choose Your Plan</h1>
        <p className="text-xl text-muted-foreground">
          Select the perfect plan for your needs
        </p>
      </div>

      {isActive && (
        <Alert>
          <AlertTitle>Current Plan: <span className='capitalize'>{currentTier}</span></AlertTitle>
          <AlertDescription>
            You can upgrade your plan at any time. Downgrading requires contacting support.
          </AlertDescription>
        </Alert>
      )}

      <Tabs defaultValue="plans" className="w-full">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="plans">Subscription Plans</TabsTrigger>
          <TabsTrigger value="compare">Compare Features</TabsTrigger>
        </TabsList>
        
        <TabsContent value="plans">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {plans.map((plan) => (
              <PlanCard
                key={plan._id}
                tier={plan.tier}
                name={plan.name}
                price={plan.price_monthly}
                description={`Perfect for ${plan.tier} use`}
                features={getFeaturesList(plan)}
                isPopular={plan.tier === 'growth'}
                seats={plan.seats}
      chatCredits={plan.chatCredits}
      executionCredits={plan.executionCredits}
      imageCredits={plan.imageCredits}
      onSelectPlan={handleSelectPlan}
              />
            ))}
          </div>

          <div className="mt-8 text-center p-8 border rounded-lg bg-muted/50">
            <h3 className="text-2xl font-bold mb-4">Looking for Enterprise Solutions?</h3>
            <p className="text-muted-foreground mb-6">
              Get in touch with our sales team to discuss custom enterprise options tailored to your needs.
            </p>
            <Button 
              variant="default" 
              className="gap-2"
              onClick={() => window.location.href = 'mailto:sales@example.com'}
            >
              <Mail className="h-4 w-4" />
              Contact Sales Team
            </Button>
          </div>
        </TabsContent>
        
        <TabsContent value="compare">
          <Card>
            <CardContent className="p-3 lg:px-4 pt-6">
              <PlanComparison plans={plans} />
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>

      <FAQSection type="subscriptions" className="mt-8" />
    </div>
  );
};

export default SubscriptionPlans;