import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../services/api';
import { fetchUserTeams } from './teamsSlice';
import { fetchAppFeatures } from './appFeaturesSlice';

export const login = createAsyncThunk(
  'auth/login',
  async (credentials, thunkAPI) => {
    try {
      const response = await api.login(credentials);
      //console.log('response', response);
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      thunkAPI.dispatch(fetchUserTeams());
      return { user: response.data.user, accessToken: response.data.accessToken };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Invalid credentials');
    }
  }
);

export const register = createAsyncThunk(
  'auth/register',
  async (userData, thunkAPI) => {
    try {
      const response = await api.register(userData);
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      thunkAPI.dispatch(getCurrentUserSubscription(response.data.user.subscription?.subscriptionId || '675fa33b7bc23c00e46e8505'));
      thunkAPI.dispatch(fetchUserTeams());
      return { user: response.data.user, accessToken: response.data.accessToken };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  'auth/getCurrentUser',
  async (_, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      //console.log('getCurrentUser called');
      if (!accessToken) {
        return thunkAPI.rejectWithValue({ message: 'No authentication token' });
      }
      const response = await api.getCurrentUser();
      thunkAPI.dispatch(fetchUserTeams());
      return { user: response.data, accessToken };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { auth } = getState();
      if (auth.user && Date.now() - auth.lastFetchTime < 300000) {
        return false;
      }
      return true;
    },
  }
);

export const logout = createAsyncThunk('auth/logout', async () => {
  await api.logout();
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
});

export const refreshAuthToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { rejectWithValue }) => {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        throw new Error('No refresh token available');
      }
      const response = await api.refreshToken(refreshToken);
      //console.log('refreshAuthToken response', response);
      localStorage.setItem('accessToken', response.accessToken);
      localStorage.setItem('refreshToken', response.refreshToken);
      return { accessToken: response.accessToken };
    } catch (error) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      console.log('refreshAuthToken error', error);
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  }
);

export const getCurrentUserSubscription = createAsyncThunk(
  'auth/getCurrentUserSubscription',
  async (subscriptionId, { rejectWithValue }) => {
    try {
      const response = await api.getSubscriptionPlan(subscriptionId);
      //console.log('thunk getCurrentUserSubscription response', response.data.data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch subscription plan');
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    accessToken: localStorage.getItem('accessToken'),
    isAuthenticated: false,
    loading: false,
    error: null,
    lastFetchTime: null,
    currentSubscriptionPlan: null,
  subscriptionLoading: false,
  subscriptionError: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    clearAuth: (state) => {
      state.user = null;
      state.accessToken = null;
      state.isAuthenticated = false;
      state.loading = false;
      state.error = null;
      state.lastFetchTime = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    updateCredits: (state, action) => {
      if (state.user) {
        state.user[`${action.payload.type}Credits`] += action.payload.amount * (action.payload.subtract ? -1 : 1);
      }
     },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.accessToken = action.payload.accessToken;
        state.isAuthenticated = true;
        state.loading = false;
        state.error = null;
        state.lastFetchTime = Date.now();
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.accessToken = action.payload.accessToken;
        state.isAuthenticated = true;
        state.loading = false;
        state.error = null;
        state.lastFetchTime = Date.now();
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || 'Failed to fetch user data';
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.accessToken = null;
        state.isAuthenticated = false;
        state.loading = false;
        state.lastFetchTime = null;
      })
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.accessToken = action.payload.accessToken;
        state.isAuthenticated = true;
        state.loading = false;
        state.lastFetchTime = Date.now();
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(refreshAuthToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(refreshAuthToken.fulfilled, (state, action) => {
        state.accessToken = action.payload.accessToken;
        state.isAuthenticated = true;
        state.loading = false;
        state.error = null;
        state.lastFetchTime = Date.now();
      })
      .addCase(refreshAuthToken.rejected, (state, action) => {
        state.user = null;
        state.accessToken = null;
        state.isAuthenticated = false;
        state.loading = false;
        state.error = action.payload?.message || 'Token refresh failed';
        state.lastFetchTime = null;
      })
      .addCase(getCurrentUserSubscription.pending, (state) => {
        state.subscriptionLoading = true;
        state.subscriptionError = null;
      })
      .addCase(getCurrentUserSubscription.fulfilled, (state, action) => {
        state.currentSubscriptionPlan = action.payload;
        state.subscriptionLoading = false;
      })
      .addCase(getCurrentUserSubscription.rejected, (state, action) => {
        state.subscriptionLoading = false;
        state.subscriptionError = action.payload;
      });
  },
});

export const { clearAuth, setLoading, setError, setIsAuthenticated, clearError, setUser, updateCredits } = authSlice.actions;
export default authSlice.reducer;