import React, { useState } from 'react';
import { Button } from "../../components/ui/button";
import { Play, ChevronDown, HelpCircle } from "lucide-react";
import { useDispatch } from 'react-redux';
import { openVideoModal } from '../../slices/videoModalSlice';
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
  } from "../../components/ui/collapsible";

const VideoTutorialsAll = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);

  // Tutorial video data
  const tutorials = [
    {
      title: "How to Create A Flow From Scratch",
      url: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Explainer_Vids/How%20to%20Build%20a%20Flow%20form%20Scratch%20%20-%20Explainer%20Vid.mp4",
    },
    {
      title: "How to Manage Your Flows",
      url: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Explainer_Vids/How%20to%20Manage%20Your%20Flows%20-%20Explainer%20Vid.mp4",
    },
    {
      title: "How to Build a Pre-Made Flow",
      url: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Explainer_Vids/How%20to%20Build%20a%20Pre-Made%20Flow%20-%20Explainer%20Vid.mp4",
    },
    {
      title: "What is Flow Orchestra?",
      url: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Explainer_Vids/What%20is%20Flow%20Orchestra%20-%20Explainer%20Vid.mp4",
    },
    {
      title: "How to Build a Prompt Stack",
      url: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Explainer_Vids/How%20to%20Build%20a%20Prompt%20Stack%20%20-%20Explainer%20Vid.mp4",
    },
    {
      title: "How to Create a Custom Assistant",
      url: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Explainer_Vids/How%20to%20Build%20a%20Custom%20Assistant%20-%20Explainer%20Vid.mp4",
    },
    {
      title: "How to Build a JSON Flow",
      url: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Explainer_Vids/How%20to%20Build%20a%20JSON%20Flow%20-%20Explainer%20Vid.mp4",
    },
    {
      title: "How to Create a Schedule",
      url: "https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Explainer_Vids/How%20to%20Create%20a%20Schedule%20%20-%20Explainer%20Vid.mp4",
    }
  ];

  const handleOpenVideo = (video) => {
    dispatch(openVideoModal({
      url: video.url,
      title: video.title
    }));
  };

  return (
    <div className="w-full px-4 pt-1 pb-2 lg:pb-4 lg:pt-2 border rounded-lg bg-gray-300">
      {/* Desktop View */}
      <div className="hidden sm:block">
        <div className="flex items-center justify-between mb-10">
        <h2 className="text-xl font-semibold mb-1">Video Tutorials</h2>
        <div className="text-md text-black mb-2 flex items-center"><HelpCircle className='mr-1 h-4 w-4' />Not sure how to get started? Watch these short explainer videos to learn more.</div>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-3 gap-6">
          {tutorials.map((tutorial, index) => (
            <Button
              key={index}
              variant="outline"
              size="sm"
              className="text-gray-600 hover:text-green-500 bg-white hover:bg-white transition-colors duration-200 w-full"
              onClick={() => handleOpenVideo(tutorial)}
            >
              <div className="flex items-center justify-start w-full">
                <Play className="h-4 w-4 mr-2 flex-shrink-0 play-icon" />
                <span className="truncate">{tutorial.title}</span>
              </div>
            </Button>
          ))}
        </div>
      </div>

      {/* Mobile View with Collapsible */}
      <div className="sm:hidden">
        <Collapsible
          open={isOpen}
          onOpenChange={setIsOpen}
          className="w-full space-y-2"
        >
          <div className="flex items-center justify-between space-x-4 px-1">
            <h2 className="text-xl font-semibold">Video Tutorials</h2>
            <CollapsibleTrigger asChild>
              <Button
                variant="ghost"
                size="sm"
                className="w-9 p-0"
              >
                <ChevronDown className={`h-4 w-4 transition-transform duration-200 ${
                  isOpen ? "transform rotate-180" : ""
                }`}/>
                <span className="sr-only">Toggle video tutorials</span>
              </Button>
            </CollapsibleTrigger>
          </div>
          
          <CollapsibleContent className="space-y-2">
            {tutorials.map((tutorial, index) => (
              <Button
                key={index}
                variant="outline"
                size="sm"
                className="text-gray-600 hover:text-gray-800 transition-colors duration-200 w-full bg-gradient-to-r from-indigo-50 to-indigo-300"
                onClick={() => handleOpenVideo(tutorial)}
              >
                <div className="flex items-center justify-start w-full">
                  <Play className="h-4 w-4 mr-2 flex-shrink-0" />
                  <span className="truncate">{tutorial.title}</span>
                </div>
              </Button>
            ))}
          </CollapsibleContent>
        </Collapsible>
      </div>
    </div>
  );
};

export default VideoTutorialsAll;