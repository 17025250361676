import React from 'react';
import { Folder, Tag, Hash, Lock } from 'lucide-react';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../../components/ui/sheet";
import { Button } from "../../../components/ui/button";
import { Checkbox } from "../../../components/ui/checkbox";
import { ScrollArea } from "../../../components/ui/scroll-area";

const FilterSidebar = ({
  categories,
  selectedCategories,
  tags,
  selectedTags,
  onCategoryChange,
  onTagChange,
  isMobile,
  selectedStepRanges,
  onStepRangeChange,
  limits,
  showAvailableOnly,
  onAvailabilityChange,
}) => {
  const FilterContent = () => (
    <div className="space-y-6 z-[70] lg:mt-4">
      {/* Available Flows Filter */}
    <div>
      <h3 className="font-semibold mb-3 flex items-center">
        <Lock className="w-4 h-4 mr-2" /> {/* Add Lock to imports */}
        Availability
      </h3>
      <div className="space-y-2">
      <div className="flex items-center space-x-2">
  <Checkbox
    id="show-available"
    checked={showAvailableOnly}
    onCheckedChange={onAvailabilityChange}
  />
  <label htmlFor="show-available" className="text-sm flex items-center justify-between flex-1">
    <span>Show available flows only</span>
    {/*<span className="text-xs text-muted-foreground">
      ({limits.flowSteps} steps max)
    </span> */}
  </label>
</div>
      </div>
    </div>
      {/* Categories Section */}
      <div>
        <h3 className="font-semibold mb-3 flex items-center">
          <Folder className="w-4 h-4 mr-2" />
          Categories
        </h3>
        <div className="space-y-2">
          {categories.map(category => (
            <div key={category} className="flex items-center space-x-2">
              <Checkbox
                id={`category-${category}`}
                checked={selectedCategories.includes(category)}
                onCheckedChange={() => onCategoryChange(category)}
              />
              <label htmlFor={`category-${category}`} className="text-sm">
                {category}
              </label>
            </div>
          ))}
        </div>
      </div>

      

      {/* Steps Range Section */}
      <div>
        <h3 className="font-semibold mb-3 flex items-center">
          <Hash className="w-4 h-4 mr-2" />
          Flow Size
        </h3>
        <div className="space-y-2">
        {['Quick (1-5 steps)', 'Medium (6-14 steps)', 'Detailed (15+ steps)'].map(range => (
  <div key={range} className="flex items-center space-x-2">
    <Checkbox
      id={`range-${range}`}
      checked={selectedStepRanges.includes(range)}   // Add this line
      onCheckedChange={() => onStepRangeChange(range)} // Add this line
    />
    <label htmlFor={`range-${range}`} className="text-sm">
      {range}
    </label>
  </div>
))}
        </div>
      </div>

      {/* Tags Section */}
      <div>
        <h3 className="font-semibold mb-3 flex items-center">
          <Tag className="w-4 h-4 mr-2" />
          Popular Tags
        </h3>
        <div className="space-y-2">
          {tags.map(tag => (
            <div key={tag} className="flex items-center space-x-2">
              <Checkbox
                id={`tag-${tag}`}
                checked={selectedTags.includes(tag)}
                onCheckedChange={() => onTagChange(tag)}
              />
              <label htmlFor={`tag-${tag}`} className="text-sm">
                {tag}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  if (isMobile) {
    return (
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="sm" className="mb-4">
            Filters
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="w-[300px] z-[100]">
          <SheetHeader>
            <SheetTitle>Filters</SheetTitle>
          </SheetHeader>
          <ScrollArea className="h-[calc(100vh-80px)] px-1">
            <FilterContent />
          </ScrollArea>
        </SheetContent>
      </Sheet>
    );
  }

  return (
    <div className="w-[250px] border-r pr-4 hidden md:block">
      <ScrollArea className="h-[calc(100vh-200px)] lg:h-[calc(100vh-80px)]">
        <FilterContent />
      </ScrollArea>
    </div>
  );
};

export default FilterSidebar;