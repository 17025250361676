import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsPlaying,
  setVolume,
  setDuration,
  setCurrentTime,
  setCurrentTrack,
    setPlaylistIndex,
} from "../slices/audioSlice";
import { Slider } from "./ui/slider";
import { Button } from "./ui/button";
import { Card } from "./ui/card";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from './ui/sheet';
import QueueManager from './AudioComponents/QueueManager';
import PlaylistManager from './AudioComponents/PlaylistManager';
import {
  Volume2,
  VolumeX,
  Play,
  Pause,
  SkipBack,
  SkipForward,
  Music,
  ChevronDown,
  KeyboardMusic,
  Piano,
  X,
  ListMusic,
} from "lucide-react";

const AudioPlayer = () => {
  const dispatch = useDispatch();
  const audioRef = useRef(null);
  const { currentTrack, isPlaying, volume, queue, currentTime, duration, currentPlaylist, currentPlaylistIndex } =
    useSelector((state) => state.audio);

  const [isMuted, setIsMuted] = useState(false);
  const [prevVolume, setPrevVolume] = useState(1);
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    const handleTrackEnd = () => {
      if (currentPlaylist.length > 0) {
        const nextIndex = (currentPlaylistIndex + 1) % currentPlaylist.length;
        dispatch(setCurrentTrack(currentPlaylist[nextIndex]));
        dispatch(setPlaylistIndex(nextIndex));
        dispatch(setIsPlaying(true));
      }
    };
  
    if (audioRef.current) {
      audioRef.current.addEventListener('ended', handleTrackEnd);
      return () => audioRef.current?.removeEventListener('ended', handleTrackEnd);
    }
  }, [currentPlaylist, currentPlaylistIndex, dispatch]);
  
  useEffect(() => {
    if (currentTrack?.url) {
      audioRef.current.src = currentTrack.url;
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [currentTrack, isPlaying]);

  const handleTimeUpdate = () => {
    dispatch(setCurrentTime(audioRef.current.currentTime));
  };

  const handleLoadedMetadata = () => {
    dispatch(setDuration(audioRef.current.duration));
  };

  const handlePlayPause = () => {
    dispatch(setIsPlaying(!isPlaying));
  };

  const handleVolumeChange = (value) => {
    const newVolume = value[0];
    audioRef.current.volume = newVolume;
    dispatch(setVolume(newVolume));
  };

  const handleTimeSeek = (value) => {
    const newTime = value[0];
    audioRef.current.currentTime = newTime;
    dispatch(setCurrentTime(newTime));
  };

  const toggleMute = () => {
    if (isMuted) {
      audioRef.current.volume = prevVolume;
      dispatch(setVolume(prevVolume));
    } else {
      setPrevVolume(volume);
      audioRef.current.volume = 0;
      dispatch(setVolume(0));
    }
    setIsMuted(!isMuted);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const hasQueue = queue.length > 0;

  if (!currentTrack) return null;

  return (
    <>
      <Card
        className={`fixed bottom-0 left-0 right-0 p-4 bg-gray-300 border-t z-[70] transition-transform duration-300 ${
          isMinimized ? "translate-y-full" : "translate-y-0"
        }`}
      >
        <div className="max-w-8xl mx-auto">
          <div className="flex items-center justify-between gap-4">
            {/* Track Info */}
            <div className="flex items-center gap-3 min-w-0 w-1/4">
              <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center flex-shrink-0">
                {currentTrack.coverUrl ? (
                  <img
                    src={currentTrack.coverUrl}
                    alt={currentTrack.title}
                    className="w-full h-full object-cover rounded-lg"
                  />
                ) : (
                  <Music className="w-6 h-6 text-primary" />
                )}
              </div>
              <div className="min-w-0">
                <div className="font-medium truncate">{currentTrack.title}</div>
                <div className="text-sm text-muted-foreground truncate">
                  {currentTrack.artist}
                </div>
              </div>
            </div>

            {/* Main Controls */}
            <div className="flex flex-col items-center flex-1">
              <div className="flex items-center gap-2">
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8"
                  disabled={!queue.length}
                >
                  <SkipBack className="h-4 w-4" />
                </Button>
                <Button
                  onClick={handlePlayPause}
                  variant="outline"
                  size="icon"
                  className="h-10 w-10"
                >
                  {isPlaying ? (
                    <Pause className="h-5 w-5" />
                  ) : (
                    <Play className="h-5 w-5" />
                  )}
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8"
                  disabled={!queue.length}
                >
                  <SkipForward className="h-4 w-4" />
                </Button>
              </div>
              <div className="flex items-center gap-2 w-full max-w-2xl mt-2">
                <span className="text-sm w-12 text-right">
                  {formatTime(currentTime)}
                </span>
                <Slider
                  value={[currentTime]}
                  max={duration}
                  step={1}
                  className="w-full"
                  onValueChange={handleTimeSeek}
                />
                <span className="text-sm w-12">{formatTime(duration)}</span>
              </div>
            </div>

            {/* Volume Control */}
            <div className="flex items-center gap-2 w-1/4 justify-end">
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8"
                onClick={toggleMute}
              >
                {isMuted || volume === 0 ? (
                  <VolumeX className="h-4 w-4" />
                ) : (
                  <Volume2 className="h-4 w-4" />
                )}
              </Button>
              <Slider
                value={[volume]}
                max={1}
                step={0.01}
                className="w-32"
                onValueChange={handleVolumeChange}
              />
            </div>
            <div className="ml-4">
  <Sheet>
    <SheetTrigger asChild>
      <Button 
        variant="ghost" 
        size="icon" 
        className="h-8 w-8 relative"
        data-count={currentPlaylist.length || undefined}
      >
        <ListMusic className="h-4 w-4" />
      </Button>
    </SheetTrigger>
    <SheetContent>
      <SheetHeader>
        <SheetTitle>Current Playlist</SheetTitle>
      </SheetHeader>
      <div className="mt-4">
        <PlaylistManager />
      </div>
    </SheetContent>
  </Sheet>
</div>
            <div className="ml-4">
  <Sheet>
    <SheetTrigger asChild>
      <Button 
        variant="ghost" 
        size="icon" 
        className="h-8 w-8"
        data-count={hasQueue ? queue.length : undefined}
      >
        <ListMusic className="h-6 w-6" />
      </Button>
    </SheetTrigger>
    <SheetContent className="bg-gray-200 z-[70]">
      <SheetHeader>
        <SheetTitle className="flex items-center"><ListMusic className="h-4 w-4 mr-1" />Flow Audio Queue</SheetTitle>
      </SheetHeader>
      <div className="mt-4">
        <QueueManager />
      </div>
    </SheetContent>
  </Sheet>
</div>
            {/*<div onClick={() => setIsMinimized(!isMinimized)}>
              <ChevronDown className="h-6 w-6 text-muted-foreground cursor-pointer" />
            </div>*/}
          </div>
          <div className="flex right-4 absolute top-2 gap-4">
            <div
              onClick={() => setIsMinimized(!isMinimized)}
              className="flex items-center text-muted-foreground hover:text-black cursor-pointer"
            >
              Minimize
              <ChevronDown className="h-6 w-6 ml-1" />
            </div>
            <div
              onClick={() => dispatch(setCurrentTrack(null))}
              className="flex items-center text-muted-foreground hover:text-black cursor-pointer"
            >
              Stop & Close
              <X className="h-6 w-6 ml-1" />
            </div>
          </div>
        </div>

        <audio
          ref={audioRef}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
          onEnded={() => dispatch(setIsPlaying(false))}
        />
      </Card>
      {isMinimized && (
        <div
          onClick={() => setIsMinimized(false)}
          className="fixed bottom-4 right-4 p-2 bg-primary text-white rounded-full shadow-lg cursor-pointer"
        >
          <Piano className="h-6 w-6" />
        </div>
      )}
    </>
  );
};

export default AudioPlayer;
