import React, { useState } from 'react';
import { Card, CardHeader, CardContent, CardFooter } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Badge } from '../../components/ui/badge';
import { Check, Info } from 'lucide-react';
import useSubscription from '../../hooks/useSubscription';
import { TIER_LEVELS } from '../../utils/subscriptionUtils';
import { useToast } from '../../components/ui/use-toast';
import SubscriptionTypeModal from './SubscriptionTypeModal';

const PlanCard = ({ 
  name, 
  price, 
  description, 
  features, 
  tier,
  seats,
  chatCredits,
  executionCredits,
  imageCredits,
  isPopular,
  onSelectPlan 
}) => {
  const { toast } = useToast();
  const { currentTier, isActive } = useSubscription();
  const [showTypeModal, setShowTypeModal] = useState(false);
  
  const isCurrentPlan = currentTier === tier;
  const isDowngrade = TIER_LEVELS[tier] < TIER_LEVELS[currentTier];
  const hasMultipleSeats = seats > 1;
  
  const handleSelectPlan = () => {
    if (isDowngrade) {
      toast({
        title: "Please Contact Support",
        description: "Please contact support to downgrade your plan.",
        variant: "destructive"
      });
      return;
    }
    
    // Debug log to verify seats value
    console.log('Plan seats:', seats);
    
    if (seats > 1) {
      setShowTypeModal(true);
    } else {
      onSelectPlan(tier);
    }
  };
  
  const handleTypeSelection = (subscriptionType) => {
    // Pass both tier and subscriptionType to parent
    console.log('Selected subscription type:', subscriptionType, 'for tier:', tier);
    onSelectPlan(tier, subscriptionType);
  };

  return (
    <>
      <Card className={`w-full relative ${isPopular ? 'border-2 border-green-600 shadow-lg' : ''}`}>
        {isPopular && (
          <Badge 
            className="absolute -top-2 right-4 bg-green-500 text-white" 
            variant="default"
          >
            Most Popular
          </Badge>
        )}
        
        <CardHeader>
          <div className="space-y-1">
            <h3 className="font-bold text-2xl">{name}</h3>
            <p className="text-sm text-muted-foreground">{description}</p>
          </div>
          <div className="mt-4">
            <span className="text-4xl font-bold">${price}</span>
            <span className="text-muted-foreground">/month</span>
          </div>
        </CardHeader>
        
        <CardContent>
          <ul className="space-y-3">
            {features.map((feature, index) => (
              <li key={index} className="flex items-center space-x-2">
                <Check className="h-4 w-4 text-green-600" />
                <span className="text-sm">{feature}</span>
              </li>
            ))}
          </ul>
        </CardContent>
        
        <CardFooter>
          <Button 
            className="w-full hover:bg-green-500 hover:text-white" 
            variant={isPopular ? "default" : "outline"}
            disabled={isCurrentPlan}
            onClick={handleSelectPlan}
          >
            {isCurrentPlan ? 'Current Plan' : 'Select Plan'}
          </Button>
        </CardFooter>
      </Card>

      <SubscriptionTypeModal
        isOpen={showTypeModal}
        onClose={() => setShowTypeModal(false)}
        onSelect={handleTypeSelection}
        planDetails={{
          name,
          seats,
          chatCredits,
          executionCredits,
          imageCredits
        }}
      />
    </>
  );
};

export default PlanCard;