import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { login, register } from "../slices/authSlice";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import {
  joinWaitlist,
  getListCount,
  checkEmailWhitelist,
} from "../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebook,
  faLinkedin,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import NavigationDots from "./LoginComponents/NavigationDots";
import PricingSection from "./LoginComponents/PricingSection";
import FAQSection from "./LoginComponents/FAQSection";
import FloatingQuote from "./LoginComponents/FloatingQuote";
import ConnectSection from "./LoginComponents/ConnectSection";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Key, ShieldX } from "lucide-react";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [waitlistEmail, setWaitlistEmail] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [isWaitlistConfirmed, setIsWaitlistConfirmed] = useState(false);
  const [error, setError] = useState("");
  const [waitlistCount, setWaitlistCount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isRegistering, setIsRegistering] = useState(false);
  const [registrationData, setRegistrationData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [isCheckingWhitelist, setIsCheckingWhitelist] = useState(false);
  const [isWhitelisted, setIsWhitelisted] = useState(null);

  useEffect(() => {
    const fetchWaitlistCount = async () => {
      try {
        const response = await getListCount("67638c2345c78942fa2c14ad");
        setWaitlistCount(response.data);
      } catch (error) {
        console.error("Error fetching waitlist count:", error);
      }
    };

    fetchWaitlistCount();
  }, []);

  useEffect(() => {
    if (isWaitlistConfirmed) {
      const videoOverlay = document.getElementById("waitlist-video-wrapper");

      if (videoOverlay) {
        // Set up fade-in effect
        videoOverlay.style.opacity = "0";
        videoOverlay.style.transition = "opacity 1s ease-in";

        // Timeout for fade-in to start immediately
        setTimeout(() => {
          videoOverlay.style.opacity = "1";
        }, 50); // Small delay to trigger CSS transition
      }
    }
  }, [isWaitlistConfirmed]);

  useEffect(() => {
    if (isWaitlistConfirmed) {
      const timer = setTimeout(() => {
        // Fade out video and fade in confirmation box
        const videoOverlay = document.getElementById("waitlist-video-wrapper");
        const confirmationBox = document.getElementById(
          "waitlist-confirmation-box"
        );

        if (videoOverlay) {
          videoOverlay.style.transition = "opacity 1s ease-out";
          videoOverlay.style.opacity = "0";
        }

        if (confirmationBox) {
          confirmationBox.style.transition = "opacity 1s ease-in";
          confirmationBox.style.opacity = "1";
        }
      }, 8500); // 10 seconds

      return () => clearTimeout(timer);
    }
  }, [isWaitlistConfirmed]);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await dispatch(login({ email, password })).unwrap();
      navigate("/dashboard");
    } catch (err) {
      // Instead of letting the error propagate up and crash the component,
      // we'll handle it gracefully
      alert("Invalid email or password");
      setError("Invalid email or password");
      setPassword(""); // Clear password on failed attempt
      //return; // Prevent the component from trying to navigate
    }
  };

  const debouncedCheckWhitelist = useCallback(
    debounce(async (email) => {
      if (!email || email.length < 5 || !email.includes("@")) return; // Basic email validation

      try {
        setIsCheckingWhitelist(true);
        const response = await checkEmailWhitelist(email);
        setIsWhitelisted(response.data.isWhitelisted);
      } catch (error) {
        console.error("Error checking whitelist:", error);
        setIsWhitelisted(false);
      } finally {
        setIsCheckingWhitelist(false);
      }
    }, 500), // Wait 500ms after user stops typing
    []
  );

  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const checkWhitelist = async (email) => {
    try {
      setIsCheckingWhitelist(true);
      const response = await checkEmailWhitelist(email);
      setIsWhitelisted(response.data.isWhitelisted);
    } catch (error) {
      console.error("Error checking whitelist:", error);
      setIsWhitelisted(false);
    } finally {
      setIsCheckingWhitelist(false);
    }
  };

  // Add registration handler
  const handleRegister = async (e) => {
    e.preventDefault();
    setError("");

    if (registrationData.password !== registrationData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await dispatch(register(registrationData)).unwrap();
      navigate("/dashboard");
    } catch (err) {
      setError(err.message || "Registration failed");
    }
  };

  const handleWaitlistSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await joinWaitlist(waitlistEmail, "67638c2345c78942fa2c14ad");
      setIsWaitlistConfirmed(true);
      setWaitlistCount((prevCount) => prevCount + 1);
    } catch (err) {
      setError(err.message || "An error occurred while joining the waitlist");
    }
  };

  const handleVideoClick = () => {
    const waitlistForm = document.getElementById("waitlist-form");
    if (waitlistForm) {
      let blinkCount = 0;
      const interval = setInterval(() => {
        if (blinkCount < 4) {
          waitlistForm.classList.toggle("border-4");
          waitlistForm.classList.toggle("border-cyan-500");
          blinkCount++;
        } else {
          clearInterval(interval);
        }
      }, 200);
    }
  };

  const sections = ["home", "pricing", "faq", "connect"];

  return (
    <div className="relative">
      <div className="hidden lg:block">
        <NavigationDots sections={sections} />
      </div>

      {/* Main Content */}
      <div className="overflow-y-auto scroll-smooth h-screen">
        {/* Login Section */}
        <section data-section-index="0" className="min-h-screen relative">
          <div className="flex flex-col lg:flex-row min-h-screen w-full">
            {/* Left side - Dark section */}
            <div className="flex flex-col lg:w-1/2 bg-black text-white justify-between relative h-80 md:h-screen">
              <div className="absolute inset-0 bg-black p-4 pt-12 lg:p-0">
                <video
                  className="w-full h-full object-contain"
                  src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/FO%20Main%20Page%20Vid%202.mp4"
                  autoPlay
                  loop
                  muted
                  playsInline
                  onClick={handleVideoClick} // Handles click on video
                />
              </div>

              <div className="p-4 lg:p-12 relative z-10">
                <div className="flex items-center space-x-2">
                  <img
                    src="/FlowOrchestraIcon5.png"
                    alt="Flow Orchestra Icon"
                    className="w-8 h-8 bg-white rounded-md"
                  />
                  <span className="text-xl font-semibold">Flow Orchestra</span>
                </div>
              </div>
              <FloatingQuote
                quote="The whole is greater than the sum of its parts."
                author="Aristotle"
                className="hidden lg:block text-white"
              />
            </div>

            {/* Right side - Form container */}
            <div className="flex-1 flex flex-col justify-between px-4 py-4 lg:py-0 sm:px-6 lg:px-20 xl:px-24">
              <div className="flex-1 flex flex-col justify-center">
                <img
                  src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/site/triangles%20down%201%20on%20white%20bg.jpg"
                  alt="Flow Orchestra - AI and Automation"
                  className="lg:w-1/2 hidden lg:block"
                />
                <img
                  src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/site/triangles%20down%201%20on%20white%20bg.jpg"
                  alt="Flow Orchestra - AI and Automation"
                  className="lg:w-1/2"
                />

                {/* Waitlist Form or Login Form with Fade Effect */}
                <div
                  className={`w-full max-w-sm transition-opacity duration-1000 ${
                    isWaitlistConfirmed ? "opacity-0 h-0" : "opacity-100"
                  }`}
                >
                  
                  {error && (
                            <div className="text-sm text-red-500 bg-red-50 border border-red-200 rounded-md p-3">
                              {error}
                            </div>
                          )}
                  {/* <div className="flex justify-between items-center">
                    <h2 className="text-3xl font-bold text-inter">
                      {isLogin ? "Sign in" : "Join the Waitlist"}
                    </h2>
                    <button
                      onClick={() => setIsLogin(!isLogin)}
                      className="text-sm text-gray-600 hover:text-gray-900"
                    >
                      {isLogin ? "Join the Waitlist" : "Sign in"}
                    </button>
                  </div> */}
                  {isLogin ? (
                    <>
                      <div className="flex justify-between items-center">
                        <h2 className="text-3xl font-bold text-inter">
                          Sign in
                        </h2>
                        <div>
                          <button
                            onClick={() => {
                              setIsLogin(false);
                              setIsRegistering(true);
                            }}
                            className="text-sm text-gray-600 hover:text-gray-900 mr-4"
                          >
                            Register
                          </button>
                          <button
                            onClick={() => {
                              setIsLogin(false);
                              setIsRegistering(false);
                            }}
                            className="text-sm text-gray-600 hover:text-gray-900"
                          >
                            Join Waitlist
                          </button>
                        </div>
                      </div>
                      <p className="mt-2 text-sm text-gray-600">
                        Enter your credentials below to access your account
                      </p>
                      <form
                        onSubmit={handleLoginSubmit}
                        className="mt-8 space-y-6"
                      >
                        <div className="space-y-4">
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email address"
                            required
                            className="w-full"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <Input
                            id="password"
                            name="password"
                            type="password"
                            placeholder="Password"
                            required
                            className="w-full"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <Button
                            type="submit"
                            className="w-full bg-black text-white hover:bg-gray-800"
                          >
                            Sign In
                          </Button>
                        </div>
                      </form>
                    </>
                  ) : isRegistering ? (
                    <>
                      <div className="flex justify-between items-center">
                        <h2 className="text-3xl font-bold text-inter">
                          Register
                        </h2>
                        <div>
                          <button
                            onClick={() => {
                              setIsLogin(true);
                              setIsRegistering(false);
                            }}
                            className="text-sm text-gray-600 hover:text-gray-900 mr-4"
                          >
                            Sign in
                          </button>
                          <button
                            onClick={() => {
                              setIsLogin(false);
                              setIsRegistering(false);
                            }}
                            className="text-sm text-gray-600 hover:text-gray-900"
                          >
                            Join Waitlist
                          </button>
                        </div>
                      </div>
                      <form
                        onSubmit={handleRegister}
                        className="mt-8 space-y-6"
                      >
                        <div className="space-y-4">
                          <div className="grid grid-cols-2 gap-4">
                            <Input
                              type="text"
                              placeholder="First Name"
                              value={registrationData.firstName}
                              onChange={(e) =>
                                setRegistrationData((prev) => ({
                                  ...prev,
                                  firstName: e.target.value,
                                }))
                              }
                              required
                            />
                            <Input
                              type="text"
                              placeholder="Last Name"
                              value={registrationData.lastName}
                              onChange={(e) =>
                                setRegistrationData((prev) => ({
                                  ...prev,
                                  lastName: e.target.value,
                                }))
                              }
                              required
                            />
                          </div>
                          {isCheckingWhitelist && (
                            <div className="text-sm text-gray-500">
                              <Key className="w-4 h-4 inline-block" /> Checking
                              registration access...
                            </div>
                          )}
                          {!isCheckingWhitelist && isWhitelisted === false && (
                            <div className="text-sm text-red-500">
                              <ShieldX className="w-4 h-4 inline-block" />{" "}
                              Registration is currently by invitation only
                            </div>
                          )}
                          <Input
                            type="email"
                            placeholder="Email address"
                            value={registrationData.email}
                            onChange={(e) => {
                              const email = e.target.value;
                              setRegistrationData((prev) => ({
                                ...prev,
                                email,
                              }));
                              debouncedCheckWhitelist(email);
                            }}
                            required
                          />
                          {registrationData.password && registrationData.password.length < 8 && (
                              <div className="text-sm text-red-500">
                                Passwords must contain at least 8 characters
                              </div>
                            )}
                          <Input
                            type="password"
                            placeholder="Password"
                            value={registrationData.password}
                            onChange={(e) =>
                              setRegistrationData((prev) => ({
                                ...prev,
                                password: e.target.value,
                              }))
                            }
                            required
                            className={registrationData.password && registrationData.password.length < 8 ? "border-red-500 focus:border-red-500" : ""}
                          />
                          <Input
                            type="password"
                            placeholder="Confirm Password"
                            value={registrationData.confirmPassword}
                            onChange={(e) =>
                              setRegistrationData((prev) => ({
                                ...prev,
                                confirmPassword: e.target.value,
                              }))
                            }
                            required
                          />
                          {registrationData.password &&
                            registrationData.confirmPassword &&
                            registrationData.password !==
                              registrationData.confirmPassword && (
                              <div className="text-sm text-red-500">
                                Passwords do not match
                              </div>
                            )}
                          <Button
                            type="submit"
                            className="w-full bg-black text-white hover:bg-gray-800"
                            disabled={
                              !isWhitelisted ||
                              !registrationData.password ||
                              registrationData.password.length < 8 ||
                              !registrationData.confirmPassword ||
                              registrationData.password !== registrationData.confirmPassword
                            }
                          >
                            Register
                          </Button>
                        </div>
                      </form>
                    </>
                  ) : (
                    <>
                      <div className="flex justify-between items-center">
                        <h2 className="text-3xl font-bold text-inter">
                          Join the Waitlist
                        </h2>
                        <div>
                          <button
                            onClick={() => setIsLogin(true)}
                            className="text-sm text-gray-600 hover:text-gray-900 mr-4"
                          >
                            Sign in
                          </button>
                          <button
                            onClick={() => setIsRegistering(true)}
                            className="text-sm text-gray-600 hover:text-gray-900"
                          >
                            Register
                          </button>
                        </div>
                      </div>
                      <form
  id="waitlist-form"
  onSubmit={handleWaitlistSubmit}
  className="mt-8 space-y-6"
>
  <div className="space-y-4">
    <Input
      id="waitlist-email"
      name="waitlist-email"
      type="email"
      placeholder="Email address"
      required
      className="w-full"
      value={waitlistEmail}
      onChange={(e) => setWaitlistEmail(e.target.value)}
    />
    <Button
      type="submit"
      className="w-full bg-black text-white hover:bg-gray-800"
    >
      Join The Waitlist
    </Button>
  </div>
</form>
                    </>
                  )}
                  {isLogin && (
                    <p className="mt-6 text-center text-sm text-gray-500">
                      By signing in, you agree to our{" "}
                      <a
                        href="/terms-of-service"
                        className="font-medium text-gray-900 hover:underline"
                      >
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a
                        href="/privacy-policy"
                        className="font-medium text-gray-900 hover:underline"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                  )}
                </div>

                {/* Confirmation Message after Successful Waitlist Signup */}
                {isWaitlistConfirmed && (
                  <>
                    <div
                      id="waitlist-video-wrapper"
                      className="absolute inset-0 z-20 flex items-center justify-center bg-white"
                    >
                      <video
                        id="waitlist-video"
                        className="w-full max-w-screen-md object-contain"
                        src="https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/FO%20Main%20Page%20Waitlist%20Joined%20Vid%201.mp4"
                        autoPlay
                        muted
                        playsInline
                      />
                    </div>
                    <div
                      id="waitlist-confirmation-box"
                      className="w-full max-w-sm mt-0 p-6 bg-cyan-100 rounded-lg shadow-lg transition-opacity duration-1000 opacity-0 relative z-30"
                    >
                      <h3 className="text-2xl font-bold text-black text-center">
                        Welcome to the Waitlist!
                      </h3>
                      <p className="mt-4 text-center text-black">
                        You've successfully joined the waitlist! 🎉
                        <br />
                        Keep an eye on your emails for exciting updates coming
                        soon.
                        <br />
                        Meanwhile, follow us on social media to stay in the
                        loop!
                      </p>
                      <div className="flex justify-center mt-6 space-x-4">
                        <a
                          href="https://www.facebook.com/orchestraflow"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={faFacebook}
                            className="text-2xl text-blue-600 hover:text-blue-700 transition-colors duration-300"
                          />
                        </a>
                        <a
                          href="https://www.youtube.com/@Flow-Orchestra"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={faYoutube}
                            className="text-2xl text-blue-700 hover:text-blue-800 transition-colors duration-300"
                          />
                        </a>
                        <a
                          href="https://www.linkedin.com/company/flow-orchestra/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={faLinkedin}
                            className="text-2xl text-blue-700 hover:text-blue-800 transition-colors duration-300"
                          />
                        </a>
                        <a
                          href="https://www.instagram.com/_floworchestra/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={faInstagram}
                            className="text-2xl text-blue-700 hover:text-blue-800 transition-colors duration-300"
                          />
                        </a>
                        <a
                          href="https://x.com/floworchestra"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={faTwitter}
                            className="text-2xl text-blue-400 hover:text-blue-500 transition-colors duration-300"
                          />
                        </a>
                      </div>
                    </div>
                  </>
                )}
                <div className="my-4 text-sm text-gray-500">
                  <p>Members waiting: {waitlistCount}</p>
                  <div className="w-full lg:w-1/2 bg-gray-200 border border-gray-300 rounded-md overflow-hidden mt-2">
                    <div
                      className="bg-green-500 h-5 text-white text-center text-sm leading-5"
                      style={{
                        width: `${Math.min(
                          (waitlistCount / 1000) * 100,
                          100
                        )}%`,
                      }}
                    >
                      Waitlist capacity:{" "}
                      {Math.round((waitlistCount / 1000) * 100)}%
                    </div>
                  </div>
                </div>
              </div>

              <FloatingQuote
                quote="Orchestrate. Automate. Innovate."
                author="Flow Orchestra"
                className="text-black text-right pr-4 lg:pr-12 pl-12 lg:pl-0"
              />
              <div className="lg:hidden flex flex-col items-center justify-center mt-0 space-x-2">
                More
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="scroll-chevron-glow w-6 h-6 text-primary hover:text-primary/80 transition-colors"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section data-section-index="1" className="min-h-screen relative pb-20">
          <PricingSection />
        </section>

        {/* FAQ Section */}
        <section data-section-index="2" className="min-h-screen relative">
          <FAQSection />
          <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 mt-12">
            <Button
              variant="outline"
              onClick={() => {
                const firstSection = document.querySelector(
                  '[data-section-index="0"]'
                );
                if (firstSection) {
                  firstSection.scrollIntoView({ behavior: "smooth" });
                }
              }}
              className="flex items-center gap-2 border-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-4 h-4"
              >
                <path d="m18 15-6-6-6 6" />
              </svg>
              Back to Top
            </Button>
          </div>
        </section>

        {/* Connect Section */}
        <section
          data-section-index="3"
          className="min-h-screen relative pb-12 lg:pb-0"
        >
          <ConnectSection />
          {/* Back to Top Button and Copyright can go here */}
          <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 mt-12">
            <Button
              variant="outline"
              onClick={() => {
                const firstSection = document.querySelector(
                  '[data-section-index="0"]'
                );
                if (firstSection) {
                  firstSection.scrollIntoView({ behavior: "smooth" });
                }
              }}
              className="flex items-center gap-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-4 h-4"
              >
                <path d="m18 15-6-6-6 6" />
              </svg>
              Back to Top
            </Button>
          </div>
        </section>
      </div>
      <div className="text-sm text-gray-500 text-center mb-2">
        © {new Date().getFullYear()} Flow Orchestra. All rights reserved.
      </div>
    </div>
  );
};

export default LoginPage;
