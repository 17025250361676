import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../../components/ui/card";
import { ScrollArea } from "../../components/ui/scroll-area";
import { Badge } from "../../components/ui/badge";
import {
  Users,
  Crown,
  User,
  Shield,
  Gem,
  MessageCircle,
  MessagesSquare,
  MessageSquare,
  Play,
  Image,
} from "lucide-react";
import { formatDistanceToNow } from "date-fns";

const TeamsCard = ({ teams, selectedTeam }) => {
  const formatJoinDate = (team, userId) => {
    try {
      const member = team.members.find((m) => m.user === userId);
      if (member?.joinedAt) {
        return formatDistanceToNow(new Date(member.joinedAt), {
          addSuffix: true,
        });
      }
      return "Recently";
    } catch (error) {
      return "Recently";
    }
  };

  const getUserRole = (team, userId) => {
    const member = team.members.find((m) => m.user === userId);
    return member?.role || "member";
  };

  return (
    <Card className="col-span-2">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-base font-semibold">My Teams</CardTitle>
        <Users className="h-4 w-4 text-muted-foreground" />
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-fit pr-4 lg:max-h-[300px] overflow-y-auto">
          <div className="space-y-4 pr-4">
            {teams?.length > 0 ? (
              teams.map((team) => {
                const isCreator =
                  team.creator ===
                  team.members.find((m) => m.role === "admin")?.user;

                return (
                  <div
  key={team._id}
  className={`flex items-center justify-between p-3 rounded-lg hover:bg-gray-100 transition-colors ${
    team._id === selectedTeam || 
    (selectedTeam === 'personal' && team.isPersonal) ? 
    'bg-black bg-opacity-5 border-2 border-black' : 
    'bg-gray-50 border border-gray-300'
  }`}
>
                    <div className="flex flex-col flex-grow min-w-0">
                      <div className="flex items-center space-x-2">
                        {isCreator ? (
                          <Crown className="h-4 w-4 text-yellow-500" />
                        ) : (
                          <Shield className="h-4 w-4 text-blue-500" />
                        )}
                        <span className="font-medium truncate">
                          {team.name}
                        </span>
                        {(team._id === selectedTeam || 
        (selectedTeam === 'personal' && team.isPersonal)) && (
        <Badge 
          variant="outline" 
          className="ml-2 bg-black text-white text-xs px-2 py-0.5"
        >
          Selected
        </Badge>
      )}
                      </div>
                      <div className="flex items-center mt-1 text-sm text-muted-foreground">
                        <User className="h-3 w-3 mr-1" />
                        <span>
                          {team.members?.length || 0} member
                          {team.members?.length !== 1 ? "s" : ""}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col items-end ml-4">
                      {team.isSeatsTeam && team.subscription?.tier && (
                        <div className="flex flex-col items-end gap-1">
                          <div className="flex items-center gap-1">
                            <Gem className="h-4 w-4 text-yellow-500" />
                            <Badge
                              variant="secondary"
                              className="mb-1 bg-yellow-300 text-black"
                            >
                              {team.subscription.tier}
                            </Badge>
                          </div>

                          <div className="flex items-center text-xs text-muted-foreground gap-1">
                            <div className="p-1 bg-black rounded-full">
                              <MessageSquare className="h-3 w-3 text-blue-500" />
                            </div>{" "}{team.subscription.credits.chat}
                            <div className="p-1 bg-black rounded-full">
                              <Image className="h-3 w-3 text-purple-500" />
                            </div>{" "}
                            {team.subscription.credits.image}
                            <div className="p-1 bg-black rounded-full">
                              <Play className="h-3 w-3 text-green-500" />
                            </div>{" "}
                            {team.subscription.credits.execution}
                          </div>
                        </div>
                      )}
                      <span className="text-xs text-muted-foreground">
                        Joined {formatJoinDate(team, team.creator)}
                      </span>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center text-muted-foreground py-8">
                No teams joined yet
              </div>
            )}
          </div>
        </ScrollArea>
      </CardContent>
    </Card>
  );
};

export default TeamsCard;
