import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserChatbots,
  setActiveChatbot,
  deleteUserChatbot,
} from "../slices/userChatbotSlice";
import UserChatbotList from "./UserChatbotComponents/UserChatbotList";
import UserChatbotDetails from "./UserChatbotComponents/UserChatbotDetails";
import UserChatbotForm from "./UserChatbotComponents/UserChatbotForm";
import { Card } from "../components/ui/card";
import { useToast } from "../components/ui/use-toast";
import { Button } from "../components/ui/button";
import { Cog, Menu, X } from "lucide-react";
import useSubscription from "../hooks/useSubscription";
import { useNavigate } from "react-router-dom";
import { openVideoModal } from '../slices/videoModalSlice';

const UserChatbotPage = () => {
  const dispatch = useDispatch();
  const { credits, limits } = useSubscription();
  const { chatbots, activeChatbot, status, error } = useSelector(
    (state) => state.userChatbots
  );
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchUserChatbots());
    }
  }, [status, dispatch]);

  const handleChatbotSelect = (chatbot) => {
    dispatch(setActiveChatbot(chatbot));
    setIsEditing(false);
    setIsMobileMenuOpen(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleNewChatbot = () => {
    if (chatbots.length >= limits.customAssistants) {
      toast({
        title: "Limit Reached",
        description: "You have reached the limit of custom assistants.",
        variant: "destructive",
      });
      return;
    }
    dispatch(setActiveChatbot(null));
    setIsEditing(true);
    setIsMobileMenuOpen(false);
  };

  const handleDeleteChatbot = (id) => {
    dispatch(deleteUserChatbot(id))
      .unwrap()
      .then(() => {
        if (activeChatbot && activeChatbot._id === id) {
          dispatch(setActiveChatbot(null));
        }
        dispatch(fetchUserChatbots());
        toast({
          title: "Chatbot Deleted",
          description: "The chatbot has been successfully deleted.",
        });
      })
      .catch((error) => {
        console.error("Failed to delete chatbot:", error);
        toast({
          title: "Error",
          description: "Failed to delete the chatbot. Please try again.",
          variant: "destructive",
        });
      });
  };

  const handleSubmitSuccess = (newChatbot) => {
    setIsEditing(false);
    dispatch(setActiveChatbot(newChatbot));
    dispatch(fetchUserChatbots());
    toast({
      title: isEditing ? "Chatbot Updated" : "Chatbot Created",
      description: `The chatbot "${newChatbot.name}" has been successfully ${
        isEditing ? "updated" : "created"
      }.`,
    });
  };

  if (!credits || !limits) {
    return <div className="text-center">Loading subscription details...</div>;
  }

  return (
    <div className="flex flex-col lg:flex-row h-[calc(100vh-4rem)] lg:h-[calc(100vh-0rem)]">
      {/* Mobile Header */}
      <div className="lg:hidden flex items-center px-4 border-b bg-background z-[60] -mt-4 -mx-6">
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="mr-2"
        >
          <Cog className="h-5 w-5" />
        </Button>
        <h2 className="text-base font-medium truncate">
          {!isMobileMenuOpen &&
            (activeChatbot ? activeChatbot.name : "Custom Assistants")}
        </h2>
      </div>

      {/* Chatbot List Panel */}
      <div
        className={`
            w-full lg:w-1/3 border-r bg-background
            fixed lg:relative inset-y-0 left-0 z-[60]
            transform transition-transform duration-200 ease-in-out
            lg:transform-none h-[100vh] lg:h-auto pt-0
            ${
              isMobileMenuOpen
                ? "translate-x-0"
                : "-translate-x-full lg:translate-x-0"
            }
          `}
      >
        <Card className="w-full px-4 overflow-y-auto border-0 bg-gray-100 h-full pt-10 lg:pt-0">
          {status === "loading" && <p>Loading...</p>}
          {status === "failed" && <p>Error: {error}</p>}
          {status === "succeeded" && (
            <UserChatbotList
              chatbots={chatbots}
              activeChatbot={activeChatbot}
              onChatbotSelect={handleChatbotSelect}
              onNewChatbot={handleNewChatbot}
              onDeleteChatbot={handleDeleteChatbot}
              isMobileMenuOpen={isMobileMenuOpen}
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              credits={credits}
              limits={limits}
            />
          )}
        </Card>
      </div>

      {/* Chatbot Details/Form Panel */}
      <div className="flex-1 lg:w-2/3 overflow-y-auto pt-0 lg:pt-4 lg:ml-8">
        <Card className="w-full pr-4 pt-2 overflow-y-auto border-0 bg-gray-100">
          {activeChatbot && !isEditing ? (
            <UserChatbotDetails
              chatbot={activeChatbot}
              onEditClick={handleEditClick}
              onDeleteChatbot={handleDeleteChatbot}
            />
          ) : /* Allow form access if editing OR if under limit */
          isEditing || chatbots.length < limits.customAssistants ? (
            <UserChatbotForm
              chatbot={isEditing ? activeChatbot : null}
              onSubmitSuccess={handleSubmitSuccess}
            />
          ) : (
            <div className="mt-4 border border-red-300 bg-red-100 p-4 rounded">
              <p className="text-red-500 text-sm font-semibold">
                You have reached the limit of custom assistants for your plan.
                Please upgrade to create more.
              </p>
            </div>
          )}
        </Card>
      </div>

      {/* Mobile Menu Overlay 
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black/50 z-[60] lg:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}*/}
    </div>
  );
};

export default UserChatbotPage;
