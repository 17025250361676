import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../services/api';

export const fetchAudioTracks = createAsyncThunk(
  'audio/fetchTracks',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getAudioTracks();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch audio tracks');
    }
  }
);

const initialState = {
    currentTrack: null,
    isPlaying: false,
    queue: [],
    volume: 1,
    duration: 0,
    currentTime: 0,
    tracks: [],
    loading: false,
    error: null,
    currentPlaylist: [],
    currentPlaylistIndex: 0
  };

const audioSlice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    setCurrentTrack: (state, action) => {
      state.currentTrack = action.payload;
    },
    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
    setVolume: (state, action) => {
      state.volume = action.payload;
    },
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload;
    },
    setPlaylist: (state, action) => {
      state.currentPlaylist = action.payload;
    },
    addToPlaylist: (state, action) => {
      state.currentPlaylist.push(action.payload);
    },
    removeFromPlaylist: (state, action) => {
      state.currentPlaylist = state.currentPlaylist.filter(track => track._id !== action.payload);
    },
    reorderPlaylist: (state, action) => {
      const { oldIndex, newIndex } = action.payload;
      const [removed] = state.currentPlaylist.splice(oldIndex, 1);
      state.currentPlaylist.splice(newIndex, 0, removed);
    },
    setPlaylistIndex: (state, action) => {
      state.currentPlaylistIndex = action.payload;
    },
    clearPlaylist: (state) => {
      state.currentPlaylist = [];
      state.currentPlaylistIndex = 0;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAudioTracks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAudioTracks.fulfilled, (state, action) => {
        state.loading = false;
        state.tracks = action.payload.tracks;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
        state.total = action.payload.total;
      })
      .addCase(fetchAudioTracks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const {
  setCurrentTrack,
  setIsPlaying,
  setVolume,
  setDuration,
  setCurrentTime,
  addToQueue,
  removeFromQueue,
  clearQueue,
    reorderQueue,
    loadQueue,
    setPlaylist,
    addToPlaylist,
    removeFromPlaylist,
    setPlaylistIndex,
    clearPlaylist,
    reorderPlaylist
} = audioSlice.actions;

export default audioSlice.reducer;