import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserAddedChatbots } from '../../slices/chatbotsSlice';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuGroup,
  DropdownMenuLabel,
} from '../../components/ui/dropdown-menu';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog';
import { Button } from '../../components/ui/button';
import { Avatar, AvatarFallback } from '../../components/ui/avatar';
import { ScrollArea } from '../../components/ui/scroll-area';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { ChevronRight, ArrowLeft } from 'lucide-react';

const AssistantSelector = ({
  selectedAssistant,
  onAssistantChange,
  assistants,
}) => {
  const dispatch = useDispatch();
  const userAddedChatbots = useSelector(
    (state) => state.chatbots.userChatbots.chatbots
  );

  const [isMobile, setIsMobile] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  // Check for mobile screen size
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 640); // sm breakpoint
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    dispatch(fetchUserAddedChatbots());
  }, [dispatch]);

  // Group assistants
  const groupedAssistants = useMemo(() => {
    const userCustomChatbots = assistants.filter(a => a.type === 'UserChatbot');
    const openAIAssistants = assistants.filter(a => a.type === 'OpenAIAssistant');
    const flowAssistants = assistants.filter(a => a.type === 'FlowChatbot');

    const userAddedGroups = userAddedChatbots.reduce((acc, bot) => {
      const type = bot.assistantType || 'other';
      if (!acc[type]) acc[type] = [];
      acc[type].push({ ...bot, type: 'MarketplaceChatbot' });
      return acc;
    }, {});

    const groups = {
      'Admin Assistants': userAddedGroups['admin'] || [],
      'Flow Tools': [...flowAssistants.filter(a => a.assistantType === 'flow')],
      'My Custom Assistants': userCustomChatbots,
      'General Assistants': [
        ...openAIAssistants.filter(a => a.assistantType === 'general'),
        ...(userAddedGroups['general'] || []),
      ],
      'AI Experts': userAddedGroups['expert'] || [],
      'AI Teams': userAddedGroups['team'] || [],
      'AI Tools': userAddedGroups['tool'] || [],
      'Other Assistants': [
        ...openAIAssistants.filter(a => !a.assistantType || a.assistantType === 'other'),
        ...(userAddedGroups['other'] || []),
      ],
      'Standard Assistants': userAddedGroups['standard'] || [],
    };

    return Object.fromEntries(
      Object.entries(groups).filter(([_, items]) => items.length > 0)
    );
  }, [assistants, userAddedChatbots]);

  // Get current assistant details
  const selectedAssistantDetails = useMemo(() => {
    const allAssistants = [
      ...assistants,
      ...userAddedChatbots.map(bot => ({
        ...bot,
        type: 'MarketplaceChatbot',
      })),
    ];
    return allAssistants.find(a => a._id === selectedAssistant);
  }, [assistants, userAddedChatbots, selectedAssistant]);

  // Render assistant avatar
  const renderAvatar = (assistant) => {
    if (
      assistant.type === 'OpenAIAssistant' ||
      assistant.type === 'MarketplaceChatbot' ||
      assistant.type === 'FlowChatbot'
    ) {
      return assistant.avatarUrl ? (
        <img
          src={assistant.avatarUrl}
          alt={assistant.name}
          className="object-cover"
        />
      ) : (
        <AvatarFallback className="bg-black">
          <FontAwesomeIcon icon={faRobot} className="text-white text-sm" />
        </AvatarFallback>
      );
    }
    return (
      <AvatarFallback className="bg-black">
        <FontAwesomeIcon icon={faRobot} className="text-white text-sm" />
      </AvatarFallback>
    );
  };

  // Handle assistant selection for mobile
  const handleMobileSelection = (assistantId) => {
    onAssistantChange(assistantId);
    setIsDialogOpen(false);
    setSelectedGroup(null);
  };

  // Render mobile dialog content
  const renderMobileContent = () => {
    if (selectedGroup) {
      const groupAssistants = groupedAssistants[selectedGroup];
      return (
        <>
          <DialogHeader className="space-y-4">
            <div className="flex items-center">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setSelectedGroup(null)}
                className="mr-2"
              >
                <ArrowLeft className="h-4 w-4" />
              </Button>
              <DialogTitle>{selectedGroup}</DialogTitle>
            </div>
          </DialogHeader>
          <ScrollArea className="max-h-[60vh] mt-4">
            {groupAssistants.map((assistant) => (
              <Button
                key={assistant._id}
                variant="ghost"
                className="w-full justify-start py-6"
                onClick={() => handleMobileSelection(assistant._id)}
              >
                <div className="flex items-start gap-2">
                  <Avatar className="w-6 h-6">
                    {renderAvatar(assistant)}
                  </Avatar>
                  <div className="flex-1 text-left">
                    <div className="font-medium text-sm">
                      {assistant.name}
                    </div>
                    <div className="text-xs text-muted-foreground mt-0.5">
                      {assistant.description || 'No description available'}
                    </div>
                  </div>
                </div>
              </Button>
            ))}
          </ScrollArea>
        </>
      );
    }

    return (
      <>
        <DialogHeader>
          <DialogTitle>Select Assistant</DialogTitle>
        </DialogHeader>
        <ScrollArea className="max-h-[60vh] mt-4">
          {Object.entries(groupedAssistants).map(([groupName, groupAssistants]) => (
            <Button
              key={groupName}
              variant="ghost"
              className="w-full justify-between py-6"
              onClick={() => setSelectedGroup(groupName)}
            >
              <span>{groupName}</span>
              <ChevronRight className="h-4 w-4" />
            </Button>
          ))}
        </ScrollArea>
      </>
    );
  };

  // Render desktop dropdown
  const renderDesktopDropdown = () => (
    <DropdownMenuContent className="w-56 z-[70]">
      {Object.entries(groupedAssistants).map(([groupName, groupAssistants]) => (
        <DropdownMenuSub key={groupName}>
          <DropdownMenuSubTrigger className="justify-between">
            {groupName}
          </DropdownMenuSubTrigger>
          <DropdownMenuSubContent className="w-80">
            <DropdownMenuLabel>{groupName}</DropdownMenuLabel>
            <DropdownMenuGroup>
              {groupAssistants.map((assistant) => (
                <DropdownMenuItem
                  key={assistant._id}
                  onSelect={() => onAssistantChange(assistant._id)}
                >
                  <div className="flex items-start gap-2 py-1">
                    <Avatar className="w-6 h-6">
                      {renderAvatar(assistant)}
                    </Avatar>
                    <div className="flex-1 min-w-0">
                      <div className="font-medium text-sm">
                        {assistant.name}
                      </div>
                      <div className="text-xs text-muted-foreground mt-0.5 flex-wrap">
                        {assistant.description || 'No description available'}
                      </div>
                    </div>
                  </div>
                </DropdownMenuItem>
              ))}
            </DropdownMenuGroup>
          </DropdownMenuSubContent>
        </DropdownMenuSub>
      ))}
    </DropdownMenuContent>
  );

  return (
    <>
      {isMobile ? (
        <Button
          variant="outline"
          role="combobox"
          className="w-full justify-between"
          onClick={() => setIsDialogOpen(true)}
        >
          {selectedAssistantDetails ? (
            <div className="flex items-center">
              <Avatar className="w-6 h-6 mr-2">
                {renderAvatar(selectedAssistantDetails)}
              </Avatar>
              <span className="truncate">{selectedAssistantDetails.name}</span>
            </div>
          ) : (
            'Select an assistant...'
          )}
          <FontAwesomeIcon
            icon={faChevronDown}
            className="ml-2 h-4 w-4 shrink-0 opacity-50"
          />
        </Button>
      ) : (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              role="combobox"
              className="w-full justify-between"
            >
              {selectedAssistantDetails ? (
                <div className="flex items-center">
                  <Avatar className="w-6 h-6 mr-2">
                    {renderAvatar(selectedAssistantDetails)}
                  </Avatar>
                  <span className="truncate">{selectedAssistantDetails.name}</span>
                </div>
              ) : (
                'Select an assistant...'
              )}
              <FontAwesomeIcon
                icon={faChevronDown}
                className="ml-2 h-4 w-4 shrink-0 opacity-50"
              />
            </Button>
          </DropdownMenuTrigger>
          {renderDesktopDropdown()}
        </DropdownMenu>
      )}

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[425px] z-[70]">
          {renderMobileContent()}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AssistantSelector;