import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserTeams,
  createTeam,
  joinTeamByCode,
  joinTeam,
  leaveTeam,
  inviteUserToTeam,
  revokeInvitation,
  updateMemberRole,
  deleteTeam,
  renameTeam,
} from "../slices/teamsSlice";
import {
  Card,
  CardHeader,
  CardContent,
  CardTitle,
} from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../components/ui/alert-dialog";
import { useToast } from "../components/ui/use-toast";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { Label } from "../components/ui/label";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { Badge } from "../components/ui/badge";
import { ScrollArea } from "../components/ui/scroll-area";
import { InfoIcon } from "lucide-react";

const ManageTeams = () => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const { teams, status, error } = useSelector((state) => state.teams);
  const currentUser = useSelector((state) => state.auth.user);
  const [activeTab, setActiveTab] = useState("myTeams");
  const [newTeamName, setNewTeamName] = useState("");
  const [newTeamCode, setNewTeamCode] = useState("");
  const [joinTeamCode, setJoinTeamCode] = useState("");
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteRole, setInviteRole] = useState("member");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [pendingRoleChange, setPendingRoleChange] = useState(null);
  const [pendingRevokeInvitation, setPendingRevokeInvitation] = useState(null);
  const [invitationToRevoke, setInvitationToRevoke] = useState(null);
  const [isRevoking, setIsRevoking] = useState(false);
  const [teamToRename, setTeamToRename] = useState(null);
  const [renameError, setRenameError] = useState("");

  useEffect(() => {
    dispatch(fetchUserTeams());
  }, [dispatch]);

  const prependedCode = useMemo(() => {
    const cleanedName = newTeamName.replace(/\s+/g, "").toLowerCase();
    return cleanedName.slice(0, 6);
  }, [newTeamName]);

  const handleRenameTeam = async (newName) => {
    if (!newName || newName.trim().length === 0) {
      setRenameError("Team name is required");
      return;
    }
    if (newName.length > 40) {
      setRenameError("Team name must be 40 characters or less");
      return;
    }
    try {
      await dispatch(
        renameTeam({ teamId: teamToRename._id, name: newName })
      ).unwrap();
      toast({
        title: "Success",
        description: "Team renamed successfully",
        variant: "default",
      });
      setTeamToRename(null);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to rename team: " + error.message,
        variant: "destructive",
      });
    }
  };

  const handleCreateTeam = () => {
    if (newTeamName.trim() && newTeamCode.trim()) {
      dispatch(
        createTeam({
          name: newTeamName.trim(),
          teamCode: `${prependedCode}-${newTeamCode.trim()}`,
        })
      )
        .unwrap()
        .then(() => {
          setNewTeamName("");
          setNewTeamCode("");
          toast({
            title: "Success",
            description: "Team created successfully",
            variant: "default",
          });
          dispatch(fetchUserTeams());
        })
        .catch((error) => {
          toast({
            title: "Error",
            description: "Failed to create team: " + error.message,
            variant: "destructive",
          });
        });
    }
  };

  const handleDeleteTeam = (teamId) => {
    dispatch(deleteTeam(teamId))
      .unwrap()
      .then(() => {
        toast({
          title: "Success",
          description: "Team deleted successfully",
          variant: "default",
        });
        dispatch(fetchUserTeams());
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: "Failed to delete team: " + error.message,
          variant: "destructive",
        });
      });
  };

  const handleJoinTeam = () => {
    if (joinTeamCode.trim()) {
      dispatch(joinTeamByCode(joinTeamCode.trim()))
        .unwrap()
        .then(() => {
          setJoinTeamCode("");
          toast({
            title: "Success",
            description: "Joined team successfully",
            variant: "default",
          });
          dispatch(fetchUserTeams());
        })
        .catch((error) => {
          let errorMessage = "Failed to join team";
          if (error.response && error.response.status === 403) {
            errorMessage =
              "You have not been invited to join this team. Please contact the team administrator for an invitation.";
          } else {
            errorMessage += ": " + error.message;
          }
          toast({
            title: "Error",
            description: errorMessage,
            variant: "destructive",
          });
        });
    }
  };

  const InfoBlurb = ({ children }) => (
    <div className="flex items-center space-x-2 text-sm text-gray-500 mt-2">
      <InfoIcon size={16} />
      <span>{children}</span>
    </div>
  );

  const handleLeaveTeam = (teamId) => {
    dispatch(leaveTeam(teamId))
      .unwrap()
      .then(() => {
        toast({
          title: "Success",
          description: "Left team successfully",
          variant: "default",
        });
        dispatch(fetchUserTeams());
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: "Failed to leave team: " + error.message,
          variant: "destructive",
        });
      });
  };

  const handleInviteUser = () => {
    if (inviteEmail.trim() && selectedTeam) {
      dispatch(
        inviteUserToTeam({
          teamId: selectedTeam,
          email: inviteEmail.trim(),
          role: inviteRole,
        })
      )
        .unwrap()
        .then(() => {
          setInviteEmail("");
          setInviteRole("member");
          setSelectedTeam("");
          toast({
            title: "Success",
            description: "Invitation sent successfully",
            variant: "default",
          });
          dispatch(fetchUserTeams());
        })
        .catch((error) => {
          toast({
            title: "Error",
            description: "Failed to send invitation: " + error.message,
            variant: "destructive",
          });
        });
    }
  };

  const handleRevokeInvitation = async (teamId, invitationId) => {
    setIsRevoking(true);
    try {
      await dispatch(revokeInvitation({ teamId, invitationId })).unwrap();
      toast({
        title: "Success",
        description: "Invitation revoked successfully",
        variant: "default",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to revoke invitation: " + error.message,
        variant: "destructive",
      });
    } finally {
      setIsRevoking(false);
    }
  };

  const handleUpdateMemberRole = () => {
    if (pendingRoleChange) {
      const { teamId, userId, role } = pendingRoleChange;
      dispatch(updateMemberRole({ teamId, userId, role }))
        .unwrap()
        .then(() => {
          toast({
            title: "Success",
            description: "Member role updated successfully",
            variant: "default",
          });
          dispatch(fetchUserTeams());
          setPendingRoleChange(null);
        })
        .catch((error) => {
          toast({
            title: "Error",
            description: "Failed to update member role: " + error.message,
            variant: "destructive",
          });
        });
    }
  };

  const handleRoleSelect = (teamId, userId, newRole) => {
    setPendingRoleChange({ teamId, userId, role: newRole });
  };

  /*if (status === 'loading') {
    return <div>Loading...</div>;
  }*/

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="space-y-2 lg:space-y-6 px-0 lg:px-4 lg:px-0 pb-6">
      <h1 className="text-2xl lg:text-3xl font-bold text-inter">
        Manage Teams
      </h1>
      <InfoBlurb>
        Create, join, and manage your teams. Collaborate with others by inviting
        them to your teams or accepting invitations to join existing teams.
      </InfoBlurb>

      <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
        <TabsList className="bg-black text-white w-full flex">
          <TabsTrigger value="myTeams" className="flex-1">
            My Teams
          </TabsTrigger>
          <TabsTrigger value="createJoin" className="flex-1">
            Create/Join
          </TabsTrigger>
          <TabsTrigger value="inviteManage" className="flex-1">
            Manage
          </TabsTrigger>
        </TabsList>

        <TabsContent value="myTeams">
          <InfoBlurb>
            View and manage your teams, including your role and the number of
            members. Team creators can delete teams if necessary.
          </InfoBlurb>
          <Card className="mt-3">
            <CardHeader className="p-2 lg:p-4">
              <CardTitle>Your Teams</CardTitle>
            </CardHeader>
            <CardContent className="p-2 lg:p-4">
              <ScrollArea className="h-[400px]">
                <div className="lg:hidden space-y-4">
                  {teams.map((team) => (
                    <Card key={team._id} className="p-2 bg-gray-100">
                      <div className="space-y-2">
                        <div className="flex justify-between items-start">
                          <div>
                            <h3 className="font-semibold">{team.name}</h3>
                            <Badge
                              variant={
                                team.userRole === "admin"
                                  ? "default"
                                  : "secondary"
                              }
                            >
                              {team.userRole}
                            </Badge>
                          </div>
                          <div>Members: {team.members.length}</div>
                        </div>
                        <div className="flex flex-col space-y-2">
                          {team.userRole === "admin" && (
                            <AlertDialog>
                              <AlertDialogTrigger asChild>
                                <Button variant="outline" size="sm">
                                  Rename Team
                                </Button>
                              </AlertDialogTrigger>
                              <AlertDialogContent className="z-[80]">
                                <AlertDialogHeader>
                                  <AlertDialogTitle>
                                    Rename Team
                                  </AlertDialogTitle>
                                  <AlertDialogDescription>
                                    Enter a new name for the team
                                  </AlertDialogDescription>
                                </AlertDialogHeader>
                                <Input
                                  placeholder="New team name"
                                  onChange={(e) => {
                                    setTeamToRename({
                                      ...team,
                                      newName: e.target.value,
                                    });
                                    setRenameError("");
                                  }}
                                  defaultValue={team.name}
                                  className="my-4"
                                  maxLength={40}
                                />
                                {renameError && (
                                  <p className="text-sm text-red-500 mt-1">
                                    {renameError}
                                  </p>
                                )}
                                <AlertDialogFooter>
                                  <AlertDialogCancel
                                    onClick={() => setTeamToRename(null)}
                                  >
                                    Cancel
                                  </AlertDialogCancel>
                                  <AlertDialogAction
                                    onClick={() =>
                                      handleRenameTeam(teamToRename.newName)
                                    }
                                  >
                                    Rename
                                  </AlertDialogAction>
                                </AlertDialogFooter>
                              </AlertDialogContent>
                            </AlertDialog>
                          )}
                          <AlertDialog>
                            <AlertDialogTrigger asChild>
                              <Button
                                variant="destructive"
                                size="sm"
                                className="w-full"
                              >
                                Leave Team
                              </Button>
                            </AlertDialogTrigger>
                            <AlertDialogContent className="z-[80]">
                              <AlertDialogHeader>
                                <AlertDialogTitle>
                                  Are you sure you want to leave this team?
                                </AlertDialogTitle>
                                <AlertDialogDescription>
                                  This will remove you from the team. You'll
                                  lose access to all team resources.
                                </AlertDialogDescription>
                              </AlertDialogHeader>
                              <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction
                                  onClick={() => handleLeaveTeam(team._id)}
                                >
                                  Leave Team
                                </AlertDialogAction>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>

                          {team.creator === currentUser._id && (
                            <AlertDialog>
                              <AlertDialogTrigger asChild>
                                <Button
                                  variant="destructive"
                                  size="sm"
                                  className="w-full"
                                >
                                  Delete Team
                                </Button>
                              </AlertDialogTrigger>
                              <AlertDialogContent className="z-[80]">
                                <AlertDialogHeader>
                                  <AlertDialogTitle>
                                    Are you sure you want to delete this team?
                                  </AlertDialogTitle>
                                  <AlertDialogDescription>
                                    This will permanently delete the team for
                                    all members. All team members will lose
                                    access to team-related assets they didn't
                                    create themselves. This action cannot be
                                    undone.
                                  </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                                  <AlertDialogAction
                                    onClick={() => handleDeleteTeam(team._id)}
                                  >
                                    Delete Team
                                  </AlertDialogAction>
                                </AlertDialogFooter>
                              </AlertDialogContent>
                            </AlertDialog>
                          )}
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
                <div className="hidden lg:block">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Team Name</TableHead>
                        <TableHead>Your Role</TableHead>
                        <TableHead>Members</TableHead>
                        <TableHead>Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {teams.map((team) => (
                        <TableRow key={team._id}>
                          <TableCell>{team.name}</TableCell>
                          <TableCell>
                            <Badge
                              variant={
                                team.userRole === "admin"
                                  ? "default"
                                  : "secondary"
                              }
                            >
                              {team.userRole}
                            </Badge>
                          </TableCell>
                          <TableCell>{team.members.length}</TableCell>
                          <TableCell>
                            <div className="space-x-2">
                              {team.userRole === "admin" && (
                                <AlertDialog>
                                  <AlertDialogTrigger asChild>
                                    <Button variant="outline" size="sm">
                                      Rename Team
                                    </Button>
                                  </AlertDialogTrigger>
                                  <AlertDialogContent className="z-[80]">
                                    <AlertDialogHeader>
                                      <AlertDialogTitle>
                                        Rename Team
                                      </AlertDialogTitle>
                                      <AlertDialogDescription>
                                        Enter a new name for the team
                                      </AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <Input
                                      placeholder="New team name"
                                      onChange={(e) => {
                                        setTeamToRename({
                                          ...team,
                                          newName: e.target.value,
                                        });
                                        setRenameError("");
                                      }}
                                      defaultValue={team.name}
                                      className="my-4"
                                      maxLength={40}
                                    />
                                    {renameError && (
                                      <p className="text-sm text-red-500 mt-1">
                                        {renameError}
                                      </p>
                                    )}
                                    <AlertDialogFooter>
                                      <AlertDialogCancel
                                        onClick={() => setTeamToRename(null)}
                                      >
                                        Cancel
                                      </AlertDialogCancel>
                                      <AlertDialogAction
                                        onClick={() =>
                                          handleRenameTeam(teamToRename.newName)
                                        }
                                      >
                                        Rename
                                      </AlertDialogAction>
                                    </AlertDialogFooter>
                                  </AlertDialogContent>
                                </AlertDialog>
                              )}
                              {team.members.length > 1 && (
                                <AlertDialog>
                                  <AlertDialogTrigger asChild>
                                    <Button variant="destructive" size="sm">
                                      Leave Team
                                    </Button>
                                  </AlertDialogTrigger>
                                  <AlertDialogContent className="z-[80]">
                                    <AlertDialogHeader>
                                      <AlertDialogTitle>
                                        Are you sure you want to leave this
                                        team?
                                      </AlertDialogTitle>
                                      <AlertDialogDescription>
                                        This will remove you from the team.
                                        You'll lose access to all team
                                        resources.
                                      </AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                      <AlertDialogCancel>
                                        Cancel
                                      </AlertDialogCancel>
                                      <AlertDialogAction
                                        onClick={() =>
                                          handleLeaveTeam(team._id)
                                        }
                                      >
                                        Leave Team
                                      </AlertDialogAction>
                                    </AlertDialogFooter>
                                  </AlertDialogContent>
                                </AlertDialog>
                              )}
                              {team.creator === currentUser._id && (
                                <AlertDialog>
                                  <AlertDialogTrigger asChild>
                                    <Button variant="destructive" size="sm">
                                      Delete Team
                                    </Button>
                                  </AlertDialogTrigger>
                                  <AlertDialogContent className="z-[80]">
                                    <AlertDialogHeader>
                                      <AlertDialogTitle>
                                        Are you sure you want to delete this
                                        team?
                                      </AlertDialogTitle>
                                      <AlertDialogDescription>
                                        This will permanently delete the team
                                        for all members. All team members will
                                        lose access to team-related assets they
                                        didn't create themselves. This action
                                        cannot be undone.
                                      </AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                      <AlertDialogCancel>
                                        Cancel
                                      </AlertDialogCancel>
                                      <AlertDialogAction
                                        onClick={() =>
                                          handleDeleteTeam(team._id)
                                        }
                                      >
                                        Delete Team
                                      </AlertDialogAction>
                                    </AlertDialogFooter>
                                  </AlertDialogContent>
                                </AlertDialog>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </ScrollArea>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="createJoin">
          <InfoBlurb>
            Create a team and set a unique team code, then invite users by email
            address in the Invite/Manage Users tab. Users can accept invitations
            via email or join using the team code from their dashboard.
          </InfoBlurb>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-3">
            <Card>
              <CardHeader>
                <CardTitle>Create New Team</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <div>
                    <Label htmlFor="newTeamName">Team Name</Label>
                    <Input
                      id="newTeamName"
                      type="text"
                      placeholder="Enter team name"
                      value={newTeamName}
                      onChange={(e) => setNewTeamName(e.target.value)}
                    />
                    <InfoBlurb>
                      The team name will be used to generate a unique prefix for
                      your team code
                    </InfoBlurb>
                  </div>
                  <div>
                    <Label htmlFor="newTeamCode">Team Code</Label>
                    <div className="flex">
                      <div className="bg-gray-100 p-2 rounded-l-md border border-r-0">
                        {prependedCode}-
                      </div>
                      <Input
                        id="newTeamCode"
                        type="text"
                        placeholder="Enter team code"
                        value={newTeamCode}
                        onChange={(e) => setNewTeamCode(e.target.value)}
                        className="rounded-l-none"
                      />
                    </div>
                    <InfoBlurb>
                      Choose a unique code for your team. It will be prefixed
                      with the first 6 letters of your team name
                    </InfoBlurb>
                  </div>
                  <Button onClick={handleCreateTeam}>Create Team</Button>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Join Existing Team</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <div>
                    <Label htmlFor="joinTeamCode">Team Code</Label>
                    <Input
                      id="joinTeamCode"
                      type="text"
                      placeholder="Enter team code"
                      value={joinTeamCode}
                      onChange={(e) => setJoinTeamCode(e.target.value)}
                    />
                    <InfoBlurb>
                      Enter the team code provided by your team administrator
                    </InfoBlurb>
                  </div>
                  <Button onClick={handleJoinTeam}>Join Team</Button>
                </div>
              </CardContent>
            </Card>
          </div>
        </TabsContent>

        <TabsContent value="inviteManage">
          <InfoBlurb>
            Invite users to your team by email address. Users can accept
            invitations via email or join using the team code from their
            dashboard. View and manage team members and pending invitations
            below.
          </InfoBlurb>
          <Card className="mt-3">
            <CardHeader>
              <CardTitle className="text-md lg:text-2xl">
                Invite User to Team
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4">
                <div className="space-y-2">
                  <Label htmlFor="inviteEmail">User's Email</Label>
                  <Input
                    id="inviteEmail"
                    type="email"
                    placeholder="Enter user's email"
                    value={inviteEmail}
                    onChange={(e) => setInviteEmail(e.target.value)}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="inviteRole">User's Role</Label>
                  <Select
                    id="inviteRole"
                    value={inviteRole}
                    onValueChange={setInviteRole}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select role" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="member">Member</SelectItem>
                      <SelectItem value="admin">Admin</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="space-y-2">
                  <Label htmlFor="selectedTeam">Select Team</Label>
                  <Select
                    id="selectedTeam"
                    value={selectedTeam}
                    onValueChange={setSelectedTeam}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select a team" />
                    </SelectTrigger>
                    <SelectContent>
                      {teams.map((team) => (
                        <SelectItem key={team._id} value={team._id}>
                          {team.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex items-end">
                  <Button
                    onClick={handleInviteUser}
                    disabled={!selectedTeam || !inviteEmail.trim()}
                    className="w-full"
                  >
                    Invite User
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>

          {teams.map((team) => (
            <Card key={team._id} className="mt-6">
              <CardHeader>
                <CardTitle className="text-md lg:text-2xl">
                  {team.name} - Members and Invitations
                </CardTitle>
              </CardHeader>
              <CardContent>
                <h3 className="text-lg font-semibold mb-2">Members</h3>
                <div className="lg:hidden space-y-2">
                  {team.members.map((member) => {
                    const isCurrentUserAdmin = team.members.some(
                      (m) =>
                        m.user._id === currentUser._id && m.role === "admin"
                    );
                    const canChangeRole =
                      isCurrentUserAdmin && member.user._id !== currentUser._id;

                    return (
                      <Card key={member._id} className="p-2 lg:p-4">
                        <div className="space-y-2">
                          <div>
                            <p className="font-semibold">{member.user.email}</p>
                            <Badge>{member.role}</Badge>
                          </div>
                          {canChangeRole && (
                            <AlertDialog>
                              <AlertDialogTrigger asChild>
                                <Button
                                  variant="outline"
                                  size="sm"
                                  className="w-full"
                                >
                                  Change Role
                                </Button>
                              </AlertDialogTrigger>
                              <AlertDialogContent className="z-[80]">
                                <AlertDialogHeader>
                                  <AlertDialogTitle>
                                    Change User Role
                                  </AlertDialogTitle>
                                  <AlertDialogDescription>
                                    Select a new role for {member.user.email}
                                  </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                                  <Select
                                    value={member.role}
                                    onValueChange={(newRole) =>
                                      handleRoleSelect(
                                        team._id,
                                        member.user._id,
                                        newRole
                                      )
                                    }
                                  >
                                    <SelectTrigger className="w-32">
                                      <SelectValue placeholder="Select role" />
                                    </SelectTrigger>
                                    <SelectContent>
                                      <SelectItem value="member">
                                        Member
                                      </SelectItem>
                                      <SelectItem value="admin">
                                        Admin
                                      </SelectItem>
                                    </SelectContent>
                                  </Select>
                                  <AlertDialogAction
                                    onClick={handleUpdateMemberRole}
                                  >
                                    Confirm
                                  </AlertDialogAction>
                                </AlertDialogFooter>
                              </AlertDialogContent>
                            </AlertDialog>
                          )}
                        </div>
                      </Card>
                    );
                  })}
                </div>
                <div className="hidden lg:block">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Email</TableHead>
                        <TableHead>Role</TableHead>
                        <TableHead>Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {team.members.map((member) => {
                        const isCurrentUserAdmin = team.members.some(
                          (m) =>
                            m.user._id === currentUser._id && m.role === "admin"
                        );
                        const canChangeRole =
                          isCurrentUserAdmin &&
                          member.user._id !== currentUser._id;

                        return (
                          <TableRow key={member._id}>
                            <TableCell>{member.user.email}</TableCell>
                            <TableCell>{member.role}</TableCell>
                            <TableCell>
                              {canChangeRole && (
                                <AlertDialog>
                                  <AlertDialogTrigger asChild>
                                    <Button variant="outline" size="sm">
                                      Change Role
                                    </Button>
                                  </AlertDialogTrigger>
                                  <AlertDialogContent className="z-[80]">
                                    <AlertDialogHeader>
                                      <AlertDialogTitle>
                                        Change User Role
                                      </AlertDialogTitle>
                                      <AlertDialogDescription>
                                        Select a new role for{" "}
                                        {member.user.email}
                                      </AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                      <AlertDialogCancel>
                                        Cancel
                                      </AlertDialogCancel>
                                      <Select
                                        value={member.role}
                                        onValueChange={(newRole) =>
                                          handleRoleSelect(
                                            team._id,
                                            member.user._id,
                                            newRole
                                          )
                                        }
                                      >
                                        <SelectTrigger className="w-32">
                                          <SelectValue placeholder="Select role" />
                                        </SelectTrigger>
                                        <SelectContent>
                                          <SelectItem value="member">
                                            Member
                                          </SelectItem>
                                          <SelectItem value="admin">
                                            Admin
                                          </SelectItem>
                                        </SelectContent>
                                      </Select>
                                      <AlertDialogAction
                                        onClick={handleUpdateMemberRole}
                                      >
                                        Confirm
                                      </AlertDialogAction>
                                    </AlertDialogFooter>
                                  </AlertDialogContent>
                                </AlertDialog>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>

                <h3 className="text-lg font-semibold mt-4 mb-2">
                  Pending Invitations
                </h3>
                <div className="lg:hidden space-y-4">
                  {team.invitations.map((invitation) => (
                    <Card key={invitation._id} className="p-2 lg:p-4">
                      <div className="space-y-2">
                        <div>
                          <p className="font-semibold">
                            {invitation.invitedEmail}
                          </p>
                          <Badge>{invitation.role}</Badge>
                          <p className="text-sm text-muted-foreground">
                            Status: {invitation.status}
                          </p>
                        </div>
                        {invitation.status === "pending" && (
                          <AlertDialog>
                            <AlertDialogTrigger asChild>
                              <Button
                                variant="destructive"
                                size="sm"
                                disabled={isRevoking}
                                className="w-full"
                              >
                                {isRevoking
                                  ? "Revoking..."
                                  : "Revoke Invitation"}
                              </Button>
                            </AlertDialogTrigger>
                            <AlertDialogContent className="z-[80]">
                              <AlertDialogHeader>
                                <AlertDialogTitle>
                                  Confirm Revoke Invitation
                                </AlertDialogTitle>
                                <AlertDialogDescription>
                                  Are you sure you want to revoke the invitation
                                  for {invitation.invitedEmail}? This action
                                  cannot be undone.
                                </AlertDialogDescription>
                              </AlertDialogHeader>
                              <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction
                                  onClick={() =>
                                    handleRevokeInvitation(
                                      team._id,
                                      invitation._id
                                    )
                                  }
                                  disabled={isRevoking}
                                >
                                  {isRevoking
                                    ? "Revoking..."
                                    : "Revoke Invitation"}
                                </AlertDialogAction>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>
                        )}
                      </div>
                    </Card>
                  ))}
                </div>
                <div className="hidden lg:block">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Email</TableHead>
                        <TableHead>Invited Role</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {team.invitations.map((invitation) => (
                        <TableRow key={invitation._id}>
                          <TableCell>{invitation.invitedEmail}</TableCell>
                          <TableCell>{invitation.role}</TableCell>
                          <TableCell>{invitation.status}</TableCell>
                          <TableCell>
                            {invitation.status === "pending" && (
                              <AlertDialog>
                                <AlertDialogTrigger asChild>
                                  <Button
                                    variant="destructive"
                                    size="sm"
                                    disabled={isRevoking}
                                  >
                                    Revoke
                                  </Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent className="z-[80]">
                                  <AlertDialogHeader>
                                    <AlertDialogTitle>
                                      Confirm Revoke Invitation
                                    </AlertDialogTitle>
                                    <AlertDialogDescription>
                                      Are you sure you want to revoke the
                                      invitation for {invitation.invitedEmail}?
                                      This action cannot be undone.
                                    </AlertDialogDescription>
                                  </AlertDialogHeader>
                                  <AlertDialogFooter>
                                    <AlertDialogCancel>
                                      Cancel
                                    </AlertDialogCancel>
                                    <AlertDialogAction
                                      onClick={() =>
                                        handleRevokeInvitation(
                                          team._id,
                                          invitation._id
                                        )
                                      }
                                      disabled={isRevoking}
                                    >
                                      {isRevoking
                                        ? "Revoking..."
                                        : "Revoke Invitation"}
                                    </AlertDialogAction>
                                  </AlertDialogFooter>
                                </AlertDialogContent>
                              </AlertDialog>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </CardContent>
            </Card>
          ))}
        </TabsContent>
      </Tabs>

      <AlertDialog
        open={!!pendingRoleChange}
        onOpenChange={(isOpen) => !isOpen && setPendingRoleChange(null)}
      >
        <AlertDialogContent className="z-[80]">
          <AlertDialogHeader>
            <AlertDialogTitle>Confirm Role Change</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to change the user's role to{" "}
              {pendingRoleChange?.role}? This action can't be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setPendingRoleChange(null)}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleUpdateMemberRole}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default ManageTeams;
