import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import VideoTutorialsAll from './DocumentationComponents/VideoTutorialsAll';
import { LifeBuoy, HelpCircle } from 'lucide-react';

const DocumentationPage = () => {
  const navigate = useNavigate();

  return (
    <div className="container mx-auto px-4 py-2 lg:py-8">
      <Card className="w-full max-w-6xl mx-auto min-h-[70vh]">
        <CardHeader>
          <CardTitle className="text-lg lg:text-2xl font-bold flex items-center leading-none">
            <LifeBuoy className="mr-2 h-6 w-6 text-pink-700" />
            Help & Guides - Flow Orchestra
          </CardTitle>
          <p className="text-muted-foreground mt-2">
            Welcome to the help center. Here you'll find video tutorials and a growing list of guides, and resources 
            to help you get the most out of Flow Orchestra. Can't find what you're looking for? 
            Visit our support page for personalized assistance.
          </p>
        </CardHeader>
        <CardContent className="space-y-10">
          <VideoTutorialsAll />
          <div className="flex justify-center pt-4">
            <Button 
              onClick={() => navigate('/support')}
              className="bg-black hover:bg-pink-700 text-white hover:text-white"
            >
              <HelpCircle className="mr-2 h-4 w-4" />
              Get Support
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default DocumentationPage;