// src/components/FAQSection.js
import React from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion";
import { faqSets, defaultFaqType } from './data/faqData';

const FAQSection = ({ type = defaultFaqType, className = "" }) => {
  // Get the appropriate FAQ set or fall back to default
  const faqs = faqSets[type] || faqSets[defaultFaqType];
  
  // Customize titles based on type
  const getTitleContent = () => {
    switch(type) {
      case 'subscriptions':
        return {
          title: "Subscription FAQs",
          subtitle: "Everything you need to know about Flow Orchestra pricing"
        };
      case 'login':
        return {
          title: "Account & Login Help",
          subtitle: "Common questions about accessing Flow Orchestra"
        };
      default:
        return {
          title: "Frequently Asked Questions",
          subtitle: "Everything you need to know about Flow Orchestra"
        };
    }
  };

  const { title, subtitle } = getTitleContent();

  return (
    <div className={`min-h-screen flex flex-col justify-center items-center p-6 pb-20 lg:p-12 ${className}`}>
      <div className="max-w-3xl w-full mb-10">
        <h2 className="text-3xl font-bold text-center mb-4">{title}</h2>
        <p className="text-gray-600 text-center mb-12">
          {subtitle}
        </p>

        <Accordion type="single" collapsible className="w-full">
          {faqs.map((faq, index) => (
            <AccordionItem key={index} value={`item-${index}`}>
              <AccordionTrigger className="text-left">
                {faq.question}
              </AccordionTrigger>
              <AccordionContent className="text-gray-600">
                {faq.answer}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FAQSection;