import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";
import { ScrollArea } from "../ui/scroll-area";
import { Music2, Play, Pause, Tag, Clock, User, MoreVertical } from 'lucide-react';
import { addToPlaylist, setCurrentTrack, setIsPlaying, setPlaylist } from '../../slices/audioSlice';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger
  } from "../ui/dropdown-menu";
  import { addToQueue } from '../../slices/audioSlice';
import { format } from 'date-fns';

const AudioTrackList = ({ 
  tracks, 
  currentTrack, 
  isPlaying, 
  onPlayTrack, 
  loading,
}) => {
    const dispatch = useDispatch();

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!Array.isArray(tracks) || !tracks.length) {
    return (
      <Card>
        <CardContent className="flex flex-col items-center justify-center py-12">
          <Music2 className="h-12 w-12 text-muted-foreground mb-4" />
          <p className="text-muted-foreground">No tracks found</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <ScrollArea className="h-[calc(100vh-280px)]">
      <div className="space-y-2">
        {tracks.map((track) => (
          <Card 
            key={track._id}
            className={`transition-colors hover:bg-accent/50 ${
              currentTrack?._id === track._id ? 'bg-accent' : ''
            }`}
          >
            <CardContent className="flex items-center gap-4 p-4">
              <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center flex-shrink-0">
                {track.coverUrl ? (
                  <img 
                    src={track.coverUrl} 
                    alt={track.title} 
                    className="w-full h-full object-cover rounded-lg shadow-xl"
                  />
                ) : (
                  <Music2 className="w-6 h-6 text-primary" />
                )}
              </div>

              <div className="flex-1 min-w-0">
                <div className="flex items-center gap-2">
                  <h3 className="font-medium truncate">{track.title}</h3>
                  {/*track.isPublic ? (
                    <Badge variant="secondary">Public</Badge>
                  ) : (
                    <Badge variant="outline">Private</Badge>
                  )*/}
                  <Badge className="capitalize bg-indigo-100 border border-indigo-500" variant="outline">{track.genre}</Badge>
                </div>
                <div className="flex items-center gap-4 text-sm text-muted-foreground">
                  <span className="flex items-center gap-1">
                    <User className="w-3 h-3" />
                    {track.artist}
                  </span>
                  {track.album && (
                    <span>• {track.album}</span>
                  )}
                  <span className="flex items-center gap-1">
                    <Clock className="w-3 h-3" />
                    {formatDuration(track.duration)}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-4">
                {track.tags?.length > 0 && (
                  <div className="hidden md:flex md:flex-col items-center gap-1">
                    <div className='text-sm text-muted-foreground'>
                        Helps with:
                    </div>
                    <div className="flex gap-1">
                      {track.tags.slice(0, 2).map((tag) => (
                        <Badge key={tag} variant="outline" className="text-xs">
                          {tag}
                        </Badge>
                      ))}
                      {track.tags.length > 2 && (
                        <Badge variant="outline" className="text-xs">
                          +{track.tags.length - 2}
                        </Badge>
                      )}
                    </div>
                  </div>
                )}

                {track.frequency && (
                    <div className='flex flex-col items-center gap-1'>
                    <div className='text-sm text-muted-foreground'>
                        Frequency:
                    </div>
                  <Badge variant="outline" className="text-xs bg-green-100 border border-green-500">
                    {track.frequency}hz
                    </Badge>
                    </div>

                )}

<DropdownMenu>
  <DropdownMenuTrigger asChild>
    <Button variant="ghost" size="icon">
      <MoreVertical className="h-4 w-4" />
    </Button>
  </DropdownMenuTrigger>
  <DropdownMenuContent>
  <DropdownMenuItem onClick={() => dispatch(addToPlaylist(track))}>
  Add to Playlist
</DropdownMenuItem>
<DropdownMenuItem 
  onClick={() => {
    dispatch(setPlaylist([track]));
    dispatch(setCurrentTrack(track));
    dispatch(setIsPlaying(true));
  }}
>
  Play as Playlist
</DropdownMenuItem>
    <DropdownMenuItem onClick={() => onPlayTrack(track)}>
      Play Now
    </DropdownMenuItem>
    <DropdownMenuItem onClick={() => dispatch(addToQueue(track))}>
      Add to Queue
    </DropdownMenuItem>
  </DropdownMenuContent>
</DropdownMenu>

                <Button
                  variant={currentTrack?._id === track._id ? "default" : "outline"}
                  size="icon"
                  onClick={() => onPlayTrack(track)}
                >
                  {currentTrack?._id === track._id && isPlaying ? (
                    <Pause className="h-4 w-4" />
                  ) : (
                    <Play className="h-4 w-4" />
                  )}
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </ScrollArea>
  );
};

export default AudioTrackList;