import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import { fetchFlows } from "../slices/flowsSlice";
import { fetchExecutions } from "../slices/executionsSlice";
import {
  Card,
  CardHeader,
  CardContent,
  CardTitle,
  CardFooter,
} from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Activity, GitBranch, Play, AlertTriangle, Users, ExternalLink, Plus, List, Clock, AlertCircle } from "lucide-react";
import { formatDistanceToNow } from 'date-fns';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import TeamsCard from "./DashboardComponents/TeamsCard";
import CreditsCard from "./DashboardComponents/CreditsCard";
import useSubscription from "../hooks/useSubscription";
import TodoList from "../components/TodoList";
import TodoDetails from '../components/TodoComponents/TodoDetails';
import { setSelectedTodo } from '../slices/todoSlice';
import { openVideoModal } from '../slices/videoModalSlice';
import VideoTutorials from "./DashboardComponents/VideoTutorials";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { flows } = useSelector((state) => state.flows);
  const { loading: subscriptionLoading, credits, limits, periodDates } = useSubscription();
  const executionsState = useSelector((state) => state.executions);
  const { selectedTeam, selectedTeamFull } = useSelector((state) => state.teams);
  const { teams } = useSelector((state) => state.teams);
  const [error, setError] = useState(null);
  const [filteredExecutions, setFilteredExecutions] = useState([]);
  const [statusFilter, setStatusFilter] = useState("all");
  const [chartData, setChartData] = useState([]);
  const isLoading = executionsState.status === 'loading';
  const isSubscriptionLoading = subscriptionLoading;
  const [selectedTodo, setSelectedTodo] = useState(null);
  const [isTodoModalOpen, setIsTodoModalOpen] = useState(false);
  //console.log('periodDates:', periodDates);
  //console.log('Dashboard limits:', limits);
  //console.log('Dashboard loading:', subscriptionLoading);
  //console.log('selectedTeam:', selectedTeam);

  const fetchData = async () => {
    try {
      await Promise.all([
        dispatch(fetchFlows({ page: 1, limit: 50, teamId: selectedTeam })).unwrap(),
        dispatch(fetchExecutions({ 
          page: 1, 
          limit: 50,
          teamId: selectedTeam, 
          sortBy: 'startTime', 
          sortOrder: 'desc' 
        })).unwrap(),
      ]);
    } catch (err) {
      setError("Failed to fetch dashboard data. Please try again later.");
      console.error("Error fetching data:", err);
    }
  };
  
  // 2. Add a useEffect specifically for team changes
  useEffect(() => {
    fetchData();
  }, [selectedTeam]);

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  const formatStatValue = (value) => {
    return value >= 50 ? '49+' : value.toString();
  };

  const handleTodoClick = (todo) => {
    setSelectedTodo(todo);
    setIsTodoModalOpen(true);
  };

  useEffect(() => {
    if (executionsState.executions) {
      const filtered = statusFilter === "all"
        ? executionsState.executions.slice(0, 50)
        : executionsState.executions.filter(execution => execution.status === statusFilter).slice(0, 50);
      
      setFilteredExecutions(filtered);
  
      const executionStatusCounts = executionsState.executions.reduce((acc, execution) => {
        acc[execution.status] = (acc[execution.status] || 0) + 1;
        return acc;
      }, {});
  
      setChartData([
        { id: "Completed", label: "Completed", value: executionStatusCounts.completed || 0 },
        { id: "Failed", label: "Failed", value: executionStatusCounts.failed || 0 },
        { id: "Running", label: "Running", value: executionStatusCounts.running || 0 },
      ]);
    }
  }, [executionsState.executions, statusFilter, selectedTeam]);

  const handleStatusFilterChange = (value) => {
    setStatusFilter(value);
  };

  const handleViewResults = (executionId) => {
    navigate(`/execution/${executionId}`, { state: { viewingPrevious: true } });
  };

  // For a single video:
const handleOpenSingleVideo = () => {
  dispatch(openVideoModal({
    url: 'https://otp.nyc3.cdn.digitaloceanspaces.com/Flow_Orchestra/videos/Explainer_Vids/How%20to%20Build%20a%20Flow%20form%20Scratch%20%20-%20Explainer%20Vid.mp4',
    title: 'How to Create Your First Flow'
  }));
};

// For multiple videos:
const handleOpenPlaylist = () => {
  dispatch(openVideoModal([
    {
      url: 'https://example.com/video1.mp4',
      title: 'Getting Started'
    },
    {
      url: 'https://example.com/video2.mp4',
      title: 'Advanced Features'
    }
  ]));
};

  const activeFlows = flows.filter((flow) => flow.isActive).length;

  const softColors = ['#09c269', '#F87060', '#F6BD60'];

  const getColor = (bar) => {
    const colors = {
      Completed: softColors[0],
      Failed: softColors[1],
      Running: softColors[2]
    };
    return colors[bar.data.id] || softColors[0];
  };

  // For the Bar Chart, update the axisLeft and axisBottom configuration:
  const MemoizedBarChart = useMemo(() => {
    return () => (
      <ResponsiveBar
        data={chartData}
        keys={['value']}
        indexBy="id"
        margin={{ top: 10, right: 10, bottom: 40, left: 80 }}
        padding={0.3}
        layout="horizontal"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={getColor}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 3]] }}
        animate={false}
        motionStiffness={90}
        motionDamping={15}
        theme={{
          axis: {
            ticks: {
              text: {
                fontSize: 12,
                fontWeight: 700
              }
            },
            legend: {
              text: {
                fontSize: 12,
                fontWeight: 700
              }
            }
          },
          labels: {
            text: {
              fontSize: 12,
              fontWeight: 700
            }
          }
        }}
      />
    );
  }, [chartData]);

// For the Pie Chart, update the labels configuration:
const MemoizedPieChart = useMemo(() => {
  return () => (
    <ResponsivePie
      data={chartData}
      margin={{ top: 35, right: 20, bottom: 20, left: 20 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      colors={getColor}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      radialLabelsSkipAngle={60}
      radialLabelsTextColor="#333333"
      radialLabelsLinkColor={{ from: 'color' }}
      radialLabel={d => `${d.id} (${d.value})`}
      radialLabelsTextXOffset={16}
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={16}
      radialLabelsLinkHorizontalLength={24}
      radialLabelsLinkStrokeWidth={1}
      sliceLabelsSkipAngle={10}
      sliceLabelsTextColor="#333333"
      sliceLabel={d => `${d.value}`}
      enableSlicesLabels={false}
      slicesLabelsSkipAngle={10}
      slicesLabelsTextColor="#333333"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      theme={{
        labels: {
          text: {
            fontSize: 14,
            fontWeight: 700
          }
        },
        tooltip: {
          container: {
            fontSize: 14,
            fontWeight: 700
          }
        }
      }}
    />
  );
}, [chartData]);



if (executionsState.status === 'failed') {
  return (
    <Alert variant="destructive">
      <AlertTitle>Error</AlertTitle>
      <AlertDescription>Failed to load dashboard data: {executionsState.error}</AlertDescription>
    </Alert>
  );
}

if (isSubscriptionLoading || !credits || !limits) {
  return (
    <Alert>
      <AlertTitle>Loading</AlertTitle>
      <AlertDescription>Loading subscription data...</AlertDescription>
    </Alert>
  );
}

  return (
    <div className="space-y-6 pb-10">
      <h1 className="text-3xl font-bold text-inter capitalize">{selectedTeamFull?.name || selectedTeam} Dashboard</h1>
      {error && (
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      <VideoTutorials />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2 sm:gap-4">
      <Card>
  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-0 pt-2 sm:pb-2 sm:pt-6">
    <CardTitle className="text-sm font-medium">Total Flows</CardTitle>
    <GitBranch className="inline-flex items-center justify-center bg-black text-white rounded-full h-8 w-8 p-1" />
  </CardHeader>
  <CardContent className="py-0 sm:py-6 pb-1">
  <div className="text-2xl font-bold">{formatStatValue(flows?.length)}</div>
    
  </CardContent>
</Card>
        <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-0 pt-2 sm:pb-2 sm:pt-6">
            <CardTitle className="text-sm font-medium">Active Flows</CardTitle>
            <Activity className="inline-flex items-center justify-center bg-black text-white rounded-full h-8 w-8 p-1" />
          </CardHeader>
          <CardContent className="py-0 sm:py-6 pb-1">
  <div className="text-2xl font-bold">{formatStatValue(activeFlows)}</div>
</CardContent>
        </Card>
        <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-0 pt-2 sm:pb-2 sm:pt-6">
            <CardTitle className="text-sm font-medium">Total Executions</CardTitle>
            <Play className="inline-flex items-center justify-center bg-black text-white rounded-full h-8 w-8 p-1" />
          </CardHeader>
          <CardContent className="py-0 sm:py-6 pb-1">
  <div className="text-2xl font-bold">{formatStatValue(executionsState.totalExecutions || 0)}</div>
</CardContent>
        </Card>
        <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-0 pt-2 sm:pb-2 sm:pt-6">
            <CardTitle className="text-sm font-medium">Failed Executions</CardTitle>
            <AlertTriangle className="inline-flex items-center justify-center bg-black text-white rounded-full h-8 w-8 p-1" />
          </CardHeader>
          <CardContent className="py-0 sm:py-6 pb-1">
  <div className="text-2xl font-bold">
    {formatStatValue(chartData.find(item => item.id === "Failed")?.value || 0)}
  </div>
</CardContent>
        </Card>
        <Card>
  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-1 pt-2 sm:pb-2 sm:pt-6">
    <CardTitle className="text-sm font-medium">Report Bug</CardTitle>
    <AlertCircle className="inline-flex items-center justify-center bg-red-500 text-white rounded-full h-8 w-8 p-1" />
  </CardHeader>
  <CardContent className="py-0 sm:py-6 pb-2">
    <Button 
      variant="ghost" 
      className="text-sm w-full text-gray-600 hover:text-gray-800 bg-red-100 hover:bg-red-200"
      onClick={() => navigate('/support')}
    >
      Submit Feedback
    </Button>
  </CardContent>
</Card>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-5 gap-2 sm:gap-4 lg:max-h-[400px]">
  <CreditsCard 
    chatCredits={credits.chat}
    imageCredits={credits.image}
    executionCredits={credits.execution}
    periodDates={periodDates}
  />
  <TeamsCard teams={teams} selectedTeam={selectedTeam} />
  <TodoList 
  isWidget={true}
  maxItems={5}
  teamId={selectedTeam?._id}  // If you want team-specific todos
  onItemClick={handleTodoClick}
/>
<TodoDetails 
  todo={selectedTodo}
  isOpen={isTodoModalOpen}
  setSelectedTodo={setSelectedTodo}  // Add this prop
  onClose={() => {
    setIsTodoModalOpen(false);
    setSelectedTodo(null);
  }}
/>
</div>

{isLoading ? (<>
  <div className="flex items-center justify-center pt-6">
      <div className="space-y-4 text-center flex flex-col items-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-black"></div>
        <p className="text-lg font-medium">Loading executions data...</p>
      </div>
    </div>
  </>):(<>
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      
      <Card className="lg:col-span-1 flex flex-col h-[500px]">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-base">Recent Executions</CardTitle>
          <Select onValueChange={handleStatusFilterChange} defaultValue="all">
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Filter by status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All</SelectItem>
              <SelectItem value="completed">Completed</SelectItem>
              <SelectItem value="failed">Failed</SelectItem>
              <SelectItem value="pending">Pending</SelectItem>
            </SelectContent>
          </Select>
        </CardHeader>
        <CardContent className="flex-grow overflow-hidden">
          <div className="h-full overflow-y-auto pr-2">
            <ul className="space-y-4">
              {filteredExecutions.length > 0 ? (
                filteredExecutions.map((execution) => (
                  <li
                    key={execution._id}
                    className="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center bg-gray-50 p-4 rounded-lg shadow-sm"
                  >
                    <div className="flex flex-col mb-2 sm:mb-0">
                      <span className="font-semibold text-gray-800">{execution.flow?.name}</span>
                      <span className="text-sm text-gray-500">
                        {formatDistanceToNow(new Date(execution.startTime), { addSuffix: true })}
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <span
                        className={`px-3 py-1 rounded-full text-xs font-medium ${
                          execution.status === "completed"
                            ? "bg-green-100 text-green-800"
                            : execution.status === "failed"
                            ? "bg-red-100 text-red-800"
                            : "bg-yellow-100 text-yellow-800"
                        }`}
                      >
                        {execution.status}
                      </span>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handleViewResults(execution._id)}
                        className="text-gray-600 hover:text-gray-800 transition-colors duration-200"
                      >
                        <ExternalLink className="h-4 w-4 mr-2" />
                        View
                      </Button>
                    </div>
                  </li>
                ))
              ) : (
                <li className="text-center text-gray-500">No executions found.</li>
              )}
            </ul>
          </div>
        </CardContent>
        <CardFooter className="mt-auto grid grid-cols-2 gap-2">
          <NavLink to="/create-flow" className="w-full">
            <Button variant="default" className="w-full bg-black text-white hover:bg-gray-800">
              <Plus className="mr-2 h-4 w-4" />
              Create Flow
            </Button>
          </NavLink>
          <NavLink to="/manage-flows" className="w-full">
            <Button variant="default" className="w-full bg-black text-white hover:bg-gray-800">
              <List className="mr-2 h-4 w-4" />
              Manage Flows
            </Button>
          </NavLink>
          {/*<NavLink to="/scheduling" className="w-full">
            <Button variant="default" className="w-full bg-black text-white hover:bg-gray-800">
              <Clock className="mr-2 h-4 w-4" />
              Scheduling
            </Button>
          </NavLink>*/}
        </CardFooter>
      </Card>
      <Card className="lg:col-span-1">
        <CardHeader>
          <CardTitle className="text-inter text-base font-semibold">Execution Status Overview</CardTitle>
        </CardHeader>
        <CardContent className="h-auto md:h-[300px]">
<div className="h-full">
  <div className="h-[300px] md:h-full">
    <MemoizedBarChart />
  </div>
  {/*<div className="h-[300px] md:h-full">
    <MemoizedPieChart />
  </div>*/}
</div>
</CardContent>
      </Card>
    </div>
  </>)}
      
    </div>
  );
};

export default Dashboard;