import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCheckoutSession, selectMarketplaceFilters } from '../../slices/chatbotsSlice';
import { useToast } from '../../components/ui/use-toast';
import { Dialog, DialogContent } from '../../components/ui/dialog';
import ChatbotCard from './ChatbotCard';
import ChatbotDetails from './ChatbotDetails';
import { Loader2 } from 'lucide-react';
import { loadStripe } from '@stripe/stripe-js';

const MarketplaceView = ({
  chatbots,
  loading,
  onChatbotSelect,
  selectedChatbotId,
  isMobileView,
  ownedChatbotIds
}) => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const filters = useSelector(selectMarketplaceFilters);
  const [showDetailsDialog, setShowDetailsDialog] = React.useState(false);
  const displayableChatbots = useMemo(() => 
    chatbots.filter(bot => !ownedChatbotIds.has(bot._id)),
    [chatbots, ownedChatbotIds]
  );

  const stripePromise = loadStripe('pk_live_51QgtRaAc8dniylqxX9SHcE95h6VHPiByeG12czEsFhGzNlSYMtGZ51nP5eY3J6GfLtviyojG6bP9ugzoFltetLIj007FP6XnBY');

  const handlePurchase = async (chatbot) => {
    try {
      const result = await dispatch(createCheckoutSession(chatbot._id)).unwrap();
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Stripe failed to initialize');
      }
      const { error } = await stripe.redirectToCheckout({
        sessionId: result.sessionId
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to initiate purchase",
        variant: "destructive"
      });
    }
  };

  const handleChatbotClick = (chatbot) => {
    onChatbotSelect(chatbot._id);
    if (isMobileView) {
      setShowDetailsDialog(true);
    }
  };

  const filteredChatbots = React.useMemo(() => {
    return chatbots.filter(chatbot => {
      // First check if chatbot is owned
      if (ownedChatbotIds?.has(chatbot._id)) return false;
  
      const matchesSearch = chatbot.name.toLowerCase().includes(filters.search.toLowerCase()) ||
        chatbot.description.toLowerCase().includes(filters.search.toLowerCase());
      
      const matchesAccessType = filters.accessType === 'all' || chatbot.accessType === filters.accessType;
      
      const matchesPriceRange = chatbot.accessType === 'paid' ? 
        (!filters.priceRange.min || chatbot.price >= filters.priceRange.min) &&
        (!filters.priceRange.max || chatbot.price <= filters.priceRange.max) : true;
      
      const matchesSalesGroup = filters.salesGroup === 'all' || chatbot.salesGroup === filters.salesGroup;
  
      return matchesSearch && matchesAccessType && matchesPriceRange && matchesSalesGroup;
    });
  }, [chatbots, filters, ownedChatbotIds]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
        {filteredChatbots.map(chatbot => (
          <ChatbotCard
            key={chatbot._id}
            chatbot={chatbot}
            isSelected={chatbot._id === selectedChatbotId}
            onClick={handleChatbotClick}
            onPurchase={handlePurchase}
          />
        ))}
        {filteredChatbots.length === 0 && (
          <div className="col-span-full text-center py-8 text-muted-foreground">
            No chatbots found matching your criteria
          </div>
        )}
      </div>

      {isMobileView && (
        <Dialog open={showDetailsDialog} onOpenChange={setShowDetailsDialog}>
          <DialogContent className="sm:max-w-[425px] mt-16">
            {selectedChatbotId && (
              <ChatbotDetails
                chatbotId={selectedChatbotId}
                onClose={() => setShowDetailsDialog(false)}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default MarketplaceView;