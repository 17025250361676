import React from 'react';
import { Button } from '../../components/ui/button';
import { Card } from '../../components/ui/card';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../components/ui/alert-dialog';
import { ScrollArea } from '../../components/ui/scroll-area';

const UserChatbotDetails = ({ chatbot, onEditClick, onDeleteChatbot }) => {
  const handleDelete = () => {
    if (onDeleteChatbot) {
      onDeleteChatbot(chatbot._id);
    }
  };

  return (
    <ScrollArea className="h-full px-0 lg:px-0">
      <Card className="py-4 px-4 lg:px-10">
        <div className='flex flex-col items-center mb-8 rounded bg-gray-100 p-4'>
          <h2 className="text-xl lg:text-2xl font-bold mb-4">{chatbot.name}</h2>
          <img 
            src={chatbot.avatarUrl} 
            alt={chatbot.name} 
            className="w-20 h-20 lg:w-24 lg:h-24 rounded-full mb-4" 
          />
          <p className="mb-2 text-sm lg:text-base text-center">
            <strong>Description:</strong> {chatbot.description}
          </p>
        </div>
        
        <div className="mb-4 lg:mb-6">
          <p className="whitespace-pre-wrap text-sm lg:text-base">
            <strong>Prompt:</strong> {chatbot.prompt}
          </p>
        </div>

        <div className="mb-4 lg:mb-6">
          <p className="text-sm lg:text-base">
            <strong>Instructions:</strong> {chatbot.instructions}
          </p>
        </div>

        <div className="mb-4 lg:mb-6">
          <strong className="text-sm lg:text-base">Suggested Prompts:</strong>
          <ul className="list-disc list-inside mt-2">
            {chatbot.suggested_prompts && chatbot.suggested_prompts.map((prompt, index) => (
              <li key={index} className="text-sm lg:text-base ml-2">{prompt}</li>
            ))}
          </ul>
        </div>

        <p className="mb-4 lg:mb-6 text-sm lg:text-base">
          <strong>Date Added:</strong> {new Date(chatbot.date_added).toLocaleDateString()}
        </p>

        <div className="mt-6 lg:mt-8 space-x-2 flex flex-wrap gap-2">
          <Button 
            variant="outline" 
            onClick={onEditClick}
            className="w-full lg:w-auto"
          >
            Edit
          </Button>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button 
                variant="destructive"
                className="w-full lg:w-auto"
              >
                Delete
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className="max-w-[90vw] lg:max-w-lg z-[100]">
              <AlertDialogHeader>
                <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  This action cannot be undone. This will permanently delete the chatbot
                  and remove its data from our servers.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter className="flex-col lg:flex-row gap-2">
                <AlertDialogCancel className="w-full lg:w-auto">Cancel</AlertDialogCancel>
                <AlertDialogAction 
                  onClick={handleDelete}
                  className="w-full lg:w-auto"
                >
                  Delete
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </Card>
    </ScrollArea>
  );
};

export default UserChatbotDetails;