import React from 'react';
import { Button } from "../../components/ui/button";
import { Textarea } from "../../components/ui/textarea";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";

const JsonInputForm = ({ jsonInput, setJsonInput, handleJsonSubmit, jsonError, isSubmitting }) => {
  return (
    <form onSubmit={handleJsonSubmit} className="space-y-4">
      <Textarea
        value={jsonInput}
        onChange={(e) => setJsonInput(e.target.value)}
        placeholder="Paste your flow JSON here"
        className="w-full h-64"
      />
      <Button 
        type="submit"
        disabled={isSubmitting}
        className="w-full bg-black text-white"
      >
        Create Flow from JSON
      </Button>
      {jsonError && (
        <Alert variant="destructive" className="mt-2">
          <AlertTitle>JSON Error</AlertTitle>
          <AlertDescription>{jsonError}</AlertDescription>
        </Alert>
      )}
    </form>
  );
};

export default JsonInputForm;