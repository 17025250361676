import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser, getCurrentUserSubscription } from '../slices/authSlice';
import {
  getTierFromId,
  isSubscriptionActive,
  getSubscriptionStatus,
  canUpgrade,
  getUpgradeOptions,
  formatSubscriptionDates
} from '../utils/subscriptionUtils';

const useSubscription = () => {
  const dispatch = useDispatch();
  const { user, loading: authLoading, currentSubscriptionPlan, subscriptionLoading } = useSelector((state) => state.auth);
  const { isSeatTeam, selectedTeam, selectedTeamFull } = useSelector((state) => state.teams);
  //console.log('useSubscription user:', user);
  //console.log('useSubscription executionCredits:', user?.executionCredits);
  //console.log('useSubscription isSeatTeam:', isSeatTeam);
  
  let subscription;
  if (isSeatTeam) {
    subscription = selectedTeamFull?.subscription;
    //console.log('isSeatTeam subscription:', subscription);
  } else {
    subscription = user?.subscription;
    //console.log('user subscription:', subscription);
  }
  //console.log('useSubscription subscription:', subscription);
  //console.log('active flows: ', subscription?.subscriptionId?.activeFlows);

  useEffect(() => {
    // If we don't have user data and we're not currently loading, fetch it
    if (!user && !authLoading) {
      dispatch(getCurrentUser());
    }
    
    // Always fetch subscription data for new users (they'll have default free plan)
    if (user && !currentSubscriptionPlan && !subscriptionLoading && !isSeatTeam) {
      //console.log('useSubscription: Fetching subscription data for new user: ', subscription);
      dispatch(getCurrentUserSubscription(subscription?.subscriptionId?._id || '675fa33b7bc23c00e46e8505'));
    }
  }, [dispatch]);

  // Return default values for new users when data is not yet loaded
  if ((authLoading || subscriptionLoading) && !isSeatTeam) {
    return {
      loading: true,
      currentTier: 'free',
      isActive: true,
      status: 'loading',
      credits: {
        chat: 0,
        execution: 15,
        image: 0
      },
      limits: {
        activeFlows: 0,
        flowSteps: 0,
        chatCredits: 0,
        executionCredits: 15,
        imageCredits: 0,
        customAssistants: 0,
        seats: 0
      }
    };
  }

  //console.log('useSubscription subscription:', subscription);

  return {
    loading: false,
    currentTier: subscription?.subscriptionId?.tier || 'free',
    subscriptionId: subscription?.subscriptionId?._id || '675fa33b7bc23c00e46e8505',
    status: getSubscriptionStatus(subscription),
    isActive: isSubscriptionActive(subscription),

    credits: {
      chat: isSeatTeam ? selectedTeamFull?.subscription.credits.chat : user?.chatCredits,
      execution: isSeatTeam ? selectedTeamFull?.subscription.credits.execution : user?.executionCredits,
      image: isSeatTeam ? selectedTeamFull?.subscription.credits.image : user?.imageCredits
    },
    
    // Plan details
    name: subscription?.subscriptionId?.name,
    limits: {
      activeFlows: subscription?.subscriptionId?.activeFlows,
      flowSteps: subscription?.subscriptionId?.flowSteps,
      chatCredits: subscription?.subscriptionId?.chatCredits,
      executionCredits: subscription?.subscriptionId?.executionCredits,
      imageCredits: subscription?.subscriptionId?.imageCredits,
      customAssistants: subscription?.subscriptionId?.customAssistants,
      seats: subscription?.subscriptionId?.seats
    },
    features: {
      isAdvancedTemplates: subscription?.subscriptionId?.isAdvancedTemplates
    },

    // Dates
    periodDates: formatSubscriptionDates(subscription),
    
    // Subscription status
    isCancelling: subscription?.status === 'active' && subscription?.cancelAtPeriodEnd,
    
    // Raw data for edge cases
    rawSubscription: subscription || null,
    rawPlan: subscription?.subscriptionId || null
  };
};

export default useSubscription;