// src/slices/imageGenerationSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { generateImage as generateImageApi, fetchUserImages as fetchUserImagesApi, deleteImage as deleteImageApi } from '../services/api';

export const generateImage = createAsyncThunk(
  'imageGeneration/generateImage',
  async ({ prompt, size, styleId, styleModelName, teamId }, { rejectWithValue }) => {
    try {
      const response = await generateImageApi(prompt, size, styleId, styleModelName, teamId);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchUserImages = createAsyncThunk(
  'imageGeneration/fetchUserImages',
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await fetchUserImagesApi(page);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteImage = createAsyncThunk(
  'imageGeneration/deleteImage',
  async (imageId, { rejectWithValue }) => {
    try {
      await deleteImageApi(imageId);
      return imageId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const imageGenerationSlice = createSlice({
  name: 'imageGeneration',
  initialState: {
    currentImage: null,
    images: [],
    isLoading: false,
    error: null,
    currentPage: 1,
    totalPages: 1,
  },
  reducers: {
    setCurrentImage: (state, action) => {
      state.currentImage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateImage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(generateImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentImage = action.payload;
        state.images.unshift(action.payload);
      })
      .addCase(generateImage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchUserImages.fulfilled, (state, action) => {
        state.images = action.payload.images;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(deleteImage.fulfilled, (state, action) => {
        state.images = state.images.filter(image => image._id !== action.payload);
        if (state.currentImage && state.currentImage._id === action.payload) {
          state.currentImage = null;
        }
      });
  },
});

export const { setCurrentImage } = imageGenerationSlice.actions;

export default imageGenerationSlice.reducer;