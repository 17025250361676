import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { Badge } from "../../components/ui/badge";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
  } from "../../components/ui/alert-dialog";
  import * as api from '../../services/api';
import {
  ArrowUpCircle,
  AlertCircle,
  HelpCircle
} from "lucide-react";
import useSubscription from '../../hooks/useSubscription';
import { useToast } from '../../components/ui/use-toast';

const SubscriptionManagement = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { currentTier, status, credits, limits } = useSubscription();
  //console.log('currentTier', currentTier);
  //console.log('status', status);

  const getStatusBadgeProps = () => {
    switch (status) {
      case 'active':
        return {
          variant: "success",
          children: "Active",
          colorClass: "bg-green-300"
        };
      case 'inactive':
        return {
          variant: "secondary",
          children: "Inactive",
          colorClass: "bg-gray-300"
        };
      case 'cancelled':
        return {
          variant: "destructive",
          children: "Cancelled",
          colorClass: "bg-red-300"
        };
      case 'banned':
        return {
          variant: "destructive",
          children: "Banned",
          colorClass: "bg-red-300"
        };
      default:
        return {
          variant: "secondary",
          children: status || 'Unknown',
          colorClass: "bg-gray-300"
        };
    }
  };

  const planDisplay = currentTier.charAt(0).toUpperCase() + currentTier.slice(1);
  const statusBadge = getStatusBadgeProps();

  const handleCancelSubscription = async () => {
    try {
      await api.cancelSubscription();
      toast({
        title: "Subscription Cancelled",
        description: "Your subscription will remain active until the end of your billing period.",
        duration: 5000,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to cancel subscription",
        variant: "destructive",
        duration: 5000,
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col lg:flex-row justify-between items-center gap-4">
        <h2 className="text-2xl font-bold text-center">Subscription Management</h2>
        <Button
          variant="outline"
          onClick={() => navigate('/subscription')}
        >
          View All Plans
        </Button>
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        <Card>
          <CardHeader>
            <CardTitle>Current Plan</CardTitle>
            <CardDescription>Your subscription details</CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="flex justify-between items-center">
              <h3 className={`text-lg lg:text-2xl font-bold`}>{planDisplay} Plan</h3>
              <Badge variant={statusBadge.variant} className={`text-xs ${statusBadge.colorClass}`}>
                {statusBadge.children}
              </Badge>
            </div>
            <div className="flex flex-col gap-2">
              <div>
                <span className="font-semibold">Chat Credits:</span> {limits.chatCredits}
              </div>
              <div>
                <span className="font-semibold">Image Credits:</span> {limits.imageCredits}
              </div>
              <div>
                <span className="font-semibold">Execution Credits:</span> {limits.executionCredits}
              </div>
              <div>
                <span className="font-semibold">Active Flows:</span> {limits.activeFlows}
              </div>
              <div>
                <span className="font-semibold">Max Flow Steps:</span> {limits.flowSteps}
              </div>
              <div>
                <span className="font-semibold">Custom Assistants:</span> {limits.customAssistants}
              </div>
            </div>
            {currentTier !== 'enterprise' && (
              <div className="space-y-4">
                <Button 
                  className="w-fit" 
                  variant="ghost"
                  onClick={() => navigate('/subscription')}
                >
                  <ArrowUpCircle className="mr-2 h-4 w-4" />
                  Upgrade Your Plan
                </Button>
              </div>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Plan Actions</CardTitle>
            <CardDescription>Manage your subscription</CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            {currentTier !== 'enterprise' && (
              <div className="space-y-4">
                <div>
                  Looking for more features? Upgrade your plan to unlock more benefits.
                </div>
                <Button 
                  className="w-full" 
                  onClick={() => navigate('/subscription')}
                >
                  <ArrowUpCircle className="mr-2 h-4 w-4" />
                  Upgrade Your Plan
                </Button>
              </div>
            )}
            {currentTier !== 'free' && (
              <div className="space-y-4 pt-4">
                <div>
                  If you want to downgrade your plan to a different package, please contact support and we will help process your downgrade request.
                </div>
                <Button 
                  className="w-full" 
                  onClick={() => navigate('/support')}
                >
                  <HelpCircle className="mr-2 h-4 w-4" />
                  Contact Support
                </Button>
              </div>
            )}
            {/*currentTier !== 'free' && status === 'active' && (
              <Alert>
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Active Subscription</AlertTitle>
                <AlertDescription>
                  Contact support to make changes to your subscription.
                </AlertDescription>
              </Alert>
            )*/}
            {status === 'active' && currentTier !== 'free' && (
              <div className="space-y-4 pt-4">
                <Alert>
                  <AlertCircle className="h-4 w-4" />
                  <AlertTitle>Active Subscription</AlertTitle>
                  <AlertDescription>
                    You can cancel your subscription at any time. Your features will remain active until the end of your billing period.
                  </AlertDescription>
                </Alert>
                
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button variant="destructive" className="w-full">
                      Cancel Subscription
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                      <AlertDialogDescription>
                        Your subscription will remain active until the end of your current billing period. After that:
                        <ul className="list-disc list-inside mt-2 space-y-1">
                          <li>Your account will revert to the free plan</li>
                          <li>You'll lose access to premium features</li>
                          <li>Team members will lose access</li>
                        </ul>
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Keep Subscription</AlertDialogCancel>
                      <AlertDialogAction
                        onClick={handleCancelSubscription}
                        className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
                      >
                        Yes, Cancel Subscription
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default SubscriptionManagement;