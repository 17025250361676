// src/utils/timezone.js
import { timezones } from '../services/timezones';

export const getLocalTimezone = () => {
    try {
        const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
        // Find timezone where the local timezone matches either the value or is in the aliases array
        const matchingTimezone = timezones.find(tz => 
            tz.value === localTz || tz.aliases.includes(localTz)
        );

        return matchingTimezone ? matchingTimezone.value : 'UTC';
    } catch (error) {
        console.warn('Failed to get local timezone:', error);
        return 'UTC';
    }
};