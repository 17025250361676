// src/pages/PromptStackComponents/PromptStackDrawer.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../../components/ui/sheet";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { ScrollArea } from "../../components/ui/scroll-area";
import { Plus, Save, Play, X, Layers, Info } from "lucide-react";
import { savePromptStack, editPromptStack } from '../../slices/promptStackSlice';
import PromptStackStep from './PromptStackStep';
import { useToast } from "../../components/ui/use-toast";
import { fetchUserAddedChatbots } from '../../slices/chatbotsSlice';

const PromptStackDrawer = ({ isOpen, onClose, stackToEdit = null, onExecute, assistants, credits, limits, globalPromptStacks }) => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const [title, setTitle] = useState('');
  const [steps, setSteps] = useState([{ message: '', assistantId: '' }]);

  const userAddedChatbots = useSelector(state => state.chatbots.userChatbots.chatbots);
  
    useEffect(() => {
      if (userAddedChatbots === null){
        dispatch(fetchUserAddedChatbots());
      }
    }, [dispatch]);
  
  const combinedAssistants = useSelector(state => state.userChatbots.chatbots)
    .filter(chatbot => chatbot.isChattable)
    .concat(userAddedChatbots);

   // console.log('combinedAssistants', combinedAssistants);

  useEffect(() => {
    if (stackToEdit) {
      setTitle(stackToEdit.title);
      setSteps(stackToEdit.prompts.map(prompt => ({
        message: prompt.message,
        assistantId: prompt.assistantId
      })));
    } else {
      setTitle('');
      setSteps([{ message: '', assistantId: '' }]);
    }
  }, [stackToEdit, isOpen]);

  const validateStack = () => {
    // Validate steps
    const invalidSteps = steps.some(step => !step.message || !step.assistantId);
    if (invalidSteps) {
      toast({
        title: "Invalid Steps",
        description: "All steps must have both a message and selected assistant.",
        variant: "destructive",
      });
      return false;
    }

    // Check message lengths
    const longMessages = steps.some(step => step.message.length > 2000);
    if (longMessages) {
      toast({
        title: "Message Too Long",
        description: "Individual messages cannot exceed 2000 characters.",
        variant: "destructive",
      });
      return false;
    }

    return true;
  };

  const handleAddStep = () => {
    if (steps.length < 10) {
      setSteps([...steps, { message: '', assistantId: '' }]);
    } else {
      toast({
        title: "Maximum Steps Reached",
        description: "A prompt stack can have a maximum of 10 steps.",
        variant: "destructive",
      });
    }
  };

  const handleRemoveStep = (index) => {
    const newSteps = steps.filter((_, i) => i !== index);
    setSteps(newSteps);
  };

  const handleStepChange = (index, field, value) => {
    const newSteps = [...steps];
    newSteps[index] = { ...newSteps[index], [field]: value };
    setSteps(newSteps);
  };

  const handleSave = async () => {
    if (!validateStack()) return;

    const stackData = {
      title: title || `Prompt Stack ${new Date().toLocaleString()}`,
      prompts: steps
    };

    try {
      if (stackToEdit) {
        await dispatch(editPromptStack({ id: stackToEdit._id, stackData })).unwrap();
        toast({
          title: "Success",
          description: "Prompt stack updated successfully",
        });
      } else {
        await dispatch(savePromptStack(stackData)).unwrap();
        toast({
          title: "Success",
          description: "Prompt stack created successfully",
        });
      }
      onClose();
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to save prompt stack",
        variant: "destructive",
      });
    }
  };

  const handleExecute = async (shouldSave = false) => {
    if (credits?.chat <= 0) {
      toast({
        title: "Out of Chat Credits",
        description: "You need chat credits to execute prompt stacks.",
        variant: "destructive",
      });
      return;
    }
    if (credits?.chat < steps?.length) {
      toast({
        title: "Insufficient Credits",
        description: "You need more chat credits to execute this stack.",
        variant: "destructive",
      });
      return;
    }
    if (!validateStack()) return;

    const stackData = {
      title: title || `Prompt Stack ${new Date().toLocaleString()}`,
      prompts: steps
    };

    if (shouldSave) {
      try {
        // Save first, then execute
        if (stackToEdit) {
          const result = await dispatch(editPromptStack({ 
            id: stackToEdit._id, 
            stackData 
          })).unwrap();
          onExecute(result);
        } else {
          const result = await dispatch(savePromptStack(stackData)).unwrap();
          onExecute(result);
        }
        toast({
          title: "Success",
          description: "Prompt stack saved successfully",
        });
      } catch (error) {
        toast({
          title: "Error",
          description: error.message || "Failed to save prompt stack",
          variant: "destructive",
        });
        return;
      }
    } else {
      // Execute without saving
      onExecute({ ...stackData, isTemporary: true });
    }

    onClose();
  };

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent 
        side="right" 
        className="w-[95vw] sm:w-[540px] p-0 overflow-hidden h-full z-[60] mt-12 lg:mt-0 text-white"
      >
        <div className="flex flex-col h-full">
          <SheetHeader className="p-4 pb-2 border-b bg-black">
            <SheetTitle className="text-lg sm:text-xl flex items-center text-white lg:-mb-2">
              <Layers className="w-4 h-4 mr-2" />
              {stackToEdit ? 'Edit Prompt Stack' : 'Create Prompt Stack'}
            </SheetTitle>
            <span className={`text-sm ${credits.chat <= 25 ? 'text-red-500 font-bold' : 'text-gray-300'}`}>
              {credits.chat} chat credits remaining
            </span>
            <span className="flex text-sm text-gray-300 leading-none"><Info className="w-4 h-4 mr-1 mt-1" />
            Chain multiple prompts together to create powerful multi-step workflows.
            </span>
          </SheetHeader>
  
          <div className="flex flex-col h-[calc(100vh-10rem)] sm:h-[calc(100vh-4rem)] lg:mt-2 text-black">
            <div className="px-4 py-2">
              <Input
                placeholder="Stack Title (optional)"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full"
              />
            </div>
  
            <ScrollArea className="flex-grow px-4 mb-4">
              <div className="space-y-3">
                {steps.map((step, index) => (
                  <PromptStackStep
                    key={index}
                    step={step}
                    index={index}
                    onChange={handleStepChange}
                    onRemove={() => handleRemoveStep(index)}
                    assistants={assistants}
                    showRemove={steps.length > 1}
                  />
                ))}
              </div>
            </ScrollArea>
  
            <div className="border-t px-4 py-2 space-y-1 bg-gray-200">
              {steps?.length < 10 && (
                <Button 
                  onClick={handleAddStep}
                  variant="outline"
                  className="w-full"
                  size="sm"
                >
                  <Plus className="w-4 h-4 mr-2" />
                  Add Step
                </Button>
              )}
  
              {credits?.chat < steps?.length ? (
                <div className="text-red-500 text-sm font-semibold px-2 py-1 bg-red-50 rounded-md">
                  You can save this stack, but you need {steps.length - credits.chat} more chat credit(s) to execute
                </div>
              ) : (
                <div className="grid grid-cols-2 gap-2">
                  <Button 
                    onClick={() => handleExecute(false)}
                    variant="secondary"
                    disabled={(steps.length <= 1) || (credits?.chat < steps?.length)}
                    size="sm"
                    className="w-full"
                  >
                    <Play className="w-4 h-4 mr-2" />
                    Run & Close
                  </Button>
  
                  <Button 
                    onClick={() => handleExecute(true)}
                    variant="secondary"
                    disabled={(steps.length <= 1) || (credits?.chat < steps?.length)}
                    size="sm"
                    className="w-full"
                  >
                    <Play className="w-4 h-4 mr-2" />
                    Run & Save
                  </Button>
                </div>
              )}
  
              <div className="grid grid-cols-1 gap-2">
                <Button 
                  onClick={handleSave} 
                  disabled={steps.length <= 1}
                  size="sm"
                  className="w-full"
                >
                  <Save className="w-4 h-4 mr-2" />
                  {stackToEdit ? 'Update' : 'Save'} Stack
                </Button>
  
                <Button 
                  variant="outline" 
                  onClick={onClose} 
                  size="sm"
                  className="w-full border border-red-300 hover:bg-red-100"
                >
                  <X className="w-4 h-4 mr-2" />
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default PromptStackDrawer;