import React from 'react';
import { Textarea } from "../../components/ui/textarea";
import { Button } from "../../components/ui/button";
import { Trash2 } from "lucide-react";
import AssistantSelector from '../ChatComponents/AssistantSelector';

const PromptStackStep = ({ 
  step, 
  index, 
  onChange, 
  onRemove, 
  assistants,
  showRemove,
  disabled = false
}) => {
  const handleAssistantChange = (assistantId) => {
    onChange(index, 'assistantId', assistantId);
  };
  
  return (
    <div className="p-4 border rounded-lg space-y-3 bg-gray-100">
      <div className="flex items-center justify-between">
        <h3 className="text-sm font-medium">Step {index + 1}</h3>
        {showRemove && (
          <Button
            variant="ghost"
            size="sm"
            onClick={onRemove}
            className="text-destructive"
            disabled={disabled}
          >
            <Trash2 className="w-4 h-4" />
          </Button>
        )}
      </div>

      <div className="space-y-3">
        <AssistantSelector
          selectedAssistant={step.assistantId}
          onAssistantChange={handleAssistantChange}
          assistants={assistants}
        />

        <Textarea
          value={step.message}
          onChange={(e) => onChange(index, 'message', e.target.value)}
          placeholder="Enter your prompt..."
          rows={3}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default PromptStackStep;