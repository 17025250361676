// src/pages/SchedulingComponents/CreateScheduleForm.js
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createSchedule } from '../../slices/schedulesSlice';
import { Button } from '../../components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/ui/select';
import { Alert, AlertDescription, AlertTitle } from '../../components/ui/alert';
import ScheduleConfigForm from './ScheduleConfigForm';
import { timezones } from '../../services/timezones';
import { getLocalTimezone } from '../../utils/timezone';
import { Gem, Shield } from 'lucide-react';

const CreateScheduleForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { flows } = useSelector((state) => state.flows);
  const { teams } = useSelector((state) => state.teams);
  const [formData, setFormData] = useState({
    flowId: '',
    type: 'hourly',
    config: {
      minutes: [],
      hours: [],
      daysOfMonth: [],
      daysOfWeek: [],
    },
    timezone: getLocalTimezone(), // Set default to local timezone
    team: null,
  });
  const [error, setError] = useState(null);

  const handleInputChange = useCallback((e) => {
    setFormData(prevData => ({ ...prevData, [e.target.name]: e.target.value }));
  }, []);

  const handleSelectChange = useCallback((name, value) => {
    setFormData(prevData => {
      if (name === 'type') {
        return { ...prevData, [name]: value, config: {} };
      }
      // For team selection, convert "null" string to actual null
      if (name === 'team') {
        return { ...prevData, [name]: value === "null" ? null : value };
      }
      return { ...prevData, [name]: value };
    });
  }, []);

  const handleConfigChange = useCallback((config) => {
    setFormData(prevData => ({ ...prevData, config }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Create schedule data with team array if team is selected
      const scheduleData = {
        ...formData,
        team: formData.team ? [formData.team] : [] // Convert to array for backend
      };
      await dispatch(createSchedule(scheduleData)).unwrap();
      navigate('/scheduling');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <h2 className="text-inter font-bold text-2xl mb-4">Create New Schedule</h2>
      {error && (
        <Alert variant="destructive">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      <div className="flex space-x-4">
        <div className="w-1/2">
          <label htmlFor="flowId" className="block text-sm font-medium text-gray-700">Flow</label>
          <Select name="flowId" onValueChange={(value) => handleSelectChange('flowId', value)} value={formData.flowId}>
            <SelectTrigger>
              <SelectValue placeholder="Select a flow" />
            </SelectTrigger>
            <SelectContent>
              {flows.map((flow) => (
                <SelectItem key={flow._id} value={flow._id}>{flow.name}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="w-1/2">
          <label htmlFor="team" className="block text-sm font-medium text-gray-700">Team</label>
          <Select 
            name="team" 
            onValueChange={(value) => handleSelectChange('team', value)} 
            value={formData.team ?? "null"} // Use "null" string when no team is selected
          >
            <SelectTrigger>
              <SelectValue placeholder="Select a team (optional)" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="null">No team</SelectItem>
              {teams.map((team) => (
                <SelectItem key={team._id} value={team._id}><div className='flex items-center'>{team.isSeatsTeam && <Gem className='h-4 w-4 text-yellow-500 mr-1' />}{team.name}</div></SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      
      <div>
        <label htmlFor="type" className="block text-sm font-medium text-gray-700">Schedule Type</label>
        <Select 
          name="type" 
          onValueChange={(value) => handleSelectChange('type', value)} 
          value={formData.type}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select schedule type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="hourly">Hourly</SelectItem>
            <SelectItem value="daily">Daily</SelectItem>
            <SelectItem value="twice_daily">Twice Daily</SelectItem>
            <SelectItem value="weekly">Weekly</SelectItem>
            <SelectItem value="monthly">Monthly</SelectItem>
            <SelectItem value="custom">Custom</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div>
        <label htmlFor="timezone" className="block text-sm font-medium text-gray-700">Timezone</label>
        <Select 
          name="timezone" 
          onValueChange={(value) => handleSelectChange('timezone', value)} 
          value={formData.timezone}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select a timezone" />
          </SelectTrigger>
          <SelectContent>
            {timezones.map((tz) => (
              <SelectItem key={tz.value} value={tz.value}>{tz.name}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <ScheduleConfigForm
        type={formData.type}
        config={formData.config}
        onChange={handleConfigChange}
      />

      <div className="flex justify-end space-x-2">
        <Button type="button" variant="outline" onClick={() => navigate('/scheduling')}>Cancel</Button>
        <Button type="submit">Create Schedule</Button>
      </div>
    </form>
  );
};

export default CreateScheduleForm;