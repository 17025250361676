import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createCheckoutSession, addFreeChatbot, fetchUserAddedChatbots } from '../../slices/chatbotsSlice';
import { Card, CardContent } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Badge } from '../../components/ui/badge';
import { Separator } from '../../components/ui/separator';
import { Alert, AlertDescription } from '../../components/ui/alert';
import { useToast } from '../../components/ui/use-toast';
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from '../../components/ui/collapsible';
import {
  Bot,
  X,
  MessageSquare,
  CreditCard,
  Lock,
  ExternalLink,
  AlertTriangle,
  CheckCircle2,
  Tag,
  Star,
  Users,
  Sparkles,
  Zap,
  Award,
  ThumbsUp,
  ChevronDown,
  ChevronUp,
  Brain,
  Target,
  AlertCircle,
  Settings,
  MessageCircle,
  Command,
  Briefcase,
  ArrowRight,
  PlusIcon
} from 'lucide-react';
import { format } from 'date-fns';
import { loadStripe } from '@stripe/stripe-js';

const ChatbotDetails = ({ chatbotId, onClose }) => {
  const [showMore, setShowMore] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();
  const stripePromise = loadStripe('pk_live_51QgtRaAc8dniylqxX9SHcE95h6VHPiByeG12czEsFhGzNlSYMtGZ51nP5eY3J6GfLtviyojG6bP9ugzoFltetLIj007FP6XnBY');
  
  const chatbot = useSelector(state => 
    state.chatbots.marketplace.chatbots.find(bot => bot._id === chatbotId)
  );
  const userChatbots = useSelector(state => state.chatbots.userChatbots.chatbots);
  const userSubscription = useSelector(state => state.auth.user?.subscription);
  
  const isOwned = React.useMemo(() => 
    userChatbots?.some(bot => bot._id === chatbotId) || chatbot?.isOwned,
    [userChatbots, chatbotId, chatbot]
  );

  const canAccess = React.useMemo(() => {
    if (!chatbot) return false;
    if (isOwned) return true;
    if (chatbot.accessType === 'free') return true;
    if (chatbot.accessType === 'subscription') {
      return userSubscription?.tier >= chatbot.requiredSubscriptionTier;
    }
    return false;
  }, [chatbot, isOwned, userSubscription]);

  const handlePurchase = async () => {
    try {
      const result = await dispatch(createCheckoutSession(chatbotId)).unwrap();
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Stripe failed to initialize');
      }
      const { error } = await stripe.redirectToCheckout({
        sessionId: result.sessionId
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to initiate purchase",
        variant: "destructive"
      });
    }
  };

  const handleAddFreeChatbot = async () => {
    if (chatbot.accessType === 'free') {
      try {
        await dispatch(addFreeChatbot(chatbot._id)).unwrap();
        toast({
          title: "Success",
          description: "Chatbot added successfully",
          duration: 3000,
        });
        
        // Refresh the user's chatbots
        dispatch(fetchUserAddedChatbots());
        
        // Optional: Navigate to chat after a short delay
        setTimeout(() => {
          navigate('/chat');
        }, 1000);
        
      } catch (error) {
        toast({
          title: "Error",
          description: error.message || "Failed to add chatbot",
          variant: "destructive",
          duration: 3000,
        });
      }
    }
  };

  const handleStartChatting = () => {
    navigate(`/chat?assistantId=${chatbotId}`);
  };

  const handleUpgradeSubscription = () => {
    navigate('/subscription');
  };

  if (!chatbot) {
    return (
      <div className="p-4">
        <Alert>
          <AlertTriangle className="h-4 w-4" />
          <AlertDescription>Chatbot not found</AlertDescription>
        </Alert>
      </div>
    );
  }

  return (
    <div className="space-y-2 lg:space-y-6 h-screen overflow-y-auto px-1 pb-16 lg:p-6">
      <div className="flex items-start justify-between">
        <div className="flex items-center gap-2">
          <Bot className="h-6 w-6 text-primary" />
          <div>
            <h2 className="text-xl font-semibold">{chatbot.name}</h2>
            <p className="text-sm text-muted-foreground">
              {chatbot.createdAt ? (
                `Added ${format(new Date(chatbot.createdAt), 'MMM d, yyyy')}`
              ) : 'Recently added'}
            </p>
          </div>
        </div>
        {onClose && (
          <Button className="hidden lg:block" variant="ghost" size="icon" onClick={onClose}>
            <X className="h-4 w-4" />
          </Button>
        )}
      </div>

      <div className="flex flex-wrap gap-2">
        <Badge variant="outline" className="capitalize">
          {chatbot.category || 'General'}
        </Badge>
        {chatbot.accessType === 'free' && (
          <Badge variant="secondary">Free</Badge>
        )}
        {chatbot.accessType === 'paid' && (
          <Badge variant="default" className="bg-green-500 text-white cursor-pointer" onClick={handlePurchase}>
            <PlusIcon className="w-3 h-3 mr-1" />
            ${chatbot.price}
          </Badge>
        )}
        {chatbot.accessType === 'subscription' && (
          <Badge variant="outline">
            <Lock className="w-3 h-3 mr-1" />
            {chatbot.requiredSubscriptionTier} Plan Required
          </Badge>
        )}
        {chatbot.featured && (
          <Badge variant="secondary">
            <Star className="w-3 h-3 mr-1" />
            Featured
          </Badge>
        )}
        <div className="flex items-center text-muted-foreground">
                          <Users className="w-4 h-4 mr-1" />
                          <span className="text-sm">{chatbot.activeUsers}</span>
                        </div>
      </div>

      <Card>
        <CardContent className="space-y-4 pt-6">
          <div className="mx-auto relative w-32 h-32 overflow-hidden rounded-full shadow-md shadow-gray-900 border border-gray-900">
            <img src={chatbot.avatarUrl} alt={chatbot.name} className="object-cover w-full h-full" />
          </div>

          <div>
            <h3 className="font-semibold mb-2">About</h3>
            <p className="text-sm text-muted-foreground">{chatbot.description}</p>
            {chatbot.longDescription && (
              <div className="mt-4">
                <p className="text-sm text-muted-foreground whitespace-pre-line">{chatbot.longDescription}</p>
              </div>
            )}
          </div>

          <Separator />

          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm font-medium">Training Level</p>
              <div className="flex items-center mt-1 text-muted-foreground">
                {[...Array(5)].map((_, index) => (
                  <Star
                    key={index}
                    className={`w-4 h-4 ${
                      index < (chatbot.training || 0)
                        ? 'text-yellow-400 fill-yellow-400'
                        : 'text-gray-300'
                    }`}
                  />
                ))}
              </div>
            </div>
            <div>
              <p className="text-sm font-medium">One-Time Price</p>
              <div className="flex items-center mt-1 text-muted-foreground">
                <span className="text-sm">${chatbot.price}</span>
              </div>
            </div>
            {chatbot.userRating && (
              <div>
                <p className="text-sm font-medium">User Rating</p>
                <div className="flex items-center mt-1 text-muted-foreground">
                  <ThumbsUp className="w-4 h-4 mr-1" />
                  <span className="text-sm">{chatbot.userRating}/5</span>
                </div>
              </div>
            )}
            {chatbot.activeUsers && (
              <div>
                <p className="text-sm font-medium">Active Users</p>
                <div className="flex items-center mt-1 text-muted-foreground">
                  <Users className="w-4 h-4 mr-1" />
                  <span className="text-sm">{chatbot.activeUsers}</span>
                </div>
              </div>
            )}
          </div>

          {(chatbot.subcategories?.length > 0 || chatbot.tags?.length > 0) && (
            <>
              <Separator />
              <div className="space-y-4">
                {chatbot.subcategories?.length > 0 && (
                  <div>
                    <h3 className="font-semibold mb-2">Categories</h3>
                    <div className="flex flex-wrap gap-2">
                      {chatbot.subcategories.map((subcat, index) => (
                        <Badge key={index} variant="outline">
                          <Sparkles className="w-3 h-3 mr-1" />
                          {subcat}
                        </Badge>
                      ))}
                    </div>
                  </div>
                )}
                {chatbot.tags?.length > 0 && (
                  <div>
                    <h3 className="font-semibold mb-2">Tags</h3>
                    <div className="flex flex-wrap gap-2">
                      {chatbot.tags.map((tag, index) => (
                        <Badge key={index} variant="secondary">
                          <Tag className="w-3 h-3 mr-1" />
                          {tag}
                        </Badge>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          {chatbot.features?.length > 0 && (
            <>
              <Separator />
              <div>
                <h3 className="font-semibold mb-2">Key Features</h3>
                <ul className="list-disc list-inside space-y-1">
                  {chatbot.features.map((feature, index) => (
                    <li key={index} className="text-sm text-muted-foreground">
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}

          {chatbot.capabilities?.length > 0 && (
            <>
              <Separator />
              <div>
                <h3 className="font-semibold mb-2">Capabilities</h3>
                <div className="grid grid-cols-2 gap-2">
                  {chatbot.capabilities.map((capability, index) => (
                    <div key={index} className="flex items-center text-sm text-muted-foreground">
                      <Zap className="w-4 h-4 mr-2 text-primary" />
                      {capability}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          {chatbot.certifications?.length > 0 && (
            <>
              <Separator />
              <div>
                <h3 className="font-semibold mb-2">Certifications</h3>
                <div className="flex flex-wrap gap-2">
                  {chatbot.certifications.map((cert, index) => (
                    <Badge key={index} variant="outline" className="flex items-center">
                      <Award className="w-3 h-3 mr-1" />
                      {cert}
                    </Badge>
                  ))}
                </div>
              </div>
            </>
          )}
          <Separator />

<Collapsible open={showMore} onOpenChange={setShowMore}>
  <CollapsibleTrigger asChild>
    <Button variant="ghost" className="w-full flex justify-between items-center">
      <span className="flex items-center gap-2">
        <Settings className="w-4 h-4" />
        Advanced Details
      </span>
      {showMore ? (
        <ChevronUp className="w-4 h-4" />
      ) : (
        <ChevronDown className="w-4 h-4" />
      )}
    </Button>
  </CollapsibleTrigger>
  <CollapsibleContent className="space-y-4 pt-4">
    {/* Type Section */}
    {chatbot.type && (
      <div>
        <h3 className="text-sm font-semibold mb-2 flex items-center gap-2">
          <Bot className="w-4 h-4" />
          Assistant Type
        </h3>
        <div className="space-y-2">
          <Badge variant="outline" className="mr-2">
            {chatbot.type.primary}
          </Badge>
          <div className="flex flex-wrap gap-2 mt-2">
            {chatbot.type.attributes?.map((attr, index) => (
              <Badge key={index} variant="secondary" className="text-xs">
                {attr}
              </Badge>
            ))}
          </div>
        </div>
      </div>
    )}

    {/* Expertise Section */}
    {chatbot.expertise && (
      <div className="mt-4">
        <h3 className="text-sm font-semibold mb-2 flex items-center gap-2">
          <Target className="w-4 h-4" />
          Areas of Expertise
        </h3>
        <Badge variant="outline" className="mb-2">
          {chatbot.expertise.primary}
        </Badge>
        <div className="flex flex-wrap gap-2 mt-2">
          {chatbot.expertise.secondary?.map((skill, index) => (
            <Badge key={index} variant="secondary" className="text-xs">
              {skill}
            </Badge>
          ))}
        </div>
      </div>
    )}

    {/* Personality Section */}
    {chatbot.personality && (
      <div className="mt-4">
        <h3 className="text-sm font-semibold mb-2 flex items-center gap-2">
          <Brain className="w-4 h-4" />
          Personality Profile
        </h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-xs text-muted-foreground">Tone</p>
            <Badge variant="outline">{chatbot.personality.tone}</Badge>
          </div>
          <div>
            <p className="text-xs text-muted-foreground">Style</p>
            <Badge variant="outline">{chatbot.personality.style}</Badge>
          </div>
        </div>
        <div className="flex flex-wrap gap-2 mt-2">
          {chatbot.personality.traits?.map((trait, index) => (
            <Badge key={index} variant="secondary" className="text-xs">
              {trait}
            </Badge>
          ))}
        </div>
      </div>
    )}

    {/* Interaction Suggestions */}
    {chatbot.interactions && (
      <div className="mt-4 space-y-4">
        <h3 className="text-sm font-semibold flex items-center gap-2">
          <MessageCircle className="w-4 h-4" />
          Suggested Interactions
        </h3>
        
        {/* Suggested Prompts */}
        {chatbot.interactions.suggestedPrompts?.length > 0 && (
          <div className="space-y-2">
            <p className="text-xs text-muted-foreground">Suggested Prompts</p>
            <div className="grid gap-2">
              {chatbot.interactions.suggestedPrompts.map((prompt, index) => (
                <div key={index} className="text-sm p-2 bg-secondary/20 rounded-md">
                  <p className="font-medium">{prompt.text}</p>
                  <p className="text-xs text-muted-foreground mt-1">{prompt.description}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Command Palette */}
        {chatbot.interactions.commandPalette?.length > 0 && (
          <div className="space-y-2">
            <p className="text-xs text-muted-foreground">Available Commands</p>
            <div className="flex flex-wrap gap-2">
              {chatbot.interactions.commandPalette.map((command, index) => (
                <Badge key={index} variant="outline" className="font-mono">
                  <Command className="w-3 h-3 mr-1" />
                  {command}
                </Badge>
              ))}
            </div>
          </div>
        )}
      </div>
    )}

    {/* Use Cases */}
    {chatbot.useCase && (
      <div className="mt-4 space-y-4">
        <h3 className="text-sm font-semibold flex items-center gap-2">
          <Briefcase className="w-4 h-4" />
          Use Cases
        </h3>
        
        {/* Ideal For */}
        {chatbot.useCase.idealFor?.length > 0 && (
          <div>
            <p className="text-xs text-muted-foreground mb-2">Ideal For</p>
            <div className="space-y-1">
              {chatbot.useCase.idealFor.map((use, index) => (
                <div key={index} className="flex items-center gap-2 text-sm">
                  <CheckCircle2 className="w-4 h-4 text-green-500" />
                  {use}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Not Suitable For */}
        {chatbot.useCase.notSuitableFor?.length > 0 && (
          <div className="mt-2">
            <p className="text-xs text-muted-foreground mb-2">Not Suitable For</p>
            <div className="space-y-1">
              {chatbot.useCase.notSuitableFor.map((limitation, index) => (
                <div key={index} className="flex items-center gap-2 text-sm">
                  <AlertCircle className="w-4 h-4 text-red-500" />
                  {limitation}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    )}
  </CollapsibleContent>
</Collapsible>
        </CardContent>
      </Card>

      <div className="space-y-4">
        {isOwned ? (
          
          <Button className="w-full" onClick={handleStartChatting}>
          <CheckCircle2 className="w-4 h-4 mr-2 text-green-400" />
          You've already added this chatbot. Click to start chatting.
        </Button>
        ) : chatbot.accessType === 'paid' ? (
          <Button className="w-full hover:bg-green-600" onClick={handlePurchase}>
            <CreditCard className="w-4 h-4 mr-2" />
            Pay Once - Use Forever <ArrowRight className='mx-2 h-4 w-4' /> ${chatbot.price}
          </Button>
        ) : chatbot.accessType === 'subscription' && !canAccess ? (
          <Button className="w-full" onClick={handleUpgradeSubscription}>
            <Lock className="w-4 h-4 mr-2" />
            Upgrade to {chatbot.requiredSubscriptionTier} Plan
          </Button>
        ) : (
          <Button className="w-full" onClick={handleAddFreeChatbot}>
            <MessageSquare className="w-4 h-4 mr-2" />
            Add For Free
          </Button>
        )}

        {!isOwned && canAccess && (
          <Alert>
            <CheckCircle2 className="h-4 w-4" />
            <AlertDescription>
              You have access to this chatbot! Click above to start chatting.
            </AlertDescription>
          </Alert>
        )}

        {chatbot.externalLink && (
          <Button
            variant="outline"
            className="w-full"
            onClick={() => window.open(chatbot.externalLink, '_blank')}
          >
            <ExternalLink className="w-4 h-4 mr-2" />
            Learn More
          </Button>
        )}
      </div>
    </div>
  );
};

export default ChatbotDetails;