import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserTeams,
  setSelectedTeam,
  setIsSeatsTeam,
  setSelectedTeamFull,
} from "../slices/teamsSlice";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Gem, Shield } from "lucide-react";

const TeamSelector = ({ onOpenChange }) => {
  const dispatch = useDispatch();
  const { teams, selectedTeam } = useSelector(
    (state) => state.teams
  );

  useEffect(() => {
    dispatch(fetchUserTeams());
  }, [dispatch]);

  const handleTeamChange = (value) => {
    // Handle special cases first
    if (value === "all") {
      dispatch(setSelectedTeam("all"));
      dispatch(setIsSeatsTeam(false));
      dispatch(setSelectedTeamFull(null));
      return;
    }
    if (value === "personal") {
      dispatch(setSelectedTeam("personal"));
      dispatch(setIsSeatsTeam(false));
      dispatch(setSelectedTeamFull(null));
      return;
    }

    // Find the selected team object from teams array
    const selectedTeamObject = teams.find((team) => team._id === value);

    if (selectedTeamObject) {
      dispatch(setSelectedTeam(value));
      dispatch(setIsSeatsTeam(!!selectedTeamObject.isSeatsTeam)); // Convert to boolean
      dispatch(setSelectedTeamFull(selectedTeamObject));
    }
  };

  // Find the current team object for display
  const currentTeam = teams.find((team) => team._id === selectedTeam);

  return (
    <Select
      onOpenChange={(open) => {
        if (open && onOpenChange) onOpenChange();
      }}
      onValueChange={handleTeamChange}
      value={selectedTeam}
    >
      <SelectTrigger className="w-full text-gray-100 bg-black">
        <SelectValue placeholder="Select Team">
          <div className="flex items-center">
            {currentTeam?.isSeatsTeam && (
              <Gem className="h-4 w-4 text-yellow-500 mr-1" />
            )}
            {selectedTeam === "all"
              ? "All Teams"
              : selectedTeam === "personal"
              ? "Personal"
              : currentTeam?.name || "Select Team"}
            {/*currentTeam?.isSeatsTeam && (
              <div className="flex gap-1 ml-4">
                <div className="text-xs text-blue-500">
                  {currentTeam?.subscription?.credits?.chat}
                </div>
                <div className="text-xs text-purple-500">
                  {currentTeam?.subscription?.credits?.image}
                </div>
                <div className="text-xs text-green-500">
                  {currentTeam?.subscription?.credits?.execution}
                </div>
              </div>
            )*/}
          </div>
        </SelectValue>
      </SelectTrigger>
      <SelectContent className="z-[70]">
        <SelectItem value="all">All Teams</SelectItem>
        <SelectItem value="personal">Personal</SelectItem>
        {teams.map((team) => (
          <SelectItem key={team._id} value={team._id}>
            <div className="flex items-center">
              {team.isSeatsTeam && (
                <Gem className="h-4 w-4 text-yellow-500 mr-1" />
              )}
              {team.name}
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default TeamSelector;
