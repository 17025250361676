// src/data/faqData.js
export const faqSets = {
    general: [
        {
            question: "What is Flow Orchestra?",
            answer: "Flow Orchestra is a powerful workflow automation platform that combines AI capabilities with customizable workflows to streamline your business processes. It enables individuals and teams to create, manage, and automate complex workflows while leveraging advanced AI features like natural language processing and image generation. Whether you're looking to automate routine tasks, enhance creative processes, or improve team collaboration, Flow Orchestra can handle your unique needs."
          },
          {
            question: "How does Flow Orchestra use AI?",
            answer: "Flow Orchestra integrates advanced AI capabilities throughout the platform. You can use AI to generate content, analyze data, create images, brainstorm strategy plans, and even build custom workflows. Our AI features include ChatGPT-style conversations, DALL-E image generation, expertly-trained custom AI assistants, and a ton of writing styles precisely tuned to create any kind of content you can imagine."
          },
          {
            question: "Is Flow Orchestra suitable for non-technical users?",
            answer: "Absolutely! Flow Orchestra is designed with a user-friendly interface that requires no coding knowledge. We offer easy-to-use workflow builders, pre-built templates, and intuitive controls that make automation accessible to everyone. While technical users can dive deep into customization, non-technical users can easily create powerful workflows using our simple tools."
          },
          {
            question: "What types of workflows can I create?",
            answer: "The possibilities are endless! Common use cases include content creation, social media planning, marketing strategy, product ecosystem design, and AI-powered creative processes. Our platform supports both simple task automation and complex, multi-step workflows with advanced logic and AI integration."
          },
          {
            question: "How much does Flow Orchestra cost?",
            answer: "Flow Orchestra offers flexible pricing tiers to suit different needs. We start with a free tier that lets you explore basic features, followed by Individual ($19/month), Growth ($9/month - Most Popular), and Creator ($99/month) plans that include advanced AI capabilities, increased workflow limits, and collaboration features. Enterprise plans with custom pricing are available for larger organizations needing additional support and customization."
          },
          {
            question: "Can I try Flow Orchestra before purchasing?",
            answer: "Yes! We offer a free tier that gives you access to core features and basic workflow automation. You can sign up for a free account, explore our template library, and start building your own workflows right away. If you need more advanced features, you can upgrade to a paid plan at any time to unlock additional capabilities and support."
          },
          {
            question: "How secure is Flow Orchestra?",
            answer: "Security is our top priority. We use secure cloud infrastructure, implement strict access controls, and ensure your data is protected with regular backups. Flow Orchestra is GDPR-compliant and follows industry best practices for data protection. Our platform is designed to keep your workflows and data safe, so you can focus on growing your business with confidence."
          },
          {
            question: "What kind of support is available?",
            answer: "We provide comprehensive support through multiple channels. This includes 24/7 email support for all users, priority chat support for paid plans, detailed documentation, video tutorials, and educational content posted to our social media profiles. Our team is dedicated to helping you succeed with Flow Orchestra, whether you're just getting started or looking to optimize your workflows."
          },
          {
            question: "How quickly can I get started?",
            answer: "You can start automating workflows within minutes! Sign up for a free account, choose from our template library or start building your own workflow, and watch your ideas come to life quicker than ever. Most users create their first automated workflow within 15 minutes of signing up."
          },
          {
            question: "Can I collaborate with my team?",
            answer: "Absolutely! Flow Orchestra is built for collaboration. Our paid and enterprise plans include advanced collaboration features like shared workflows and generated content, with additional exciting collaboration tools coming in the future."
          },
          {
            question: "What makes Flow Orchestra different from other automation tools?",
            answer: "Flow Orchestra stands out through its unique combination of powerful AI capabilities, intuitive workflow design, and extensive customization options. Unlike traditional automation tools, we focus on providing intelligent automation that adapts to your needs. Our platform offers unmatched flexibility in workflow design while maintaining ease of use, and our AI integration capabilities go beyond simple task automation to provide creative and analytical support."
          }
    ],
  
    subscriptions: [
        {
            question: "What subscription plans do you offer?",
            answer: "We offer several flexible tiers tailored to different needs:\n\n• Free Plan: Perfect for trying out our platform\n• Individual Plan ($19/month): Great for personal productivity\n• Growth Plan ($49/month - Most Popular): Ideal for growing teams\n• Creator Plan ($99/month): Best for power users and larger teams\n\nAll plans include core AI capabilities, with increasing features, credits, and team capabilities as you scale up. Enterprise plans are also available for custom needs."
          },
          {
            question: "What's the difference between individual and team subscriptions?",
            answer: "Our Growth and Creator plans offer flexibility in how you use them. With an individual subscription, all credits and features are assigned to your personal account. With a team subscription, you can share resources among team members (up to 3 seats for Growth, 5 for Creator). Team subscriptions are perfect for collaborating on workflows and sharing credits across your organization."
          },
          {
            question: "How do credits work?",
            answer: "We offer three types of credits that refresh monthly:\n\n• Chat Credits: Each credit allows one interaction with our AI assistants\n• Execution Credits: Used when running a complete workflow\n• Image Credits: Each credit generates one AI image\n\nUnused credits don't roll over, but our most popular Growth plan includes 2000 chat credits, 400 execution credits, and 50 image credits monthly - plenty for most teams!"
          },
          {
            question: "What are the benefits of upgrading from Free to Individual?",
            answer: "Upgrading to Individual ($19/month) dramatically expands your capabilities with 500 monthly chat credits (up from 50), 100 execution credits (up from 15), and advanced templates. You'll also get 5 custom assistants and more advanced features to supercharge your productivity."
          },
          {
            question: "Why is Growth our most popular plan?",
            answer: "The Growth plan ($49/month) offers the perfect balance of features and value. With 2000 chat credits, 400 execution credits, and team collaboration options, it's ideal for growing teams. You get up to 3 team seats, 10 custom assistants, and all our advanced features - making it our best value for serious users."
          },
          {
            question: "What makes the Creator plan special?",
            answer: "The Creator plan ($99/month) is our most powerful offering with unlimited active flows, 5000 chat credits, 1000 execution credits, and 150 image credits monthly. It supports up to 5 team seats and offers unlimited custom assistants, making it perfect for power users and larger teams who need maximum flexibility and resources."
          },
          {
            question: "Can I share my subscription with team members?",
            answer: "Yes! Our Growth and Creator plans offer team sharing options. When purchasing these plans, you can choose between an individual subscription (all features for your account) or a team subscription (shared resources among team members). This flexibility lets you choose what works best for your organization."
          },
          {
            question: "What happens if I use all my monthly credits?",
            answer: "If you reach your credit limit, you can either wait for your monthly refresh or upgrade to a higher tier plan. We recommend starting with our Growth plan, which includes generous credit allowances that comfortably support most teams' needs."
          },
          {
            question: "Can I upgrade or downgrade my plan?",
            answer: "You can upgrade your plan instantly at any time to get immediate access to more features and credits. For downgrades, our support team will help manage the transition to ensure no disruption in service or loss of important work."
          },
          {
            question: "Do you offer enterprise solutions?",
            answer: "Yes! Our enterprise solutions offer custom credit allowances, advanced security features, priority support, and tailored pricing for larger organizations. Contact our sales team to discuss your specific needs and get a custom quote."
          },
          {
            question: "What's included in all plans?",
            answer: "Every plan includes access to our core platform features:\n\n• Flow Orchestra's intuitive workflow builder\n• Access to AI assistants and tools\n• Prompt stack functionality\n• Basic templates\n• Essential integrations\n\nHigher tiers add more credits, seats, custom assistants, and advanced features."
          },
          {
            question: "How do I get started?",
            answer: "Start with our Free plan to explore the platform's capabilities. When you're ready to unlock more potential, upgrade to our Growth plan - our most popular option - to get the perfect balance of features, credits, and team capabilities. Sign up takes just minutes, and our intuitive interface means you can start creating powerful workflows right away!"
          }
    ],
  
    login: [
      {
        question: "How do I create an account?",
        answer: "Simply click the 'Join the Waitlist' button and enter your email address. Once we launch, you'll receive an invitation to create your account with a username and password."
      },
      {
        question: "Is my data secure?",
        answer: "Yes! We use industry-standard encryption and security practices to protect your data. All passwords are securely hashed, and we regularly audit our security measures."
      },
      {
        question: "What if I forget my password?",
        answer: "You can use the 'Forgot Password' link on the login page to reset your password. We'll send you a secure link to your registered email address."
      },
      {
        question: "Can I have multiple users on one account?",
        answer: "Yes, our team plans allow multiple users to collaborate under one account. Each user gets their own login credentials while sharing the team's resources."
      }
    ]
  };
  
  export const defaultFaqType = 'general';